import { PointOfInterest } from "interfaces/bus.interface";
import React, { useCallback, useEffect, useState } from "react";
import { LunchIcon } from "components";
import {
  alpha,
  Badge,
  makeStyles,
  Paper,
  SvgIconProps,
  Theme,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { ParkingLotIcon, OperatorIcon } from "components/icons";
import { useMap } from "@vis.gl/react-maplibre";
import { PointOfInterestType } from "interfaces";
import { OperatorStatusType } from "interfaces/operator.interface";
interface PointOfInterestCardProps {
  pointOfInterest: PointOfInterest;
}

interface PointOfInterestIconProps extends SvgIconProps {
  type: PointOfInterestType;
}

// TODO: Add other icons for other types
export const PointOfInterestIcon = ({
  type,
  ...props
}: PointOfInterestIconProps) => {
  if (type === PointOfInterestType.DINING_ROOM) {
    return <LunchIcon {...props} />;
  }
  if (type === PointOfInterestType.PARKING_LOT) {
    return <ParkingLotIcon {...props} />;
  }
  return <ParkingLotIcon {...props} />;
};

export const PointOfInterestCard: React.FC<PointOfInterestCardProps> = ({
  pointOfInterest,
}) => {
  const { name, alias, type, closestBuses, lat, lon, operatorsInfo } =
    pointOfInterest;

  const { current: map } = useMap();

  const operatorsAvailable = operatorsInfo?.operators?.filter(
    (operator) => operator.status !== OperatorStatusType.PRE_ASSEMBLED
  );
  const operatorsPreAssembled = operatorsInfo?.operators?.filter(
    (operator) => operator.status === OperatorStatusType.PRE_ASSEMBLED
  );

  const [zoom, setZoom] = useState(0);

  const [clusterIcons, setClusterIcons] = useState(false);

  const classes = useStyles({
    cluster: clusterIcons,
    operatorsInPointOfInterest: operatorsInfo?.total > 0,
  });

  const onClick = useCallback(() => {
    map?.flyTo({
      center: [lon, lat],
      zoom: 16,
    });
  }, [map, lon, lat]);

  useEffect(() => {
    if (zoom >= 15) {
      setClusterIcons(true);
      return;
    }
    setClusterIcons(false);
  }, [zoom]);

  useEffect(() => {
    if (map) {
      map.on("zoomend", () => {
        setZoom(map?.getZoom() ?? 0);
      });
      setZoom(map.getZoom());
    }
  }, [map]);

  return (
    <Tooltip
      title={
        <>
          <Typography variant="body1">
            <strong>{name}</strong>
          </Typography>
          <Typography variant="body1">
            <strong>Ranking Cercanía Buses</strong>
          </Typography>
          {closestBuses.map((bus, index) => (
            <Typography
              key={`bus-${bus.codigoInternoBus}-${bus.codigoRegistro}`}
              variant="body1"
            >
              <strong>
                {index + 1}. BUS {bus.codigoInternoBus}
              </strong>{" "}
              · {bus.codigoRegistro}
            </Typography>
          ))}
          {operatorsAvailable.length > 0 && (
            <>
              <Typography variant="body1">
                <strong>Operadores Disponibles</strong>
              </Typography>
              {operatorsAvailable.map((operator, index) => (
                <Typography key={`operator-${operator.id}`} variant="body1">
                  {index + 1}. {operator.name}
                </Typography>
              ))}
            </>
          )}
          {operatorsPreAssembled.length > 0 && (
            <>
              <Typography variant="body1">
                <strong>Operadores Pre-Armados</strong>
              </Typography>
              {operatorsPreAssembled.map((operator, index) => (
                <Typography key={`operator-${operator.id}`} variant="body1">
                  {index + 1}. {operator.name}
                </Typography>
              ))}
            </>
          )}
        </>
      }
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        popper: classes.popper,
      }}
      placement="left"
      interactive
      arrow
    >
      <div className={classes.pointOfInterestDiv}>
        <Badge
          classes={{
            badge: classes.badge,
          }}
          invisible={clusterIcons || operatorsInfo.total === 0}
          badgeContent={operatorsInfo.total}
          overlap="circular"
        >
          <Paper
            elevation={1}
            className={classes.pointOfInterestPaper}
            onClick={onClick}
          >
            <PointOfInterestIcon
              className={classes.pointOfInterestIcon}
              type={type as PointOfInterestType}
            />
            {clusterIcons && (
              <Badge
                classes={{
                  badge: classes.badge,
                }}
                invisible={false}
                badgeContent={operatorsInfo.total}
                overlap="rectangular"
              >
                <OperatorIcon className={classes.pointOfInterestIcon} />
              </Badge>
            )}
          </Paper>
        </Badge>
        {(alias?.length || name?.length) && (
          <div className={classes.pointOfInterestName}>
            <Typography
              variant="body1"
              className={classes.pointOfInterestNameText}
            >
              {alias?.length ? alias : name}
            </Typography>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

interface UseStylesProps {
  cluster: boolean;
  operatorsInPointOfInterest: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>((theme) => ({
  pointOfInterestDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pointOfInterestPaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: alpha(theme.palette.common.white, 0.3),
    borderRadius: "50%",
    height: ({ cluster }) => (cluster ? 100 : 40),
    width: ({ cluster }) => (cluster ? 100 : 40),
  },
  pointOfInterestName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
    borderRadius: "24px",
    marginTop: "4px",
  },
  pointOfInterestIconDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70px",
    height: "70px",
  },
  pointOfInterestIcon: {
    width: ({ cluster: isOperatorVisible }) => (isOperatorVisible ? 40 : 30),
    height: ({ cluster: isOperatorVisible }) => (isOperatorVisible ? 40 : 30),
  },
  pointOfInterestNameText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: "0px 10px",
  },
  tooltip: {
    background: theme.palette.chip.focus,
    color: theme.palette.chip.contrastText,
    borderRadius: "4px",
    boxShadow:
      "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
    padding: "6px",
    fontSize: 16,
  },
  popper: {
    maxHeight: "500px",
    overflow: "auto",
  },
  arrow: {
    color: theme.palette.chip.focus,
  },
  badge: {
    backgroundColor: ({ operatorsInPointOfInterest }) =>
      operatorsInPointOfInterest
        ? theme.palette.error.main
        : theme.palette.chip.focusAvatar,
    color: theme.palette.error.contrastText,
  },
}));
