import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { Header, OperatorAssignmentKpis, ScreenContainer } from "components";
import { useData } from "hooks";
import { makeStyles, useTheme, CircularProgress } from "@material-ui/core";
import {
  Map,
  MapProvider,
  Marker,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
} from "@vis.gl/react-maplibre";
import { ErrorMsg, OperatorStatusType } from "interfaces";
import {
  BusInfo,
  GetPointsOfInterestAndBusesResponse,
} from "interfaces/bus.interface";
import { PointOfInterestCard } from "../components/PointOfInterestCard";
import { BusMarker } from "../components/BusMarker";
import { BusesFinder } from "../components/BusesFinder";
import { RecenterButton } from "../components/RecenterButton";
import { polygons } from "../components/MapPoligons";
import "maplibre-gl/dist/maplibre-gl.css";
import { format } from "date-fns";
import { UserContext } from "contexts";
import { TimeSlider } from "../components/TimeSlider";
import { stringToColor } from "utils/bus";
import { ShortcutChips } from "../components/ShortcutChips";
import { AlertOperatorsWithoutLocation } from "../components/AlertOperatorsWithoutLocation";

const workerUrl =
  process.env.NODE_ENV === "production"
    ? "static/js/maplibre-gl-csp-worker.js"
    : undefined;

export const BusesMapScreen: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchBus, setSearchBus] = useState<BusInfo | null>(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { shiftInfo } = useContext(UserContext);

  const {
    data: {
      locations: pointOfInterestsData,
      buses: busesData,
      operators: operatorsData,
    },
    refetching,
    firstLoading,
    refetch: fetchPointOfInterestAndBuses,
  } = useData<GetPointsOfInterestAndBusesResponse, { start_date: Date }>(
    {
      config: {
        url: "buses/map/points-of-interest-and-buses",
        method: "GET",
      },
      options: {
        manual: true,
        useCache: false,
      },
    },
    ErrorMsg.GET_POINTS_OF_INTEREST_AND_BUSES
  );

  const fetchData = useCallback(
    async (date: Date) => {
      await fetchPointOfInterestAndBuses({
        params: {
          start_date: format(date, "yyyy-MM-dd HH:mm:ss"),
        },
      });
      setIsFirstLoading(false);
    },
    [fetchPointOfInterestAndBuses]
  );

  const pointsOfInterest = useMemo(() => {
    return pointOfInterestsData?.map((point) => (
      <Marker
        className={classes.pointOfInterestMarker}
        key={`point-of-interest-${point.id}`}
        longitude={point.lon}
        latitude={point.lat}
        anchor="center"
      >
        <PointOfInterestCard pointOfInterest={point} />
      </Marker>
    ));
  }, [classes.pointOfInterestMarker, pointOfInterestsData]);

  const preferredPointOfInterest = useMemo(
    () =>
      pointOfInterestsData?.filter((point) =>
        ["CM1", "3140", "Dique A", "BAILAC", "COSTA"].includes(point.alias)
      ),
    [pointOfInterestsData]
  );

  const buses = useMemo(
    () =>
      busesData?.map((bus) => (
        <Fragment key={`bus-${bus.codigoRegistro}-${bus.codigoInternoBus}`}>
          <Marker
            key={`bus-${bus.codigoRegistro}-${bus.codigoInternoBus}`}
            longitude={bus.lon}
            latitude={bus.lat}
            anchor="center"
          >
            <BusMarker
              bus={bus}
              isSelected={bus.codigoRegistro === searchBus?.codigoRegistro}
            />
          </Marker>
          <Source
            key={`trail-${bus.codigoRegistro}`}
            type="geojson"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: bus.lastPositions.map((pos) => [pos.lon, pos.lat]),
              },
            }}
          >
            <Layer
              id={`trail-${bus.codigoRegistro}`}
              type="line"
              paint={{
                "line-color": bus.moving
                  ? stringToColor(bus.codigoRegistro)
                  : "gray",
                "line-width": 4,
                "line-dasharray": [2, 1],
              }}
            />
          </Source>
        </Fragment>
      )),
    [busesData, searchBus]
  );

  const availableOperators = useMemo(
    () =>
      operatorsData?.filter(
        ({ status }) =>
          status === OperatorStatusType.AVAILABLE ||
          status === OperatorStatusType.MANUAL
      ) ?? [],
    [operatorsData]
  );
  const preAssembledOperators = useMemo(
    () =>
      operatorsData?.filter(
        ({ status }) => status === OperatorStatusType.PRE_ASSEMBLED
      ) ?? [],
    [operatorsData]
  );
  useEffect(() => {
    const getData = async () => {
      await fetchData(new Date(new Date().setSeconds(0, 0)));
      setIsFirstLoading(false);
    };
    getData();
  }, [fetchData]);

  return (
    <>
      <Header />
      <ScreenContainer>
        <OperatorAssignmentKpis />
        {isFirstLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <MapProvider>
            <div className={classes.contentContainer}>
              <BusesFinder
                searchBus={searchBus}
                setSearchBus={setSearchBus}
                busesForSearch={busesData}
                firstLoadingPointOfInterestAndBuses={refetching || firstLoading}
              />
              <div className={classes.mapContainer}>
                <Map
                  id="busesMap"
                  initialViewState={{
                    longitude: -69.051,
                    latitude: -24.2402,
                    zoom: 12,
                  }}
                  mapStyle={
                    theme.palette.type === "light"
                      ? "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                      : "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
                  }
                  attributionControl={false}
                  workerUrl={workerUrl}
                  style={{
                    backgroundColor:
                      theme.palette.type === "light"
                        ? theme.palette.background.default
                        : theme.palette.background.paper,
                    borderRadius: 4,
                  }}
                >
                  <NavigationControl
                    position="top-right"
                    showCompass={false}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      boxShadow: "none",
                    }}
                  />
                  <ScaleControl position="bottom-right" />
                  <RecenterButton />
                  <Source id="polygons" type="geojson" data={polygons}>
                    <Layer
                      id="polygons"
                      type="fill"
                      paint={{
                        "fill-color":
                          theme.palette.type === "light"
                            ? theme.palette.background.paper
                            : theme.palette.background.default,
                        "fill-opacity": 0.8,
                      }}
                    />
                    <Layer
                      id="polygons-labels"
                      type="symbol"
                      layout={{
                        "text-field": ["get", "name"], // Use the 'name' property from the feature
                        "text-size": 12,
                        "text-offset": [0, 1.5],
                        "text-anchor": "center",
                      }}
                      paint={{
                        "text-color": theme.palette.text.primary,
                      }}
                    />
                  </Source>
                  {/* TODO: Add shortcut chips */}
                  {/* <ShortcutChips
                    preAssembledOperators={preAssembledOperators}
                    availableOperators={availableOperators}
                    preferredPointOfInterest={preferredPointOfInterest}
                  /> */}
                  {/* TODO: Add alert operators without location */}
                  {/* <AlertOperatorsWithoutLocation
                  preAssembledOperators={preAssembledOperators}
                  availableOperators={availableOperators}
                /> */}
                  {pointsOfInterest}
                  {buses}
                </Map>
                <TimeSlider
                  initialDate={
                    shiftInfo?.dailyShiftStartDate
                      ? new Date(shiftInfo.dailyShiftStartDate)
                      : new Date(new Date().setSeconds(0, 0))
                  }
                  endDate={
                    shiftInfo?.dailyShiftEndDate
                      ? new Date(shiftInfo.dailyShiftEndDate)
                      : new Date(new Date().setSeconds(0, 0))
                  }
                  fetchData={fetchData}
                />
              </div>
            </div>
          </MapProvider>
        )}
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "& .maplibregl-ctrl-top-right": {
      top: 40,
      zIndex: 1001,
    },
    "& .maplibregl-ctrl-group button+button": {
      borderTop: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  pointOfInterestMarker: {
    zIndex: 1000,
    "&:hover": {
      zIndex: 1001,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    gap: 10,
    marginTop: 24,
  },
}));
