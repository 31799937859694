import { useMap } from "@vis.gl/react-maplibre";
import { BusInfo, BusFilter } from "interfaces/bus.interface";
import React, { useState, useCallback, useLayoutEffect } from "react";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import { TextField, makeStyles, Typography } from "@material-ui/core";
import { BusCard } from "./BusListCard";
import { useBusFiltering } from "../Map/hooks/useBusFiltering";
import { AutoSizer, ListboxComponent, ChipGroup, List } from "components";

interface Props {
  searchBus: BusInfo | null;
  setSearchBus: (bus: BusInfo | null) => void;
  busesForSearch: BusInfo[];
  firstLoadingPointOfInterestAndBuses: boolean;
}

interface ListChildProps {
  key: React.Key;
  index: number;
  style: React.CSSProperties;
}

export const BusesFinder = ({
  searchBus,
  setSearchBus,
  busesForSearch,
  firstLoadingPointOfInterestAndBuses,
}: Props) => {
  const [busFilter, setBusFilter] = useState<BusFilter>(BusFilter.ALL);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const classes = useStyles();
  const { busesMap } = useMap();

  const { filteredBuses, filteredBusesResume, allBusesGroups } =
    useBusFiltering(busesForSearch, busFilter);

  const handleOnBusChange = useCallback(
    (
      _event: React.ChangeEvent<{}>,
      value: BusInfo | null,
      _reason: AutocompleteChangeReason
    ) => {
      const busGroupType = allBusesGroups.find(
        (group) => group.codigoRegistro === value?.codigoRegistro
      )?.type;
      setBusFilter(busGroupType ?? BusFilter.ALL);
      setSearchBus(value);
      if (value && busesMap) {
        busesMap.flyTo({
          center: [value.lon, value.lat],
          zoom: 16,
        });
      }
    },
    [allBusesGroups, busesMap, setSearchBus]
  );

  const rowRenderer = useCallback(
    ({ index, style }: ListChildProps) => (
      <BusCard
        key={filteredBuses[index].codigoRegistro}
        onSelect={handleOnBusChange}
        isSelected={
          searchBus?.codigoRegistro === filteredBuses[index].codigoRegistro
        }
        bus={filteredBuses[index]}
        style={style}
      />
    ),
    [filteredBuses, searchBus, handleOnBusChange]
  );

  useLayoutEffect(() => {
    const seletectBusIndex = filteredBuses?.findIndex((bus) => {
      const selectedBusId = searchBus?.codigoRegistro ?? 0;
      return bus.codigoRegistro === selectedBusId;
    });
    setScrollToIndex(seletectBusIndex ?? 0);
  }, [filteredBuses, searchBus?.codigoRegistro]);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.filterContainer}>
        <Typography variant="body1">
          <strong>BUSES</strong>
        </Typography>
        <ChipGroup
          value={busFilter}
          options={filteredBusesResume}
          onChange={(value) => setBusFilter(value as BusFilter)}
          alwaysShowCount={true}
        />
      </div>
      <Autocomplete
        value={searchBus}
        options={busesForSearch ?? []}
        getOptionLabel={(option) =>
          `${option.codigoInternoBus} - ${option.codigoRegistro}`
        }
        onChange={handleOnBusChange}
        loading={firstLoadingPointOfInterestAndBuses}
        loadingText="Cargando buses"
        noOptionsText="No se encontraron buses"
        placeholder="Buscar por número bus"
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar por número bus"
            variant="outlined"
            className={classes.searchInput}
          />
        )}
      />
      {filteredBuses?.length > 0 && (
        <div className={classes.root}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                rowHeight={116}
                rowRenderer={rowRenderer}
                rowCount={filteredBuses?.length ?? 0}
                overscanRowCount={16}
                scrollToIndex={scrollToIndex}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    margin: "10px 0px",
    minWidth: "500px",
  },
  searchInput: {
    width: "468px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
  root: {
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
    display: "grid",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "468px",
    alignItems: "center",
  },
}));
