import React from "react";
import { BusInfo } from "interfaces/bus.interface";
import { Typography, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { parseTimeDelta } from "utils/date";
import {
  BusActiveIcon,
  BusInactiveIcon,
  BusOutdatedIcon,
} from "components/icons";
interface BusMarkerProps {
  bus: BusInfo;
  isSelected: boolean;
}

export const BusMarker: React.FC<BusMarkerProps> = ({ bus, isSelected }) => {
  const { codigoRegistro, codigoInternoBus, valorVelocidadAvl, datetimeValue } =
    bus;
  const classes = useStyles({ rotation: bus.orientacion, isSelected });
  return (
    <Tooltip
      title={
        <>
          <Typography variant="body1">
            <strong>
              Bus {codigoInternoBus} · {codigoRegistro}
            </strong>
          </Typography>
          <Typography variant="body1">
            Velocidad: <strong>{valorVelocidadAvl} km/hr</strong>
          </Typography>
          <Typography variant="body1">
            {`Actualizado hace ${parseTimeDelta(
              Math.floor(
                (new Date().getTime() - new Date(datetimeValue).getTime()) /
                  (1000 * 60)
              )
            )}`}
          </Typography>
        </>
      }
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      placement="left"
      interactive
      arrow
    >
      <div className={classes.busContainer}>
        {bus.moving && !bus.outdatedPulse ? (
          <>
            <BusActiveIcon className={classes.busIcon} />
            <div className={classes.busNumber}>
              <Typography variant="body1" className={classes.busNumberText}>
                {bus.codigoInternoBus}
              </Typography>
            </div>
          </>
        ) : bus.moving && bus.outdatedPulse ? (
          <>
            <BusOutdatedIcon className={classes.busIcon} />
            <div className={classes.busNumber}>
              <Typography variant="body1" className={classes.busNumberText}>
                {bus.codigoInternoBus}
              </Typography>
            </div>
          </>
        ) : (
          <>
            <BusInactiveIcon className={classes.busIcon} />
            {isSelected && (
              <div className={classes.busNumber}>
                <Typography variant="body1" className={classes.busNumberText}>
                  {bus.codigoInternoBus}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};
interface UseStylesProps {
  rotation: number;
  isSelected: boolean;
}
const useStyles = makeStyles<Theme, UseStylesProps>((theme) => ({
  busIcon: {
    width: "57px",
    height: "48px",
    transform: ({ rotation }) => `rotate(${180 + rotation}deg)`,
  },
  tooltip: {
    background: theme.palette.chip.focus,
    color: theme.palette.chip.contrastText,
    borderRadius: "4px",
    boxShadow:
      "0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
    padding: "6px",
    fontSize: 16,
  },
  arrow: {
    color: theme.palette.chip.focus,
  },
  busContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  busNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    backgroundColor: ({ isSelected }) =>
      isSelected ? theme.palette.primary.main : theme.palette.chip.focus,
    borderRadius: "24px",
  },
  busNumberText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: "0px 10px",
    color: ({ isSelected }) =>
      isSelected
        ? theme.palette.secondary.contrastText
        : theme.palette.getContrastText(theme.palette.chip.focus),
  },
}));
