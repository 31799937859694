import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const BusInactiveIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="84"
      height="147"
      viewBox="0 0 84 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 119.538C10 119.538 14.5386 146.538 15.5906 146.538H67.4094C68.4614 146.538 73 119.538 73 119.538H10Z"
        fill="url(#paint1_linear_509_1058)"
      />
      <path
        d="M67.9993 143.518C67.9993 142.978 67.4193 142.538 66.7093 142.538H60.6093C59.8993 142.538 59.3193 142.978 59.3193 143.518C59.3193 144.058 59.8993 144.498 60.6093 144.498H66.7093C67.4193 144.498 67.9993 144.058 67.9993 143.518Z"
        fill="url(#paint2_radial_509_1058)"
      />
      <path
        d="M14.9092 143.518C14.9092 142.978 15.4892 142.538 16.1992 142.538H22.2992C23.0092 142.538 23.5892 142.978 23.5892 143.518C23.5892 144.058 23.0092 144.498 22.2992 144.498H16.1992C15.4892 144.498 14.9092 144.058 14.9092 143.518Z"
        fill="url(#paint3_radial_509_1058)"
      />
      <path
        d="M67.8829 128.214C50.2224 135.312 32.3435 135.312 14.683 128.214C11.685 125.298 12.5784 122.381 11 119.465C11.0794 119.178 11.139 118.858 11.1985 118.538C21.1952 122.803 31.2416 120.864 41.2879 120.864C51.3342 120.864 60.8941 122.685 70.6426 118.824C70.722 119.549 70.8412 120.24 71 120.814C70.186 123.275 70.4242 125.753 67.8928 128.214H67.8829Z"
        fill="url(#paint4_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M39 120.089C29.7167 120.344 20.4334 121.748 11.2001 117.538C11.14 117.902 11.08 118.23 11 118.54C12.5906 121.693 11.6902 124.846 14.7113 127.999C19.5431 130.077 24.3848 131.572 29.2365 132.538C32.1275 127.562 35.4087 123.352 38.99 120.089H39Z"
        fill="url(#paint5_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M42 133.538C41.6641 133.538 41.3359 133.538 41 133.538V121.538H42V133.538Z"
        fill="url(#paint6_linear_509_1058)"
      />
      <path
        d="M6.86923 120.668C6.80923 120.668 6.74923 120.648 6.69923 120.598C6.60923 120.508 6.59923 120.358 6.69923 120.258L10.4492 116.378C10.5392 116.288 10.6892 116.278 10.7892 116.378C10.8792 116.468 10.8892 116.618 10.7892 116.718L7.03923 120.598C7.03923 120.598 6.92923 120.668 6.86923 120.668Z"
        fill="black"
      />
      <path
        d="M8.05931 122.148C7.89931 122.148 7.74931 122.068 7.65931 121.928C7.51931 121.708 7.57931 121.408 7.80931 121.268L11.7193 118.778C11.9393 118.638 12.2293 118.698 12.3793 118.928C12.5193 119.148 12.4593 119.448 12.2293 119.588L8.31931 122.078C8.23931 122.128 8.14931 122.148 8.06931 122.148H8.05931Z"
        fill="black"
      />
      <path
        d="M8.22925 122.178C8.22925 121.568 7.72925 119.948 7.11925 119.948H1.39925C-0.450749 119.948 -0.0507488 121.938 0.289251 122.178H8.22925Z"
        fill="url(#paint7_linear_509_1058)"
      />
      <path
        d="M0.0692239 120.808C0.0592239 120.378 0.619224 119.948 0.919224 119.948H7.11922C7.41922 119.948 7.68922 120.338 7.88922 120.808H0.0692239Z"
        fill="url(#paint8_linear_509_1058)"
      />
      <path
        d="M76.4794 121.268C76.4194 121.268 76.3594 121.248 76.3094 121.198L72.5594 117.318C72.4694 117.228 72.4694 117.068 72.5594 116.978C72.6494 116.888 72.7994 116.888 72.8994 116.978L76.6494 120.858C76.7394 120.948 76.7394 121.108 76.6494 121.198C76.5994 121.238 76.5394 121.268 76.4794 121.268Z"
        fill="black"
      />
      <path
        d="M75.2894 122.758C75.1994 122.758 75.1094 122.738 75.0294 122.688L71.1194 120.198C70.8994 120.058 70.8294 119.768 70.9694 119.538C71.1094 119.318 71.3994 119.248 71.6294 119.388L75.5394 121.878C75.7594 122.018 75.8294 122.308 75.6894 122.538C75.5994 122.678 75.4494 122.758 75.2894 122.758Z"
        fill="black"
      />
      <path
        d="M75.1294 122.788C75.1294 122.178 75.6294 120.558 76.2394 120.558H81.9494C83.7994 120.558 83.3994 122.548 83.0594 122.788H75.1194H75.1294Z"
        fill="url(#paint9_linear_509_1058)"
      />
      <path
        d="M83.2795 121.418C83.2895 120.988 82.7295 120.568 82.4295 120.568H76.2295C75.9295 120.568 75.6595 120.958 75.4595 121.418H83.2795Z"
        fill="url(#paint10_linear_509_1058)"
      />
      <path
        d="M73 120.17C73 121.23 58.8985 121.538 41.495 121.538C24.0914 121.538 10 121.23 10 120.17V4.0496C10 3.43276 73 3.30537 73 4.0496V120.17Z"
        fill="url(#paint11_radial_509_1058)"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M33.8292 87.1382H47.8092C49.5592 87.1382 50.9892 88.5582 50.9892 90.3182V100.038C50.9892 101.788 49.5692 103.218 47.8092 103.218H33.8292C32.0792 103.218 30.6492 101.798 30.6492 100.038V90.3182C30.6492 88.5682 32.0692 87.1382 33.8292 87.1382Z"
          fill="#E8E8E8"
        />
        <path
          d="M33.8293 87.228H47.8093C49.5093 87.228 50.8893 88.608 50.8893 90.308V100.028C50.8893 101.728 49.5093 103.108 47.8093 103.108H33.8293C32.1293 103.108 30.7493 101.728 30.7493 100.028V90.308C30.7493 88.608 32.1293 87.228 33.8293 87.228Z"
          fill="url(#paint12_radial_509_1058)"
        />
        <path
          d="M33.8291 87.3281H47.8091C49.4591 87.3281 50.7991 88.6681 50.7991 90.3181V100.038C50.7991 101.688 49.4591 103.028 47.8091 103.028H33.8291C32.1791 103.028 30.8391 101.688 30.8391 100.038V90.3181C30.8391 88.6681 32.1791 87.3281 33.8291 87.3281Z"
          fill="url(#paint13_radial_509_1058)"
        />
        <path
          d="M33.8292 87.4282H47.8092C49.3992 87.4282 50.6992 88.7182 50.6992 90.3182V100.038C50.6992 101.628 49.4092 102.928 47.8092 102.928H33.8292C32.2392 102.928 30.9392 101.638 30.9392 100.038V90.3182C30.9392 88.7282 32.2292 87.4282 33.8292 87.4282Z"
          fill="url(#paint14_radial_509_1058)"
        />
        <path
          d="M33.8291 87.5181H47.8091C49.3491 87.5181 50.5991 88.7681 50.5991 90.3081V100.028C50.5991 101.568 49.3491 102.818 47.8091 102.818H33.8291C32.2891 102.818 31.0391 101.568 31.0391 100.028V90.3081C31.0391 88.7681 32.2891 87.5181 33.8291 87.5181Z"
          fill="url(#paint15_radial_509_1058)"
        />
        <path
          d="M33.8292 87.6182H47.8092C49.2992 87.6182 50.5091 88.8282 50.5091 90.3182V100.038C50.5091 101.528 49.2992 102.738 47.8092 102.738H33.8292C32.3392 102.738 31.1292 101.528 31.1292 100.038V90.3182C31.1292 88.8282 32.3392 87.6182 33.8292 87.6182Z"
          fill="url(#paint16_radial_509_1058)"
        />
        <path
          d="M33.8293 87.7183H47.8092C49.2493 87.7183 50.4092 88.8883 50.4092 90.3183V100.038C50.4092 101.478 49.2393 102.638 47.8092 102.638H33.8293C32.3893 102.638 31.2292 101.468 31.2292 100.038V90.3183C31.2292 88.8883 32.3893 87.7183 33.8293 87.7183Z"
          fill="url(#paint17_radial_509_1058)"
        />
        <path
          d="M33.8291 87.8081H47.8091C49.1891 87.8081 50.3091 88.9281 50.3091 90.3081V100.028C50.3091 101.408 49.1891 102.528 47.8091 102.528H33.8291C32.4491 102.528 31.3291 101.408 31.3291 100.028V90.3081C31.3291 88.9281 32.4491 87.8081 33.8291 87.8081Z"
          fill="url(#paint18_radial_509_1058)"
        />
        <path
          d="M33.8292 87.9082H47.8092C49.1392 87.9082 50.2192 88.9882 50.2192 90.3182V100.038C50.2192 101.368 49.1392 102.448 47.8092 102.448H33.8292C32.4992 102.448 31.4192 101.368 31.4192 100.038V90.3182C31.4192 88.9882 32.4992 87.9082 33.8292 87.9082Z"
          fill="url(#paint19_radial_509_1058)"
        />
        <path
          d="M33.8293 87.998H47.8093C49.0893 87.998 50.1193 89.038 50.1193 90.308V100.028C50.1193 101.308 49.0793 102.338 47.8093 102.338H33.8293C32.5493 102.338 31.5193 101.298 31.5193 100.028V90.308C31.5193 89.028 32.5593 87.998 33.8293 87.998Z"
          fill="url(#paint20_radial_509_1058)"
        />
        <path
          d="M33.8291 88.0981H47.8091C49.0291 88.0981 50.0291 89.0881 50.0291 90.3181V100.038C50.0291 101.258 49.0391 102.258 47.8091 102.258H33.8291C32.6091 102.258 31.6091 101.268 31.6091 100.038V90.3181C31.6091 89.0981 32.5991 88.1082 33.8191 88.1082L33.8291 88.0981Z"
          fill="url(#paint21_radial_509_1058)"
        />
        <path
          d="M47.8092 88.1982H33.8292C32.6584 88.1982 31.7092 89.1474 31.7092 90.3182V100.038C31.7092 101.209 32.6584 102.158 33.8292 102.158H47.8092C48.9801 102.158 49.9292 101.209 49.9292 100.038V90.3182C49.9292 89.1474 48.9801 88.1982 47.8092 88.1982Z"
          fill="url(#paint22_radial_509_1058)"
        />
      </g>
      <path
        d="M47.8092 88.1982H33.8292C32.6584 88.1982 31.7092 89.1474 31.7092 90.3182V100.038C31.7092 101.209 32.6584 102.158 33.8292 102.158H47.8092C48.9801 102.158 49.9292 101.209 49.9292 100.038V90.3182C49.9292 89.1474 48.9801 88.1982 47.8092 88.1982Z"
        fill="url(#paint23_linear_509_1058)"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M33.0693 13.9478H48.5593C50.4993 13.9478 52.0793 15.5278 52.0793 17.4678V55.3877C52.0793 57.3277 50.4993 58.9078 48.5593 58.9078H33.0693C31.1293 58.9078 29.5493 57.3277 29.5493 55.3877V17.4678C29.5493 15.5278 31.1293 13.9478 33.0693 13.9478Z"
          fill="url(#paint24_radial_509_1058)"
        />
        <path
          d="M48.5694 14.0479H33.0794C31.1906 14.0479 29.6594 15.579 29.6594 17.4678V55.3778C29.6594 57.2667 31.1906 58.7979 33.0794 58.7979H48.5694C50.4582 58.7979 51.9894 57.2667 51.9894 55.3778V17.4678C51.9894 15.579 50.4582 14.0479 48.5694 14.0479Z"
          fill="url(#paint25_radial_509_1058)"
        />
        <path
          d="M33.0693 14.1577H48.5593C50.3893 14.1577 51.8693 15.6377 51.8693 17.4677V55.3877C51.8693 57.2177 50.3893 58.6977 48.5593 58.6977H33.0693C31.2393 58.6977 29.7593 57.2177 29.7593 55.3877V17.4677C29.7593 15.6377 31.2393 14.1577 33.0693 14.1577Z"
          fill="url(#paint26_radial_509_1058)"
        />
        <path
          d="M33.0694 14.2676H48.5594C50.3294 14.2676 51.7594 15.6976 51.7594 17.4676V55.3876C51.7594 57.1576 50.3294 58.5876 48.5594 58.5876H33.0694C31.2994 58.5876 29.8694 57.1576 29.8694 55.3876V17.4676C29.8694 15.6976 31.3094 14.2676 33.0694 14.2676Z"
          fill="url(#paint27_radial_509_1058)"
        />
        <path
          d="M33.0692 14.3679H48.5692C50.2792 14.3679 51.6592 15.7579 51.6592 17.4579V55.3779C51.6592 57.0879 50.2692 58.4779 48.5592 58.4779H33.0692C31.3592 58.4779 29.9692 57.0879 29.9692 55.3779V17.4579C29.9692 15.7479 31.3592 14.3579 33.0692 14.3579V14.3679Z"
          fill="url(#paint28_radial_509_1058)"
        />
        <path
          d="M33.0693 14.4775H48.5593C50.2093 14.4775 51.5493 15.8175 51.5493 17.4675V55.3875C51.5493 57.0375 50.2093 58.3775 48.5593 58.3775H33.0693C31.4193 58.3775 30.0793 57.0375 30.0793 55.3875V17.4675C30.0793 15.8175 31.4193 14.4775 33.0693 14.4775Z"
          fill="url(#paint29_radial_509_1058)"
        />
        <path
          d="M33.0692 14.5879H48.5692C50.1592 14.5879 51.4492 15.8779 51.4492 17.4679V55.3879C51.4492 56.9779 50.1592 58.2679 48.5692 58.2679H33.0792C31.4892 58.2679 30.1992 56.9779 30.1992 55.3879V17.4679C30.1992 15.8779 31.4892 14.5879 33.0792 14.5879H33.0692Z"
          fill="url(#paint30_radial_509_1058)"
        />
        <path
          d="M33.0693 14.688H48.5693C50.0993 14.688 51.3393 15.928 51.3393 17.458V55.378C51.3393 56.908 50.0993 58.158 48.5593 58.158H33.0693C31.5393 58.158 30.2993 56.918 30.2993 55.388V17.468C30.2993 15.938 31.5393 14.688 33.0793 14.688H33.0693Z"
          fill="url(#paint31_radial_509_1058)"
        />
        <path
          d="M33.0694 14.7979H48.5694C50.0394 14.7979 51.2394 15.9978 51.2394 17.4678V55.3878C51.2394 56.8578 50.0394 58.0578 48.5694 58.0578H33.0794C31.6094 58.0578 30.4094 56.8578 30.4094 55.3878V17.4678C30.4094 15.9978 31.6094 14.7979 33.0794 14.7979H33.0694Z"
          fill="url(#paint32_radial_509_1058)"
        />
        <path
          d="M33.0693 14.9077H48.5593C49.9693 14.9077 51.1193 16.0577 51.1193 17.4677V55.3877C51.1193 56.7977 49.9693 57.9477 48.5593 57.9477H33.0693C31.6593 57.9477 30.5093 56.7977 30.5093 55.3877V17.4677C30.5093 16.0577 31.6593 14.9077 33.0693 14.9077Z"
          fill="url(#paint33_radial_509_1058)"
        />
        <path
          d="M33.0694 15.0176H48.5694C49.9194 15.0176 51.0194 16.1176 51.0194 17.4676V55.3876C51.0194 56.7476 49.9194 57.8476 48.5594 57.8476H33.0694C31.7194 57.8476 30.6194 56.7476 30.6194 55.3976V17.4776C30.6194 16.1176 31.7194 15.0176 33.0794 15.0176H33.0694Z"
          fill="url(#paint34_radial_509_1058)"
        />
        <path
          d="M33.0692 15.1177H48.5692C49.8692 15.1177 50.9192 16.1677 50.9192 17.4677V55.3877C50.9192 56.6877 49.8692 57.7377 48.5692 57.7377H33.0793C31.7793 57.7377 30.7292 56.6877 30.7292 55.3877V17.4677C30.7292 16.1677 31.7793 15.1177 33.0793 15.1177H33.0692Z"
          fill="url(#paint35_radial_509_1058)"
        />
      </g>
      <path
        d="M33.0692 15.1177H48.5692C49.8692 15.1177 50.9192 16.1677 50.9192 17.4677V55.3877C50.9192 56.6877 49.8692 57.7377 48.5692 57.7377H33.0793C31.7793 57.7377 30.7292 56.6877 30.7292 55.3877V17.4677C30.7292 16.1677 31.7793 15.1177 33.0793 15.1177H33.0692Z"
        fill="url(#paint36_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M33.0692 15.1177H48.5692C49.8692 15.1177 50.9192 16.1677 50.9192 17.4677V55.3877C50.9192 56.6877 49.8692 57.7377 48.5692 57.7377H33.0793C31.7793 57.7377 30.7292 56.6877 30.7292 55.3877V17.4677C30.7292 16.1677 31.7793 15.1177 33.0793 15.1177H33.0692Z"
        fill="url(#paint37_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M14 120.538C11.4526 120.337 10 120.083 10 119.755V3.77916C10 3.68541 11.4842 3.60505 14 3.53809V120.538Z"
        fill="url(#paint38_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M69 120.538C71.5474 120.337 73 120.083 73 119.755V3.77916C73 3.68541 71.5158 3.60505 69 3.53809V120.538Z"
        fill="url(#paint39_linear_509_1058)"
      />
      <path
        d="M70.4003 1.68799H12.3603L10.0703 3.95799H72.8403L70.4003 1.68799Z"
        fill="url(#paint40_linear_509_1058)"
      />
      <path
        d="M69.0794 0.537598H13.6794L12.3594 1.6876H70.3994L69.0794 0.537598Z"
        fill="url(#paint41_linear_509_1058)"
      />
      <path
        d="M50.7792 146.148H32.1292L31.6592 144.608H51.2592L50.7792 146.148Z"
        fill="url(#paint42_linear_509_1058)"
      />
      <path
        d="M69.0387 3.40674H13.3287L12.4688 4.19674H69.9788L69.0387 3.40674Z"
        fill="url(#paint43_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 36.4282H37.7292V39.8782H43.8993V36.4282Z"
        fill="url(#paint44_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M48.4492 52.3081H33.1692V53.5881H48.4492V52.3081Z"
        fill="url(#paint45_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 32.7681H37.7292V33.6281H43.8993V32.7681Z"
        fill="url(#paint46_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 31.2881H37.7292V32.1481H43.8993V31.2881Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 29.8179H37.7292V30.6779H43.8993V29.8179Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 28.3379H37.7292V29.1979H43.8993V28.3379Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 26.8682H37.7292V27.7282H43.8993V26.8682Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8993 25.3979H37.7292V26.2579H43.8993V25.3979Z"
        fill="black"
        fillOpacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M49.9294 39.1479H49.0894V47.228H49.9294V39.1479Z"
        fill="url(#paint47_linear_509_1058)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M32.5392 39.1479H31.6992V47.228H32.5392V39.1479Z"
        fill="url(#paint48_linear_509_1058)"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M67.9995 75.5381H67.5245V7.79176H15.4646V75.5381H14.9995V7.53809H67.9995V75.5381Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        d="M67.2193 146.128H15.5393V146.918H67.2193V146.128Z"
        fill="url(#paint49_linear_509_1058)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_509_1058"
          x1="61.83"
          y1="70.9747"
          x2="83.2082"
          y2="71.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#242424" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_509_1058"
          x1="41.5"
          y1="145.089"
          x2="41.5"
          y2="133.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADADAD" />
          <stop offset="1" stopColor="#878787" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.6593 143.509) scale(3.2 2.43199)"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.2492 168.847) rotate(180) scale(3.2 2.43199)"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_509_1058"
          x1="41"
          y1="121.909"
          x2="41"
          y2="142.831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_509_1058"
          x1="36.259"
          y1="134.798"
          x2="21.4469"
          y2="129.704"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3B" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_509_1058"
          x1="41.5"
          y1="124.522"
          x2="41.5"
          y2="140.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_509_1058"
          x1="-0.000748842"
          y1="121.068"
          x2="8.22925"
          y2="121.068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADADAD" />
          <stop offset="1" stopColor="#878787" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_509_1058"
          x1="0.0692239"
          y1="120.378"
          x2="7.88922"
          y2="120.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_509_1058"
          x1="83.3594"
          y1="121.668"
          x2="75.1294"
          y2="121.668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADADAD" />
          <stop offset="1" stopColor="#878787" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_509_1058"
          x1="83.2895"
          y1="120.978"
          x2="75.4695"
          y2="120.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.505 79.78) scale(44.5326 86.8679)"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(68.8806 93.0681) rotate(-5.71) scale(9.7734 9.666)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="1" stopColor="#E7E7E7" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(97.9182 82.6023) rotate(-12.53) scale(11.1636 10.1004)"
        >
          <stop stopColor="#EBEBEB" />
          <stop offset="1" stopColor="#E6E6E6" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(127.808 63.4794) rotate(-20.55) scale(14.0478 10.6259)"
        >
          <stop stopColor="#ECECEC" />
          <stop offset="1" stopColor="#E4E4E4" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(162.407 25.146) rotate(-29.74) scale(21.3525 10.8225)"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="1" stopColor="#E3E3E3" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(279.962 -103.003) rotate(-39.8) scale(61.1807 11.2021)"
        >
          <stop stopColor="#EFEFEF" />
          <stop offset="1" stopColor="#E2E2E2" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-70.9126 230.005) rotate(-50.2) scale(60.6482 11.1046)"
        >
          <stop stopColor="#F0F0F0" />
          <stop offset="1" stopColor="#E1E1E1" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.6164 104.202) rotate(-60.26) scale(20.7831 10.5339)"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#E0E0E0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.5965 82.0576) rotate(-69.45) scale(13.4004 10.1362)"
        >
          <stop stopColor="#F3F3F3" />
          <stop offset="1" stopColor="#DFDFDF" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.2433 79.6045) rotate(-77.47) scale(10.416 9.424)"
        >
          <stop stopColor="#F4F4F4" />
          <stop offset="1" stopColor="#DDDDDD" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.5437 85.547) rotate(-84.29) scale(8.8998 8.802)"
        >
          <stop stopColor="#F6F6F6" />
          <stop offset="1" stopColor="#DCDCDC" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8192 95.1782) rotate(-90) scale(8.12 8.12)"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="paint23_linear_509_1058"
          x1="31.5792"
          y1="95.1682"
          x2="51.1892"
          y2="95.1682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </linearGradient>
        <radialGradient
          id="paint24_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8193 36.4178) scale(17.78 17.78)"
        >
          <stop stopColor="#ECECEC" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6928 36.8331) rotate(-5.71) scale(18.6459 18.6459)"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="1" stopColor="#FCFCFC" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8647 36.2119) rotate(-12.53) scale(19.7484 19.7484)"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#F8F8F8" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8855 36.0282) rotate(-20.55) scale(20.7402 20.7402)"
        >
          <stop stopColor="#EFEFEF" />
          <stop offset="1" stopColor="#F5F5F5" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8085 36.7991) rotate(-29.74) scale(21.3306)"
        >
          <stop stopColor="#F0F0F0" />
          <stop offset="1" stopColor="#F2F2F2" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8272 36.4263) rotate(-39.8) scale(21.8041 21.8041)"
        >
          <stop stopColor="#F1F1F1" />
          <stop offset="1" stopColor="#EFEFEF" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8231 36.4282) rotate(-50.2) scale(21.6976)"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#EBEBEB" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.9019 36.7494) rotate(-60.26) scale(21.0094)"
        >
          <stop stopColor="#F3F3F3" />
          <stop offset="1" stopColor="#E8E8E8" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6851 36.0946) rotate(-69.45) scale(20.2098 20.2098)"
        >
          <stop stopColor="#F4F4F4" />
          <stop offset="1" stopColor="#E5E5E5" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.7351 36.2664) rotate(-77.47) scale(19.0176 19.0176)"
        >
          <stop stopColor="#F5F5F5" />
          <stop offset="1" stopColor="#E2E2E2" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.0131 36.7086) rotate(-84.29) scale(17.7177 17.7177)"
        >
          <stop stopColor="#F6F6F6" />
          <stop offset="1" stopColor="#DEDEDE" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_509_1058"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8192 36.4277) rotate(-90) scale(16.67)"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="paint36_linear_509_1058"
          x1="40.8192"
          y1="56.5877"
          x2="40.8192"
          y2="14.5477"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="0.78" stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#E6E6E6" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_509_1058"
          x1="30.5392"
          y1="36.4177"
          x2="50.7092"
          y2="36.4177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D8D8D8" />
          <stop offset="0.03" stopColor="#EAEAEA" />
          <stop offset="0.06" stopColor="#F1F1F1" />
          <stop offset="0.47" stopColor="#F2F2F2" />
          <stop offset="0.86" stopColor="#F2F2F2" />
          <stop offset="0.93" stopColor="#F2F2F2" />
          <stop offset="0.95" stopColor="#EEEEEE" />
          <stop offset="0.97" stopColor="#E3E3E3" />
          <stop offset="0.99" stopColor="#D1D1D1" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_509_1058"
          x1="10"
          y1="62.0381"
          x2="14"
          y2="62.0381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBDBDB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_509_1058"
          x1="73.0105"
          y1="62.0381"
          x2="69.0105"
          y2="62.0381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6A6A6" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_509_1058"
          x1="9.64031"
          y1="2.81799"
          x2="77.1903"
          y2="2.81799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ADADAD" />
          <stop offset="1" stopColor="#878787" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_509_1058"
          x1="11.9594"
          y1="1.1076"
          x2="74.4194"
          y2="1.1076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_509_1058"
          x1="31.6592"
          y1="145.378"
          x2="51.2592"
          y2="145.378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_509_1058"
          x1="12.4688"
          y1="3.79674"
          x2="69.9788"
          y2="3.79674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363B39" />
          <stop offset="1" stopColor="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_509_1058"
          x1="40.8092"
          y1="36.7182"
          x2="40.8092"
          y2="38.9782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_509_1058"
          x1="40.8092"
          y1="52.4081"
          x2="40.8092"
          y2="53.2481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_509_1058"
          x1="40.8092"
          y1="32.8281"
          x2="40.8092"
          y2="33.3981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="0.3" stopColor="#CFCFCF" />
          <stop offset="0.56" stopColor="#DADADA" />
          <stop offset="0.81" stopColor="#ECECEC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_509_1058"
          x1="49.9094"
          y1="43.188"
          x2="49.1094"
          y2="43.188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_509_1058"
          x1="31.7192"
          y1="43.188"
          x2="32.5092"
          y2="43.188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_509_1058"
          x1="63.1493"
          y1="160.158"
          x2="41.5092"
          y2="146.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3B" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
