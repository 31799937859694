import { FeatureCollection } from "geojson";
export const polygons: FeatureCollection = {
  type: "FeatureCollection",
  features: [
    // {
    //   type: "Feature",
    //   properties: {
    //     name: "Lagunas Seca",
    //   },
    //   geometry: {
    //     coordinates: [
    //       [
    //         [-69.06421502924137, -24.33750798706592],
    //         [-69.0688831197276, -24.344927680881213],
    //         [-69.05763881417654, -24.349584170451763],
    //         [-69.05382256502, -24.342133704944132],
    //         [-69.06421502924137, -24.33750798706592],
    //       ],
    //     ],
    //     type: "Polygon",
    //   },
    // },
    // {
    //   type: "Feature",
    //   properties: {
    //     name: "Los Colorados",
    //   },
    //   geometry: {
    //     coordinates: [
    //       [
    //         [-69.06127163873904, -24.26149036023611],
    //         [-69.06137527769513, -24.26319108835456],
    //         [-69.06018342970627, -24.265482311091105],
    //         [-69.0561674201803, -24.26460834440452],
    //         [-69.05580468383606, -24.262222620965318],
    //         [-69.05347280733682, -24.25995496114261],
    //         [-69.0547942040193, -24.259010090944955],
    //         [-69.06137527769513, -24.261254146198524],
    //         [-69.06127163873904, -24.26149036023611],
    //       ],
    //     ],
    //     type: "Polygon",
    //   },
    // },
    // {
    //   type: "Feature",
    //   properties: {
    //     name: "Picina 400x400",
    //   },
    //   geometry: {
    //     coordinates: [
    //       [
    //         [-69.07541348446162, -24.30048206789425],
    //         [-69.07528442175379, -24.30141001657796],
    //         [-69.07324809902876, -24.303997781281097],
    //         [-69.07025097614452, -24.302351028031367],
    //         [-69.07000719102994, -24.30178903591944],
    //         [-69.07225861826791, -24.298691499845944],
    //         [-69.07541348446162, -24.30048206789425],
    //       ],
    //     ],
    //     type: "Polygon",
    //   },
    // },
    // {
    //   type: "Feature",
    //   properties: {
    //     name: "VSL",
    //   },
    //   geometry: {
    //     coordinates: [
    //       [
    //         [-69.12866892653615, -24.264938903196125],
    //         [-69.13109269881454, -24.265703777641875],
    //         [-69.13115484682186, -24.268177784445783],
    //         [-69.13004654069466, -24.268309981926507],
    //         [-69.13009833070065, -24.269688604609385],
    //         [-69.12640052427504, -24.270104077004973],
    //         [-69.12596548822506, -24.266364776540115],
    //         [-69.12866892653615, -24.264938903196125],
    //       ],
    //     ],
    //     type: "Polygon",
    //   },
    // },
    // {
    //   type: "Feature",
    //   properties: {
    //     name: "VCA",
    //   },
    //   geometry: {
    //     coordinates: [
    //       [
    //         [-69.1195429854314, -24.249740496700497],
    //         [-69.12054381233742, -24.252295521007312],
    //         [-69.12108971792225, -24.255696610085238],
    //         [-69.12008889101625, -24.25690770764747],
    //         [-69.11874232390696, -24.256774985189054],
    //         [-69.11848756796671, -24.254153688264466],
    //         [-69.11728657567971, -24.250387553164387],
    //         [-69.1195429854314, -24.249740496700497],
    //       ],
    //     ],
    //     type: "Polygon",
    //   },
    // },
    {
      type: "Feature",
      properties: {
        name: "Rajo Escondida",
      },
      geometry: {
        coordinates: [
          [
            [-69.06153990517315, -24.262622032284142],
            [-69.06227085679721, -24.25979462220188],
            [-69.06620271658971, -24.254944435734373],
            [-69.0723450402737, -24.252876718877147],
            [-69.07986401714656, -24.254434209521932],
            [-69.08620962271007, -24.25708911784821],
            [-69.08979798845269, -24.265744879992766],
            [-69.08737485193973, -24.27340472299487],
            [-69.0821650636361, -24.276007662242577],
            [-69.08005363213032, -24.283609912263216],
            [-69.07139262342645, -24.286728843237057],
            [-69.06503142969926, -24.28853609717808],
            [-69.06256446112232, -24.290021538203888],
            [-69.05062850801582, -24.27864631630294],
            [-69.06153990517315, -24.262622032284142],
          ],
        ],
        type: "Polygon",
      },
    },
    {
      type: "Feature",
      properties: {
        name: "Rajo Escondida Norte",
      },
      geometry: {
        coordinates: [
          [
            [-69.05344743090943, -24.206138985023713],
            [-69.05862674596324, -24.209907208903303],
            [-69.05888594715444, -24.216341677258796],
            [-69.05621296707743, -24.222098476247382],
            [-69.04810846562307, -24.22695653622877],
            [-69.04179685908456, -24.222270706293173],
            [-69.04045758471545, -24.21877425893203],
            [-69.0392162847443, -24.21479869858547],
            [-69.02978288073906, -24.21012998288286],
            [-69.03777936236843, -24.206991093882067],
            [-69.04893173748472, -24.20422011643464],
            [-69.05344743090943, -24.206138985023713],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};
