import React from "react";
import { useMap } from "@vis.gl/react-maplibre";
import { makeStyles } from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";

export function RecenterButton() {
  const { busesMap } = useMap();
  const classes = useStyles();
  const onClick = () => {
    busesMap?.flyTo({ center: [-69.051, -24.2402], zoom: 12 });
  };
  return (
    <div className={classes.recenterButton} onClick={onClick}>
      <CropFreeIcon />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  recenterButton: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1000,
    backgroundColor: theme.palette.primary.main,
    color: "#333333",
    borderRadius: "4px",
    width: "29px",
    height: "29px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 5,
  },
}));
