import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const BusIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="0 0 60 60"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      {...props}
    >
      <circle cx="30" cy="30" r="30" fill="#1E242B" />
      <mask
        id="mask0_115_168190"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <circle cx="30" cy="30" r="30" fill="#1E242B" />
      </mask>
      <g mask="url(#mask0_115_168190)">
        <g clipPath="url(#clip0_115_168190)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.44514 30.4795L4.99338 29.5656L10.3038 33.0302L28.0791 44.0454L29.619 42.9674L35.2697 46.7819V40.8652L5.5439 25.4534L2.97095 26.8577L3.44514 30.4795Z"
            fill="#21272B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7701 42.9056C29.7701 44.4349 30.9028 46.2983 32.2998 47.0676C32.8496 47.3705 32.2617 48.112 32.6765 48.0145C32.7888 47.988 33.991 47.295 34.0886 47.2421C34.5466 46.9931 34.83 46.4566 34.83 45.6918C34.83 44.1625 33.6972 42.2991 32.2998 41.5298C31.6543 41.174 31.0652 41.1142 30.618 41.3038C30.5752 41.322 29.4658 41.9481 29.4259 41.9707C28.9783 42.2235 29.7701 42.1503 29.7701 42.9056Z"
            fill="#151B1F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.499 43.6028C28.499 45.1321 29.6318 46.9956 31.0288 47.7652C32.4261 48.5345 33.5589 47.9184 33.5589 46.3891C33.5589 44.8598 32.4261 42.9963 31.0288 42.227C29.6318 41.4574 28.499 42.0735 28.499 43.6028Z"
            fill="#585B5D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.5576 43.7028C28.5576 45.1943 29.6623 47.0121 31.0254 47.7625C32.3879 48.5129 33.4929 47.9122 33.4929 46.4207C33.4929 44.9288 32.3879 43.1114 31.0254 42.361C29.6623 41.6102 28.5576 42.2112 28.5576 43.7028Z"
            fill="#3A3E40"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.2666 44.026C29.2666 45.0909 30.0551 46.3884 31.028 46.9242C32.0009 47.4599 32.7897 47.031 32.7897 45.966C32.7897 44.9014 32.0009 43.6039 31.028 43.0681C30.0551 42.532 29.2666 42.961 29.2666 44.026Z"
            fill="#A5A9B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.6423 45.8722C32.6423 46.851 31.9171 47.2456 31.0227 46.7528C30.1283 46.2603 29.4032 45.0672 29.4032 44.0881C29.4032 43.1093 30.1283 42.7147 31.0227 43.2075C31.9171 43.7 32.6423 44.8931 32.6423 45.8722Z"
            fill="#484B4D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.593 45.9134C32.593 46.6954 32.0136 47.0107 31.2989 46.6171C30.5842 46.2236 30.0049 45.2702 30.0049 44.4879C30.0049 43.7055 30.5842 43.3906 31.2989 43.7842C32.0136 44.1777 32.593 45.1311 32.593 45.9134Z"
            fill="#21272B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.5543 45.5884C32.5543 46.3708 31.9746 46.6857 31.2599 46.2921C30.5451 45.8986 29.9658 44.9452 29.9658 44.1628C29.9658 43.3809 30.5451 43.0656 31.2599 43.4592C31.9746 43.8527 32.5543 44.8061 32.5543 45.5884Z"
            fill="#3D454B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.0733 43.0216C29.8288 43.1974 29.6804 43.5196 29.6804 43.9616C29.6804 44.9407 30.4056 46.1338 31.3 46.6263C31.655 46.8217 31.983 46.877 32.2498 46.8121C31.9528 47.0257 31.5135 47.0229 31.0231 46.7527C30.1287 46.2602 29.4032 45.0671 29.4032 44.088C29.4032 43.4976 29.6675 43.1202 30.0733 43.0216Z"
            fill="#666B70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.3296 45.7313C32.3296 46.5133 31.7503 46.8286 31.0356 46.435C30.3209 46.0415 29.7412 45.0881 29.7412 44.3058C29.7412 43.5234 30.3209 43.2082 31.0356 43.6021C31.7503 43.9956 32.3296 44.9487 32.3296 45.7313Z"
            fill="#7E8386"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.4074 45.3485C31.4074 45.6662 31.1718 45.7943 30.8814 45.6346C30.5907 45.4745 30.3555 45.0875 30.3555 44.7695C30.3555 44.4518 30.5907 44.3237 30.8814 44.4837C31.1718 44.6434 31.4074 45.0308 31.4074 45.3485Z"
            fill="#5C3E17"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.3538 45.3787C31.3538 45.6963 31.1182 45.8244 30.8275 45.6644C30.5371 45.5047 30.3015 45.1173 30.3015 44.7996C30.3015 44.482 30.5371 44.3539 30.8275 44.5135C31.1182 44.6736 31.3538 45.061 31.3538 45.3787Z"
            fill="#A5A9B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.14158 29.3179C5.14158 30.8473 6.27431 32.7107 7.67132 33.4803C8.22113 33.7829 7.63316 34.5244 8.04794 34.4268C8.16028 34.4007 9.36251 33.7074 9.46008 33.6545C9.91807 33.4055 10.2014 32.869 10.2014 32.1042C10.2014 30.5753 9.0687 28.7114 7.67132 27.9422C7.02575 27.5867 6.43633 27.5269 5.98951 27.7165C5.94666 27.7344 4.83733 28.3605 4.79736 28.3831C4.34945 28.6362 5.14158 28.5631 5.14158 29.3179Z"
            fill="#151B1F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.87109 30.0151C3.87109 31.5444 5.00347 33.4079 6.40084 34.1775C7.79822 34.9468 8.93095 34.331 8.93095 32.8017C8.93095 31.2724 7.79822 29.409 6.40084 28.6393C5.00347 27.87 3.87109 28.4858 3.87109 30.0151Z"
            fill="#585B5D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.93152 30.1151C3.93152 31.607 5.03617 33.4244 6.39898 34.1748C7.76179 34.9255 8.8668 34.3245 8.8668 32.833C8.8668 31.3415 7.76179 29.5237 6.39898 28.7733C5.03617 28.0229 3.93152 28.6236 3.93152 30.1151Z"
            fill="#3A3E40"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.63867 30.4383C4.63867 31.5033 5.42755 32.8008 6.40042 33.3366C7.37329 33.8723 8.16218 33.4437 8.16218 32.3787C8.16218 31.3137 7.37329 30.0163 6.40042 29.4805C5.42755 28.9448 4.63867 29.3737 4.63867 30.4383Z"
            fill="#A5A9B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00372 32.2996C8.00227 32.7419 7.85141 33.0644 7.60441 33.2368C7.3088 33.4442 6.87494 33.437 6.3921 33.1691C5.50564 32.677 4.78877 31.4901 4.792 30.5178C4.79417 29.8777 5.10778 29.4896 5.57514 29.4401C5.81709 29.414 6.10118 29.479 6.40362 29.6476C7.29044 30.1397 8.00695 31.3266 8.00372 32.2996Z"
            fill="#666B70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00455 32.2994C8.00309 32.7418 7.85223 33.0643 7.60523 33.2367C7.36327 33.2631 7.07919 33.1979 6.77674 33.0296C5.89029 32.5374 5.17342 31.3505 5.17665 30.3783C5.1781 29.9353 5.32896 29.6128 5.57596 29.44C5.81792 29.4139 6.102 29.4788 6.40445 29.6475C7.29126 30.1396 8.00777 31.3265 8.00455 32.2994Z"
            fill="#484B4D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.814 31.9172C7.81327 32.1706 7.72681 32.3551 7.58559 32.454C7.41627 32.5724 7.16805 32.5683 6.89174 32.4152C6.3845 32.1335 5.97453 31.4549 5.97632 30.8986C5.97741 30.5325 6.15718 30.3109 6.42449 30.2828C6.56283 30.2677 6.7253 30.3051 6.89858 30.4013C7.40582 30.6829 7.81579 31.3615 7.814 31.9172Z"
            fill="#21272B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.73502 31.654C7.73429 31.9075 7.64783 32.0919 7.50661 32.1905C7.33729 32.3089 7.08907 32.3052 6.81276 32.1516C6.30552 31.8704 5.89555 31.1918 5.89734 30.635C5.89843 30.2689 6.0782 30.0478 6.34551 30.0196C6.48385 30.0045 6.64632 30.0416 6.8196 30.1381C7.32684 30.4194 7.73681 31.098 7.73502 31.654Z"
            fill="#3D454B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.48416 31.7702C7.48343 32.0237 7.39697 32.2081 7.25575 32.3067C7.08643 32.4255 6.83822 32.4214 6.5619 32.2682C6.05466 31.9866 5.64469 31.308 5.64649 30.7516C5.64758 30.3855 5.82734 30.164 6.09465 30.1358C6.23299 30.1207 6.39547 30.1578 6.56875 30.2543C7.07599 30.5356 7.48596 31.2145 7.48416 31.7702Z"
            fill="#7E8386"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.5793 28.4386L53.9966 39.9964L54.0859 42.579L53.796 44.3587L46.5125 40.3478V39.6077C46.5125 37.8369 45.2004 35.6788 43.5827 34.7876C41.965 33.8967 40.6529 34.61 40.6529 36.3808V37.1209L21.8785 26.7819V26.0421C21.8785 24.271 20.5669 22.1129 18.9491 21.222C17.331 20.3312 16.0194 21.0445 16.0194 22.8152V23.5553L9.23951 19.8215L2.97095 15.0646L5.06215 1.04126H5.40996L16.3088 7.04343L19.733 9.69335L52.2338 27.5913L53.5793 28.4386Z"
            fill="#E0E4EA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0844 42.5789L51.3375 44.8477C49.6694 46.2252 47.7543 47.3046 45.6843 48.0333L41.5102 49.5036L41.4209 46.921L53.9951 39.9963L54.0844 42.5789Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9052 45.324L46.8532 47.5776L50.322 45.628L51.6776 41.979L45.9052 45.324Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0728 42.2353L51.3634 44.4635C49.7554 45.7857 47.9191 46.8314 45.9359 47.5547L41.5105 49.1681V47.8119L46.0436 46.1226C48.0207 45.3856 49.8483 44.3275 51.4455 42.9946L54.0246 40.8427L54.0728 42.2353Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.4455 43.3968L54.0246 41.2449L54.0656 42.4263L54.0728 42.4201L54.0246 41.0275L51.4455 43.1794C49.8483 44.5123 48.0207 45.5704 46.0436 46.307L41.5105 47.9967V48.2141L46.0436 46.5248C48.0207 45.7881 49.8483 44.7297 51.4455 43.3968Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.4455 43.7813L54.0246 41.6294L54.0656 42.8108L54.0728 42.8046L54.0246 41.412L51.4455 43.5639C49.8483 44.8968 48.0207 45.9549 46.0436 46.6916L41.5105 48.3813V48.5986L46.0436 46.9093C48.0207 46.1726 49.8483 45.1142 51.4455 43.7813Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.4455 44.1661L54.0246 42.0141L54.0656 43.1955L54.0728 43.1893L54.0246 41.7964L51.4455 43.9487C49.8483 45.2815 48.0207 46.3397 46.0436 47.0763L41.5105 48.766V48.9834L46.0436 47.294C48.0207 46.5574 49.8483 45.4989 51.4455 44.1661Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.6072 37.3283L10.8483 14.3321L8.15255 11.9524L4.23047 9.79257L6.41349 2.90332L52.6072 28.3417V37.3283Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6757 14.8626L11.2319 14.0676L8.12746 11.3417L4.70801 9.45871V4.26636L12.6757 8.65405V14.8626Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.4232 20.2306L13.0459 15.0667V8.85815L22.4232 14.022V20.2306Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.4232 16.5207L13.0459 11.3565V11.0828L22.4232 16.247V16.5207Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5889 13.5841L17.883 13.7459V11.5211L17.5889 11.3594V13.5841Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.8184 28.7081L32.7207 25.9009V19.6926L37.8184 22.4995V28.7081Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.5644 34.0763L38.1875 28.9121V22.7039L47.5644 27.8677V34.0763Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.805 25.919L38.1875 22.2749V20.6621L44.805 24.3066V25.919Z"
            fill="#1A1006"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.5644 30.3663L38.1875 25.2024V24.9287L47.5644 30.0926V30.3663Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.7286 27.4295L43.0231 27.5916V25.3665L42.7286 25.2047V27.4295Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.82325 13.1472L2.97125 12.6781L2.3916 12.6808V14.8342L3.27351 15.5853L3.82325 15.9579V13.1472Z"
            fill="#8595AD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.5523 28.3134L32.7207 27.8563V27.3669L33.5523 27.8241V28.3134Z"
            fill="#D7A32F"
          />
          <mask
            id="mask1_115_168190"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="11"
            width="5"
            height="6"
          >
            <path
              d="M8.82779 16.9262L8.66324 16.8355V14.4081L4.9381 11.9766V13.7189L4.77356 13.5654V11.6796L8.82779 14.3257V16.9262Z"
              fill="#96999D"
            />
          </mask>
          <g mask="url(#mask1_115_168190)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.77356 16.9262H8.82779V11.6796H4.77356V16.9262Z"
              fill="#96999D"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8065 16.0772L8.14062 13.0781V12.3647L10.8065 14.7207V16.0772Z"
            fill="#D7A32F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.14099 13.0782L2.97095 10.1961V9.51782L8.14099 12.3649V13.0782Z"
            fill="#D7A32F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4522 25.8202C21.4522 27.334 20.331 27.9436 18.9484 27.1822C17.5654 26.4205 16.4442 24.5759 16.4442 23.0624C16.4442 21.5489 17.5654 20.9393 18.9484 21.7007C20.331 22.4625 21.4522 24.3067 21.4522 25.8202Z"
            fill="#3A3E40"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2353 26.2148V26.9789L21.8774 26.7818V26.042C21.8774 24.271 20.5657 22.1128 18.9479 21.222C17.3299 20.3311 16.0182 21.0444 16.0182 22.8151V23.5552L15.6603 23.3581V22.594C15.6603 20.6069 17.1322 19.806 18.9479 20.8057C20.7633 21.8058 22.2353 24.2277 22.2353 26.2148Z"
            fill="#8595AD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.0332 39.1664L53.8175 39.8979L53.9967 39.9964L53.6128 29.3957L53.0332 29.1172V39.1664Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.0773 18.2211L31.6206 20.1721V29.873L28.0773 27.922V18.2211Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5977 15.7539L27.1406 17.7049V27.4062L23.5977 25.4548V15.7539Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.172 29.782L32.0079 29.6914V25.984L32.172 26.0747V29.782Z"
            fill="#96999D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1729 26.0748L32.0084 25.9841V19.1206L27.6931 16.7437V16.5627L32.1729 19.03V26.0748Z"
            fill="#221B15"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2123 24.8478L23.0479 24.7572V21.0498L23.2123 21.1405V24.8478Z"
            fill="#96999D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.2128 21.1405L23.0479 21.0499V14.0054L27.6926 16.5626L27.528 16.4726V16.6532L23.2128 14.2763V21.1405Z"
            fill="#221B15"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.6914 27.3148L27.5273 27.2244V23.5171L27.6914 23.6074V27.3148Z"
            fill="#656769"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.6914 23.6075L27.5273 23.5171V16.4727L27.6914 16.563V23.6075Z"
            fill="#17120E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.2197 37.6658L53.9958 39.3359L53.578 30.9088L53.2197 30.7117V37.6658Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.5786 28.4386L19.9703 9.93067L19.7323 9.69336L52.2331 27.5917L53.5786 28.4386Z"
            fill="#8595AD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7321 9.69335L19.9701 9.93066L16.1362 7.13856L5.06128 1.04126H5.40909L16.308 7.04342L19.7321 9.69335Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.3687 30.5229L54.0246 30.9103C53.7429 31.2547 53.5145 31.8241 53.5145 32.182V34.3556C53.5145 34.7134 53.7429 34.7244 54.0246 34.38L54.4959 33.8167C54.7772 33.4719 54.8784 33.0784 54.8784 32.7205V30.5469C54.8784 30.1891 54.6504 30.1781 54.3687 30.5229Z"
            fill="#1F150B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.4919 30.6075L54.1475 30.9949C53.8661 31.3393 53.6377 31.9087 53.6377 32.2666V34.4402C53.6377 34.798 53.8661 34.809 54.1475 34.4646L54.6187 33.9013C54.9004 33.5569 55.0016 33.1629 55.0016 32.8051V30.6315C55.0016 30.2737 54.7732 30.263 54.4919 30.6075Z"
            fill="#484038"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.6746 36.9673L54.3757 35.0238L54.3678 29.3348L53.2215 30.7728L53.3234 30.882L54.2532 29.771V34.9136L52.5586 36.7966L52.6746 36.9673Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0047 35.3632L41.5113 49.5036L41.2218 51.2833L33.9378 47.272V46.5323C33.9378 44.7612 32.6262 42.6031 31.0084 41.7122C29.3903 40.8213 28.0787 41.5346 28.0787 43.3054V44.0455L9.30429 33.7064V32.9663C9.30429 31.1956 7.99224 29.0375 6.37451 28.1466C4.75642 27.2554 3.44474 27.9691 3.44474 29.7398V30.4796L-3.33474 26.7461L-7.42065 23.1912V8.01462L-6.21303 7.4823L4.68585 13.4845L7.72436 16.3185L40.2255 34.2168L41.0047 35.3632Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0332 44.2529L-1.72571 21.2564L-4.42109 18.8771L-6.16016 17.9192V9.82764L40.0332 35.2664V44.2529Z"
            fill="#292017"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.101058 21.787L-1.3424 20.9919L-4.4468 18.2664L-5.68359 17.5854V12.3931L0.101058 15.5787V21.787Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.84897 27.1551L0.47168 21.9909V15.7827L9.84897 20.9466V27.1551Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.84897 23.4451L0.47168 18.2813V18.0076L9.84897 23.1714V23.4451Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.0127 20.5082L5.30683 20.67V18.4452L5.0127 18.2834V20.5082Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.2438 35.6327L20.1465 32.8255V26.6169L25.2438 29.4242V35.6327Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.9915 41.0006L25.6143 35.8367V29.6282L34.9915 34.7924V41.0006Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5081 32.4149L10.1309 27.251V21.0425L19.8386 26.3918V32.6"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.9915 37.2909L25.6143 32.1267V31.853L34.9915 37.0172V37.2909Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5081 28.705L10.1309 23.5408V23.2671L19.8386 28.6101V28.8838"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.1565 34.3541L30.4506 34.5159V32.2912L30.1565 32.1294V34.3541Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6727 25.7683L14.9669 25.9301V23.7053L14.6727 23.5436V25.7683Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9777 35.2381L20.1465 34.7806V34.2913L20.9777 34.7487V35.2381Z"
            fill="#D7A32F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.554 43.5544L-1.76758 23.0017V21.6455L35.554 42.1982V43.5544Z"
            fill="#CFD4D8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.66153 33.1395V33.9036L9.30399 33.7065V32.9664C9.30399 31.1957 7.99195 29.0375 6.37422 28.1467C4.75649 27.2555 3.44445 27.9691 3.44445 29.7399V30.4796L3.08691 30.2828V29.5187C3.08691 27.5316 4.55846 26.7304 6.37422 27.7304C8.18998 28.7302 9.66153 31.1521 9.66153 33.1395Z"
            fill="#7F868E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.2947 46.705V47.4692L33.9368 47.272V46.5323C33.9368 44.7612 32.6251 42.6031 31.0074 41.7122C29.3893 40.8213 28.0776 41.5346 28.0776 43.3054V44.0455L27.7197 43.8483V43.0842C27.7197 41.0971 29.1916 40.2962 31.0074 41.296C32.8228 42.296 34.2947 44.7179 34.2947 46.705Z"
            fill="#7F868E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.4587 46.0906L41.2611 46.8327L41.4403 46.9309L41.0045 36.3415L40.4587 36.0414V46.0906Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.6117 29.3956L41.0037 36.3415L41.439 46.9309L45.9051 45.324C48.0298 44.5595 49.9871 43.4252 51.6775 41.979L53.9952 39.9964L53.6117 29.3956Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.119 38.486L53.578 30.9088L53.9953 39.336L51.8166 41.1764C50.1172 42.612 48.1664 43.7515 46.0536 44.5427L41.4391 46.2705L41.119 38.486Z"
            fill="#4D5258"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.6476 44.5905L41.4413 46.2706L41.1209 38.486L40.6476 38.1144V44.5905Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.8446 39.4644L52.4257 38.486L49.4062 40.4782L47.7928 42.0374L41.5117 44.6107L41.0439 45.4305L41.4404 46.2705L46.2241 44.4792C48.2249 43.7298 50.0723 42.6508 51.6818 41.2915L53.8446 39.4644Z"
            fill="#737F82"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.7852 37.9515L53.9963 39.3358L53.5788 30.9087L52.7852 31.3455V37.9515Z"
            fill="#9FB8B9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.6935 31.5613L50.4699 33.9406C49.0812 34.9661 47.5639 35.8213 45.9523 36.4872L41.1189 38.4856L41.0037 36.3416L46.218 34.1189C47.4609 33.589 48.6372 32.9316 49.7292 32.1565L53.611 29.396L53.6935 31.5613Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.6972 43.8497H48.4229L47.6915 34.5231H47.9658L48.6972 43.8497Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.3594 36.5485L46.3587 34.4175C47.6218 33.879 48.8251 33.2066 49.9352 32.4187L53.2934 30.0305L53.3453 31.4053L50.2614 33.6819C48.8961 34.6899 47.4007 35.5326 45.8169 36.1869L41.4371 37.9978L41.3594 36.5485Z"
            fill="#1F150B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.3604 36.6016C43.3791 36.796 43.2657 36.9804 43.0783 37.0594L42.1993 37.4914C42.0516 37.5642 41.8754 37.4629 41.874 37.3043L41.8736 37.2802L41.8434 36.9615C41.8286 36.807 41.919 36.6607 42.0678 36.5982L42.7228 36.322L43.0866 36.1678C43.1961 36.1215 43.3208 36.1912 43.3319 36.3049L43.3604 36.6016Z"
            fill="#737F82"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.8291 31.4872L52.0376 32.1002C51.9631 32.1579 51.8572 32.0975 51.8489 31.992L51.8334 31.7994L51.8211 31.6493C51.8074 31.4783 51.873 31.3165 51.9937 31.2228L52.4743 30.8505L52.7862 30.6097C52.8608 30.5524 52.9664 30.6132 52.9747 30.7182L53.0017 31.062C53.0154 31.2324 52.9498 31.3938 52.8291 31.4872Z"
            fill="#737F82"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.4644 37.0316L41.8736 37.2802L41.8434 36.9615C41.8286 36.807 41.9191 36.6606 42.0679 36.5981L42.7232 36.322L42.747 36.5738C42.7658 36.7685 42.6519 36.9526 42.4644 37.0316Z"
            fill="#E7EAEF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.5092 31.2776L51.8334 31.7992L51.8211 31.6492C51.8074 31.4781 51.873 31.3164 51.9938 31.2226L52.4746 30.8503L52.5092 31.2776Z"
            fill="#E7EAEF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.611 29.396L49.7292 32.1565C48.6372 32.9316 47.4609 33.589 46.218 34.1189L41.0037 36.3416L53.611 29.396Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5536 43.5543V42.1981L39.9744 46.9347L40.0334 48.3545L35.5536 43.5543Z"
            fill="#CFD4D8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.5117 49.1681L40.0332 48.3545V46.9979L41.5117 47.8119V49.1681Z"
            fill="#CFD4D8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.0222 36.4442L39.5652 38.3952V48.0965L36.0222 46.1451V36.4442Z"
            fill="#383E45"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0929 50.3535L40.0043 50.3044L35.495 47.8214V43.522L35.554 43.5543V42.1981L35.495 42.1654V41.753L35.6131 41.8182V42.2612L35.554 42.1981V43.5543L35.6131 43.6178V47.7562L39.9748 50.1585V48.2909L40.0339 48.3544V46.9979L39.9748 46.9347V44.2202L40.0339 44.2528V37.2334L40.0929 37.2657V47.0305L40.0339 46.9979V48.3544L40.0929 48.3867V50.3535Z"
            fill="#596474"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0339 44.2529L39.9748 44.2203V37.331L35.6131 34.9291V41.8183L35.495 41.7531V34.734L40.0339 37.2335V44.2529Z"
            fill="#1B150F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5533 43.5542L35.495 43.522V42.1654L35.5533 42.198V43.5542Z"
            fill="#596474"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0334 48.3545L39.9744 48.291V46.9347L40.0334 46.9979V48.3545ZM35.6126 43.6179L35.5536 43.5543V42.1981L35.6126 42.2613V43.6179Z"
            fill="#596474"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0915 48.3868L40.0332 48.3545V46.9979L40.0915 47.0306V48.3868Z"
            fill="#596474"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5536 47.1113L40.0334 49.5785V50.2561L35.5536 47.7889V47.1113Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.0043 35.3632L7.39636 16.8553L7.72401 16.3185L40.2251 34.2168L41.0043 35.3632Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.72436 16.3185L7.39671 16.8553L4.01111 14.3101L-7.42065 8.01462L-6.21303 7.4823L4.68585 13.4845L7.72436 16.3185Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.2326 27.5916L47.576 30.985L40.2248 34.2167L7.72363 16.3184L19.5504 9.56445L52.2326 27.5916Z"
            fill="#E6E8EA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.187 15.2119L42.9566 31.9525L42.9883 31.9968L42.7554 32.1311L12.137 15.3033L12.187 15.2119Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3711 15.1106L42.9571 31.9525L42.8055 32.0397L12.1875 15.2119L12.3711 15.1106Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5432 14.4685L44.3128 31.2091L44.3445 31.2534L44.1112 31.388L13.4932 14.5599L13.5432 14.4685Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7271 14.3671L44.3135 31.209L44.1619 31.2962L13.5438 14.4684L13.7271 14.3671Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9004 13.7249L45.6704 30.4655L45.7017 30.5098L45.4687 30.6444L14.8503 13.8165L14.9004 13.7249Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0833 13.6235L45.6696 30.4655L45.5181 30.5527L14.8997 13.7248L15.0833 13.6235Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2571 12.9813L47.0267 29.7219L47.0584 29.7662L46.8251 29.9009L16.207 13.073L16.2571 12.9813Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4401 12.8804L47.0265 29.7219L46.8749 29.8095L16.2568 12.9813L16.4401 12.8804Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6145 12.238L48.3841 28.9786L48.4155 29.0229L48.1825 29.1572L17.5645 12.3294L17.6145 12.238Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7985 12.1367L48.3849 28.9786L48.2333 29.0659L17.6152 12.238L17.7985 12.1367Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9719 11.4946L49.7415 28.2352L49.7732 28.2795L49.5403 28.4142L18.9219 11.586L18.9719 11.4946Z"
            fill="#A5B0C2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1543 11.3933L49.7403 28.2352L49.5891 28.3225L18.9707 11.4946L19.1543 11.3933Z"
            fill="#F7F5F3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1365 7.13855L19.5509 9.56455L17.3632 11.5214L11.7719 14.8627L7.72418 16.3185L4.68567 13.4845L16.1365 7.13855Z"
            fill="#CACDD1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.5787 28.4386L47.9208 32.1765L41.0045 35.3632L40.2253 34.2168L44.2526 32.3856C46.3135 31.4484 48.2773 30.3278 50.1171 29.0379L52.2332 27.5917L53.5787 28.4386Z"
            fill="#EBEEF0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0854 42.5791L53.7955 44.3588L51.4084 46.3531C49.8231 47.6774 48.0102 48.7313 46.0493 49.4684L41.2217 51.2834L41.5112 49.5037L45.6853 48.0335C47.7552 47.3047 49.6704 46.2253 51.3385 44.8478L54.0854 42.5791Z"
            fill="#8595AD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.611 29.396L49.7292 32.1565C48.6372 32.9316 47.4609 33.589 46.218 34.1189L41.0037 36.3416V35.3635L45.3416 33.3651C47.058 32.5734 48.7074 31.6561 50.2744 30.621L53.5786 28.4392L53.611 29.396Z"
            fill="#E6E8EA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0632 41.9415L54.078 42.3557L54.0855 42.5793L50.3229 45.6279L46.8542 47.5776L41.5289 49.4974L41.5113 49.5036L41.1199 49.3016L40.7725 49.5036L41.3424 50.5404L41.1199 51.4673L47.0407 49.3992L50.2272 47.6683L54.0632 44.2525L54.5611 43.4595L54.5788 42.4763L54.0632 41.9415Z"
            fill="#E6E8EA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.772 49.5037L41.4403 49.9426L41.4222 50.9258L41.1198 51.4674L40.4587 51.0735V50.1589L40.772 49.5037Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0632 41.9415L54.0855 42.5793L50.3229 45.6279L46.8542 47.5776L41.5289 49.4974L41.5113 49.5036L41.1199 49.3016L40.7725 49.5036L41.4328 49.8978L47.3536 47.8293L50.6254 45.9192L54.5788 42.4763L54.0632 41.9415Z"
            fill="#E9E5E2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.2988 47.0433L41.4508 47.0773L42.0341 46.8678L42.3392 47.5811L42.4174 49.1108L41.5106 49.4511L41.119 49.1235V47.5962L41.2988 47.0433Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.9319 49.9769L42.9316 50.0047C42.9247 50.183 42.7992 50.3804 42.6415 50.456C42.5537 50.4979 42.4735 50.4938 42.4163 50.4529C42.3652 50.4168 42.3328 50.3516 42.3303 50.264C42.3245 50.0789 42.4544 49.8639 42.6207 49.7849C42.7866 49.7056 42.9258 49.7915 42.9319 49.9769Z"
            fill="#FF9500"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.9319 49.9769L42.9316 50.0047C42.9316 50.0047 42.6782 49.9759 42.585 50.0844C42.4976 50.1864 42.4217 50.2946 42.4163 50.4529C42.3652 50.4168 42.3328 50.3516 42.3303 50.264C42.3245 50.0789 42.4544 49.8639 42.6207 49.7849C42.7866 49.7056 42.9258 49.7915 42.9319 49.9769Z"
            fill="#E05D00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.6829 49.9782C42.6858 50.0713 42.6202 50.1794 42.5365 50.2193C42.4529 50.2591 42.383 50.2158 42.3801 50.1224C42.3772 50.0294 42.4425 49.9215 42.5261 49.8817C42.6097 49.8418 42.68 49.8848 42.6829 49.9782Z"
            fill="#FFC226"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.5138 44.0711L53.5135 44.099C53.5066 44.2775 53.3814 44.475 53.2234 44.5506C53.1356 44.5925 53.0554 44.588 52.9982 44.5475C52.9474 44.5111 52.915 44.4458 52.9122 44.3583C52.9068 44.1731 53.0363 43.9585 53.2026 43.8792C53.3685 43.7998 53.5077 43.8857 53.5138 44.0711Z"
            fill="#FF9500"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.5138 44.0711L53.5135 44.099C53.5135 44.099 53.2601 44.0701 53.1669 44.1786C53.0798 44.2806 53.0039 44.3888 52.9982 44.5475C52.9474 44.5111 52.915 44.4458 52.9122 44.3583C52.9068 44.1731 53.0363 43.9585 53.2026 43.8792C53.3685 43.7998 53.5077 43.8857 53.5138 44.0711Z"
            fill="#E05D00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.2638 44.0725C53.2667 44.1659 53.2015 44.2738 53.1178 44.3136C53.0342 44.3534 52.9639 44.3102 52.961 44.2171C52.9581 44.124 53.0238 44.0158 53.1074 43.976C53.191 43.9362 53.2609 43.9794 53.2638 44.0725Z"
            fill="#FFC226"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.0058 47.327V47.3452C42.0015 47.4609 41.9244 47.5894 41.8275 47.6385C41.7735 47.6656 41.7241 47.6629 41.6888 47.6364C41.6575 47.6127 41.6376 47.5705 41.6358 47.5138C41.6326 47.3933 41.7122 47.2535 41.8145 47.202C41.9165 47.1508 42.0022 47.2065 42.0058 47.327Z"
            fill="#B9C1D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.0058 47.327V47.3452C42.0058 47.3452 41.8498 47.3263 41.7925 47.3971C41.7389 47.463 41.692 47.5334 41.6888 47.6364C41.6575 47.6127 41.6376 47.5705 41.6358 47.5138C41.6326 47.3933 41.7122 47.2535 41.8145 47.202C41.9165 47.1508 42.0022 47.2065 42.0058 47.327Z"
            fill="#606C70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.8523 47.3276C41.8541 47.3884 41.8143 47.4585 41.7629 47.4846C41.7116 47.5103 41.6688 47.4822 41.6671 47.4217C41.6653 47.3613 41.7051 47.2909 41.7565 47.2651C41.8078 47.239 41.8505 47.2672 41.8523 47.3276Z"
            fill="#D7D7D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.7734 47.9667V47.985C41.7691 48.101 41.692 48.2291 41.5951 48.2782C41.5411 48.3057 41.4913 48.3026 41.4564 48.2762C41.4251 48.2528 41.4052 48.2102 41.4034 48.1536C41.3998 48.033 41.4798 47.8936 41.5821 47.8421C41.6841 47.7906 41.7698 47.8462 41.7734 47.9667Z"
            fill="#E67B10"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.7734 47.9667V47.985C41.7734 47.985 41.6174 47.9661 41.5601 48.0368C41.5065 48.1031 41.4596 48.1731 41.4564 48.2762C41.4251 48.2528 41.4052 48.2102 41.4034 48.1536C41.3998 48.033 41.4798 47.8936 41.5821 47.8421C41.6841 47.7906 41.7698 47.8462 41.7734 47.9667Z"
            fill="#B34700"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.6219 47.9678C41.6237 48.0283 41.5834 48.0983 41.5325 48.1244C41.4811 48.1505 41.4384 48.1224 41.4366 48.0616C41.4348 48.0011 41.4746 47.9311 41.526 47.905C41.577 47.8792 41.6201 47.907 41.6219 47.9678Z"
            fill="#E8B725"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.0981 48.531L42.0978 48.5581C42.0913 48.7323 41.9758 48.9249 41.8304 48.9988C41.7494 49.0396 41.6753 49.0352 41.6224 48.9957C41.5756 48.9603 41.5458 48.8964 41.5432 48.8113C41.5382 48.6306 41.6577 48.4211 41.811 48.3438C41.9639 48.2662 42.0924 48.3504 42.0981 48.531Z"
            fill="#B9C1D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.0981 48.531L42.0978 48.5581C42.0978 48.5581 41.8639 48.53 41.7782 48.6361C41.6976 48.7354 41.6274 48.8408 41.6224 48.9957C41.5756 48.9603 41.5458 48.8964 41.5432 48.8113C41.5382 48.6306 41.6577 48.4211 41.811 48.3438C41.9639 48.2662 42.0924 48.3504 42.0981 48.531Z"
            fill="#606C70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.8672 48.5324C41.8698 48.6234 41.8098 48.7289 41.7329 48.7677C41.6561 48.8065 41.5915 48.7642 41.589 48.6736C41.5861 48.5826 41.6464 48.4771 41.7233 48.4383C41.8001 48.3992 41.8647 48.4414 41.8672 48.5324Z"
            fill="#D7D7D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0028 40.1547L53.3914 40.6894L53.2656 41.4769L53.3255 43.0251L54.0748 42.4481V40.7594L54.0028 40.1547Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0155 41.2042L54.0162 41.2214C54.0177 41.3313 53.9489 41.4707 53.8567 41.5405C53.8055 41.5789 53.7569 41.5882 53.7212 41.5721C53.6895 41.5573 53.6682 41.5223 53.6639 41.4694C53.6545 41.3567 53.7255 41.2063 53.8224 41.1331C53.9197 41.0603 54.0062 41.0919 54.0155 41.2042Z"
            fill="#E67B10"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0155 41.2042L54.0162 41.2214C54.0162 41.2214 53.8631 41.2413 53.8109 41.3217C53.7615 41.3969 53.7194 41.4742 53.7212 41.5721C53.6895 41.5573 53.6682 41.5223 53.6639 41.4694C53.6545 41.3567 53.7255 41.2063 53.8224 41.1331C53.9197 41.0603 54.0062 41.0919 54.0155 41.2042Z"
            fill="#B34700"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.866 41.2424C53.8707 41.2987 53.8352 41.3746 53.7867 41.4114C53.7379 41.4481 53.6948 41.432 53.6897 41.3757C53.6851 41.319 53.721 41.2434 53.7695 41.2067C53.8183 41.1699 53.8614 41.1857 53.866 41.2424Z"
            fill="#E8B725"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.787 40.7539L53.7877 40.7711C53.7895 40.881 53.7207 41.0204 53.6285 41.0898C53.577 41.1286 53.5287 41.1379 53.493 41.1214C53.4613 41.107 53.4397 41.072 53.4354 41.0187C53.426 40.9064 53.497 40.756 53.5943 40.6829C53.6912 40.6097 53.7776 40.6416 53.787 40.7539Z"
            fill="#B9C1D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.787 40.7539L53.7877 40.7711C53.7877 40.7711 53.635 40.791 53.5824 40.8714C53.5334 40.9463 53.4912 41.0239 53.493 41.1214C53.4613 41.107 53.4397 41.072 53.4354 41.0187C53.426 40.9064 53.497 40.756 53.5943 40.6829C53.6912 40.6097 53.7776 40.6416 53.787 40.7539Z"
            fill="#606C70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.6367 40.7918C53.6413 40.8485 53.6058 40.924 53.557 40.9608C53.5085 40.9979 53.465 40.9817 53.4604 40.9251C53.4557 40.8687 53.4912 40.7932 53.5401 40.7564C53.5886 40.7193 53.632 40.7355 53.6367 40.7918Z"
            fill="#D7D7D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9927 41.9519L53.9937 41.9777C53.9959 42.1425 53.893 42.3517 53.7544 42.4561C53.6774 42.5141 53.6047 42.5282 53.5515 42.5035C53.504 42.4815 53.4716 42.429 53.4651 42.3496C53.4511 42.1807 53.5576 41.955 53.7033 41.8451C53.8491 41.7356 53.9783 41.7833 53.9927 41.9519Z"
            fill="#B9C1D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9927 41.9519L53.9937 41.9777C53.9937 41.9777 53.7641 42.0076 53.6857 42.1281C53.6119 42.2408 53.549 42.3568 53.5515 42.5035C53.504 42.4815 53.4716 42.429 53.4651 42.3496C53.4511 42.1807 53.5576 41.955 53.7033 41.8451C53.8491 41.7356 53.9783 41.7833 53.9927 41.9519Z"
            fill="#606C70"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.7682 42.0089C53.7754 42.0937 53.7219 42.207 53.6486 42.2623C53.5757 42.3176 53.5108 42.2936 53.5036 42.2087C53.4964 42.1239 53.5499 42.0102 53.6231 41.9553C53.696 41.9 53.761 41.924 53.7682 42.0089Z"
            fill="#D7D7D7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.396 43.5052L48.1948 43.3562L47.554 35.7478L47.7789 35.6293L48.396 43.5052Z"
            fill="#383838"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.238 43.1646C49.7373 44.3796 48.0414 45.356 46.2148 46.0569L46.7168 47.25C48.0666 46.6889 49.3401 45.9745 50.5107 45.1231L51.238 43.1646Z"
            fill="#CDD4DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.8987 45.7719L48.0793 46.2225V45.3264L48.8987 44.8759V45.7719Z"
            fill="#635E59"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-7.42065 8.0146L-3.89895 5.60508C-2.05295 4.34194 -0.0895638 3.24296 1.96635 2.32153L4.91413 1L5.40956 1.04121H5.06175L2.05817 2.44688C0.267251 3.2852 -1.45021 4.25952 -3.0773 5.36056L-6.21303 7.48228L-7.42065 8.0146Z"
            fill="#A8ADB4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.06116 1.04126L2.05757 2.44693C0.266657 3.28525 -1.45081 4.25956 -3.0779 5.36061L-6.21362 7.48233L4.68526 13.4845L9.15031 11.8278L13.9221 8.98451L16.1361 7.13856L5.06116 1.04126Z"
            fill="#E6E8EA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0561 39.065L38.712 39.0973C38.4303 39.1512 38.2019 39.4854 38.2019 39.8432V41.9203C38.2019 42.2782 38.4303 42.5244 38.712 42.4705L39.1832 42.3936C39.4646 42.3393 39.5658 42.0501 39.5658 41.6923V39.6152C39.5658 39.2573 39.3378 39.0111 39.0561 39.065Z"
            fill="#322920"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0561 39.1937L38.712 39.226C38.4303 39.2799 38.2019 39.614 38.2019 39.9719V42.049C38.2019 42.4068 38.4303 42.6531 38.712 42.5992L39.1832 42.5222C39.4646 42.468 39.5658 42.1788 39.5658 41.8209V39.7439C39.5658 39.386 39.3378 39.1398 39.0561 39.1937Z"
            fill="#484038"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.8826 43.1006L40.2688 44.3239L40.3268 44.0753L39.0958 42.919V39.0787L40.7458 37.5278L40.65 37.3464L38.8826 38.9537V43.1006Z"
            fill="#322920"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_115_168190">
          <rect
            width="63"
            height="50.4674"
            fill="white"
            transform="translate(-8 1)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
