import { useMemo } from "react";
import { BusInfo, BusFilter } from "interfaces/bus.interface";
import { orderBy } from "lodash";

export const useBusFiltering = (
  busesForSearch: BusInfo[],
  busFilter: BusFilter
) => {
  const busGroups = useMemo(() => {
    const isInZone = (bus: BusInfo, zoneName: string) =>
      bus.geozonas0Nombre === zoneName || bus.geozonas1Nombre === zoneName;

    return {
      mine:
        busesForSearch
          ?.filter((bus) => !isInZone(bus, "Estacionamiento VCA"))
          .map((bus) => ({
            ...bus,
            type: BusFilter.MINE,
          })) ?? [],
      vca:
        busesForSearch
          ?.filter((bus) => isInZone(bus, "Estacionamiento VCA"))
          .map((bus) => ({
            ...bus,
            type: BusFilter.VCA,
          })) ?? [],
    };
  }, [busesForSearch]);

  const filteredBuses = useMemo(() => {
    switch (busFilter) {
      case BusFilter.VCA:
        return busGroups.vca;
      case BusFilter.MINE:
        return busGroups.mine;
      default:
        return busesForSearch;
    }
  }, [busesForSearch, busFilter, busGroups]);

  const sortedFilteredBuses = useMemo(
    () =>
      orderBy(
        filteredBuses,
        [(bus) => bus.moving, (bus) => new Date(bus.datetimeValue).getTime()],
        ["desc", "desc"]
      ),
    [filteredBuses]
  );

  const filteredBusesResume = useMemo(
    () => [
      { name: "TODOS", count: busesForSearch?.length, id: BusFilter.ALL },
      { name: "MINA", count: busGroups.mine?.length, id: BusFilter.MINE },
      { name: "VCA", count: busGroups.vca?.length, id: BusFilter.VCA },
    ],
    [busesForSearch, busGroups]
  );

  const allBusesGroups = useMemo(() => {
    return [...busGroups?.vca, ...busGroups?.mine];
  }, [busGroups]);

  return {
    filteredBuses: sortedFilteredBuses,
    filteredBusesResume,
    allBusesGroups,
  };
};
