import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const BusOutdatedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="84"
      height="147"
      viewBox="0 0 84 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0004 118.998C10.0004 118.998 14.539 145.997 15.591 145.997H67.4085C68.4605 145.997 72.999 118.998 72.999 118.998H10.0004Z"
        fill="#D32F2F"
      />
      <path
        d="M67.9971 142.977C67.9971 142.437 67.4171 141.997 66.7071 141.997H60.6072C59.8972 141.997 59.3173 142.437 59.3173 142.977C59.3173 143.517 59.8972 143.957 60.6072 143.957H66.7071C67.4171 143.957 67.9971 143.517 67.9971 142.977Z"
        fill="url(#paint1_radial_1252_2194)"
      />
      <path
        d="M14.91 142.977C14.91 142.437 15.49 141.997 16.2 141.997H22.2999C23.0099 141.997 23.5898 142.437 23.5898 142.977C23.5898 143.517 23.0099 143.957 22.2999 143.957H16.2C15.49 143.957 14.91 143.517 14.91 142.977Z"
        fill="url(#paint2_radial_1252_2194)"
      />
      <path
        d="M67.8797 127.319C50.2196 134.889 32.3411 134.889 14.681 127.319C11.6831 124.208 12.5765 121.097 10.9981 117.986C11.0775 117.68 11.1371 117.339 11.1966 116.997C21.1931 121.547 31.2392 119.479 41.2853 119.479C51.3314 119.479 60.8911 121.421 70.6394 117.303C70.7188 118.076 70.838 118.813 70.9968 119.425C70.1828 122.05 70.421 124.693 67.8896 127.319H67.8797Z"
        fill="url(#paint3_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M39.0004 120.38C29.7173 120.618 20.4342 121.927 11.2011 117.998C11.1411 118.338 11.0811 118.644 11.001 118.934C12.5916 121.876 11.6913 124.819 14.7123 127.762C19.5439 129.701 24.3855 131.096 29.2371 131.998C32.1281 127.354 35.4092 123.424 38.9904 120.38H39.0004Z"
        fill="url(#paint4_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M42.0007 132.998C41.6648 132.998 41.3367 132.998 41.0007 132.998V120.998H42.0007V132.998Z"
        fill="url(#paint5_linear_1252_2194)"
      />
      <path
        d="M6.86764 120.126C6.80764 120.126 6.74764 120.106 6.69764 120.056C6.60764 119.966 6.59764 119.816 6.69764 119.716L10.4476 115.836C10.5376 115.746 10.6876 115.736 10.7876 115.836C10.8775 115.926 10.8875 116.076 10.7876 116.176L7.03763 120.056C7.03763 120.056 6.92764 120.126 6.86764 120.126Z"
        fill="black"
      />
      <path
        d="M8.05826 121.606C7.89827 121.606 7.74827 121.526 7.65827 121.386C7.51828 121.166 7.57827 120.866 7.80827 120.726L11.7182 118.236C11.9382 118.096 12.2282 118.156 12.3782 118.386C12.5182 118.606 12.4582 118.906 12.2282 119.046L8.31826 121.536C8.23826 121.586 8.14826 121.606 8.06826 121.606H8.05826Z"
        fill="black"
      />
      <path
        d="M8.22907 121.636C8.22907 121.026 7.72908 119.406 7.11909 119.406H1.39922C-0.450739 119.406 -0.0507477 121.396 0.289245 121.636H8.22907Z"
        fill="url(#paint6_linear_1252_2194)"
      />
      <path
        d="M0.0644022 120.265C0.0544024 119.835 0.61439 119.405 0.914383 119.405H7.11425C7.41424 119.405 7.68423 119.795 7.88423 120.265H0.0644022Z"
        fill="url(#paint7_linear_1252_2194)"
      />
      <path
        d="M76.4806 120.728C76.4206 120.728 76.3606 120.708 76.3106 120.659L72.5607 116.779C72.4707 116.689 72.4707 116.529 72.5607 116.439C72.6507 116.349 72.8007 116.349 72.9007 116.439L76.6506 120.319C76.7406 120.409 76.7406 120.569 76.6506 120.659C76.6006 120.698 76.5406 120.728 76.4806 120.728Z"
        fill="black"
      />
      <path
        d="M75.2914 122.219C75.2014 122.219 75.1114 122.199 75.0314 122.149L71.1215 119.659C70.9015 119.519 70.8315 119.229 70.9715 118.999C71.1115 118.779 71.4015 118.709 71.6315 118.849L75.5414 121.339C75.7614 121.479 75.8314 121.769 75.6914 121.999C75.6014 122.139 75.4514 122.219 75.2914 122.219Z"
        fill="black"
      />
      <path
        d="M75.1308 122.249C75.1308 121.639 75.6308 120.019 76.2408 120.019H81.9507C83.8007 120.019 83.4007 122.009 83.0607 122.249H75.1208H75.1308Z"
        fill="url(#paint8_linear_1252_2194)"
      />
      <path
        d="M83.2823 120.879C83.2923 120.449 82.7323 120.029 82.4323 120.029H76.2325C75.9325 120.029 75.6625 120.419 75.4625 120.879H83.2823Z"
        fill="url(#paint9_linear_1252_2194)"
      />
      <path
        d="M72.9967 119.63C72.9967 120.689 58.8956 120.997 41.4924 120.997C24.0892 120.997 9.99811 120.689 9.99811 119.63L9.99811 3.51151C9.99811 2.89468 72.9967 2.76729 72.9967 3.51151V119.63Z"
        fill="url(#paint10_radial_1252_2194)"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M33.8286 86.5986H47.8083C49.5583 86.5986 50.9882 88.0186 50.9882 89.7786V99.4983C50.9882 101.248 49.5683 102.678 47.8083 102.678H33.8286C32.0787 102.678 30.6487 101.258 30.6487 99.4983V89.7786C30.6487 88.0286 32.0687 86.5986 33.8286 86.5986Z"
          fill="#E8E8E8"
        />
        <path
          d="M33.8287 86.6885H47.8084C49.5084 86.6885 50.8883 88.0684 50.8883 89.7684V99.4882C50.8883 101.188 49.5084 102.568 47.8084 102.568H33.8287C32.1288 102.568 30.7488 101.188 30.7488 99.4882L30.7488 89.7684C30.7488 88.0684 32.1288 86.6885 33.8287 86.6885Z"
          fill="url(#paint11_radial_1252_2194)"
        />
        <path
          d="M33.8264 86.7871H47.8061C49.4561 86.7871 50.796 88.1271 50.796 89.777V99.4968C50.796 101.147 49.4561 102.487 47.8061 102.487H33.8264C32.1765 102.487 30.8365 101.147 30.8365 99.4968L30.8365 89.777C30.8365 88.1271 32.1765 86.7871 33.8264 86.7871Z"
          fill="url(#paint12_radial_1252_2194)"
        />
        <path
          d="M33.8283 86.8887H47.808C49.3979 86.8887 50.6979 88.1786 50.6979 89.7786V99.4984C50.6979 101.088 49.4079 102.388 47.808 102.388H33.8283C32.2383 102.388 30.9384 101.098 30.9384 99.4984L30.9384 89.7786C30.9384 88.1886 32.2283 86.8887 33.8283 86.8887Z"
          fill="url(#paint13_radial_1252_2194)"
        />
        <path
          d="M33.8283 86.9775H47.808C49.348 86.9775 50.598 88.2275 50.598 89.7675V99.4873C50.598 101.027 49.348 102.277 47.808 102.277H33.8283C32.2884 102.277 31.0384 101.027 31.0384 99.4873L31.0384 89.7675C31.0384 88.2275 32.2884 86.9775 33.8283 86.9775Z"
          fill="url(#paint14_radial_1252_2194)"
        />
        <path
          d="M33.8303 87.0791H47.81C49.3 87.0791 50.5099 88.2891 50.5099 89.779V99.4988C50.5099 100.989 49.3 102.199 47.81 102.199H33.8303C32.3403 102.199 31.1304 100.989 31.1304 99.4988L31.1304 89.779C31.1304 88.2891 32.3403 87.0791 33.8303 87.0791Z"
          fill="url(#paint15_radial_1252_2194)"
        />
        <path
          d="M33.8279 87.1777H47.8075C49.2475 87.1777 50.4075 88.3477 50.4075 89.7777V99.4975C50.4075 100.937 49.2375 102.097 47.8075 102.097H33.8279C32.3879 102.097 31.2279 100.927 31.2279 99.4975L31.2279 89.7777C31.2279 88.3477 32.3879 87.1777 33.8279 87.1777Z"
          fill="url(#paint16_radial_1252_2194)"
        />
        <path
          d="M33.8276 87.2686H47.8073C49.1873 87.2686 50.3073 88.3885 50.3073 89.7685V99.4883C50.3073 100.868 49.1873 101.988 47.8073 101.988H33.8276C32.4477 101.988 31.3277 100.868 31.3277 99.4883L31.3277 89.7685C31.3277 88.3885 32.4477 87.2686 33.8276 87.2686Z"
          fill="url(#paint17_radial_1252_2194)"
        />
        <path
          d="M33.8299 87.3682H47.8096C49.1396 87.3682 50.2196 88.4481 50.2196 89.7781V99.4979C50.2196 100.828 49.1396 101.908 47.8096 101.908H33.8299C32.5 101.908 31.42 100.828 31.42 99.4979L31.42 89.7781C31.42 88.4481 32.5 87.3682 33.8299 87.3682Z"
          fill="url(#paint18_radial_1252_2194)"
        />
        <path
          d="M33.8297 87.458H47.8094C49.0894 87.458 50.1194 88.498 50.1194 89.768V99.4877C50.1194 100.768 49.0794 101.798 47.8094 101.798H33.8297C32.5498 101.798 31.5198 100.758 31.5198 99.4877L31.5198 89.768C31.5198 88.488 32.5598 87.458 33.8297 87.458Z"
          fill="url(#paint19_radial_1252_2194)"
        />
        <path
          d="M33.8274 87.5576H47.8071C49.027 87.5576 50.027 88.5476 50.027 89.7776V99.4974C50.027 100.717 49.037 101.717 47.8071 101.717H33.8274C32.6074 101.717 31.6074 100.727 31.6074 99.4974L31.6074 89.7776C31.6074 88.5576 32.5974 87.5676 33.8174 87.5676L33.8274 87.5576Z"
          fill="url(#paint20_radial_1252_2194)"
        />
        <path
          d="M47.8089 87.6582H33.8292C32.6584 87.6582 31.7093 88.6073 31.7093 89.7782L31.7093 99.4979C31.7093 100.669 32.6584 101.618 33.8292 101.618H47.8089C48.9798 101.618 49.9289 100.669 49.9289 99.4979V89.7782C49.9289 88.6073 48.9798 87.6582 47.8089 87.6582Z"
          fill="url(#paint21_radial_1252_2194)"
        />
      </g>
      <path
        d="M47.8089 87.6582H33.8292C32.6584 87.6582 31.7093 88.6073 31.7093 89.7782L31.7093 99.4979C31.7093 100.669 32.6584 101.618 33.8292 101.618H47.8089C48.9798 101.618 49.9289 100.669 49.9289 99.4979V89.7782C49.9289 88.6073 48.9798 87.6582 47.8089 87.6582Z"
        fill="#F44336"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M33.0668 13.4102H48.5565C50.4964 13.4102 52.0764 14.9901 52.0764 16.9301V54.8492C52.0764 56.7892 50.4964 58.3692 48.5565 58.3692H33.0668C31.1268 58.3692 29.5469 56.7892 29.5469 54.8492L29.5469 16.9301C29.5469 14.9901 31.1268 13.4102 33.0668 13.4102Z"
          fill="url(#paint22_radial_1252_2194)"
        />
        <path
          d="M48.5683 13.5107H33.0787C31.1899 13.5107 29.6588 15.0419 29.6588 16.9307L29.6588 54.8398C29.6588 56.7286 31.1899 58.2598 33.0787 58.2598H48.5683C50.4571 58.2598 51.9883 56.7286 51.9883 54.8398L51.9883 16.9307C51.9883 15.0419 50.4571 13.5107 48.5683 13.5107Z"
          fill="url(#paint23_radial_1252_2194)"
        />
        <path
          d="M33.0684 13.6201H48.5581C50.388 13.6201 51.868 15.1001 51.868 16.93L51.868 54.8492C51.868 56.6792 50.388 58.1591 48.5581 58.1591H33.0684C31.2385 58.1591 29.7585 56.6792 29.7585 54.8492L29.7585 16.93C29.7585 15.1001 31.2385 13.6201 33.0684 13.6201Z"
          fill="url(#paint24_radial_1252_2194)"
        />
        <path
          d="M33.068 13.7295H48.5577C50.3277 13.7295 51.7576 15.1595 51.7576 16.9294L51.7576 54.8486C51.7576 56.6185 50.3277 58.0485 48.5577 58.0485H33.068C31.2981 58.0485 29.8681 56.6185 29.8681 54.8486L29.8681 16.9294C29.8681 15.1595 31.3081 13.7295 33.068 13.7295Z"
          fill="url(#paint25_radial_1252_2194)"
        />
        <path
          d="M33.0678 13.8303H48.5674C50.2774 13.8303 51.6574 15.2203 51.6574 16.9202L51.6574 54.8394C51.6574 56.5494 50.2674 57.9393 48.5574 57.9393H33.0678C31.3578 57.9393 29.9678 56.5494 29.9678 54.8394L29.9678 16.9202C29.9678 15.2103 31.3578 13.8203 33.0678 13.8203V13.8303Z"
          fill="url(#paint26_radial_1252_2194)"
        />
        <path
          d="M33.0649 13.9385H48.5546C50.2046 13.9385 51.5445 15.2784 51.5445 16.9284L51.5445 54.8476C51.5445 56.4975 50.2046 57.8375 48.5546 57.8375H33.0649C31.415 57.8375 30.075 56.4975 30.075 54.8476L30.075 16.9284C30.075 15.2784 31.415 13.9385 33.0649 13.9385Z"
          fill="url(#paint27_radial_1252_2194)"
        />
        <path
          d="M33.0687 14.0508H48.5684C50.1583 14.0508 51.4483 15.3408 51.4483 16.9307L51.4483 54.8499C51.4483 56.4398 50.1583 57.7298 48.5684 57.7298H33.0787C31.4888 57.7298 30.1988 56.4398 30.1988 54.8499L30.1988 16.9307C30.1988 15.3408 31.4888 14.0508 33.0787 14.0508H33.0687Z"
          fill="url(#paint28_radial_1252_2194)"
        />
        <path
          d="M33.0707 14.1514H48.5704C50.1003 14.1514 51.3403 15.3913 51.3403 16.9213L51.3403 54.8405C51.3403 56.3704 50.1003 57.6204 48.5604 57.6204H33.0707C31.5408 57.6204 30.3008 56.3804 30.3008 54.8505L30.3008 16.9313C30.3008 15.4013 31.5408 14.1514 33.0807 14.1514H33.0707Z"
          fill="url(#paint29_radial_1252_2194)"
        />
        <path
          d="M33.0656 14.2598H48.5653C50.0353 14.2598 51.2352 15.4597 51.2352 16.9297L51.2352 54.8489C51.2352 56.3188 50.0353 57.5188 48.5653 57.5188H33.0756C31.6057 57.5188 30.4057 56.3188 30.4057 54.8489L30.4057 16.9297C30.4057 15.4597 31.6057 14.2598 33.0756 14.2598H33.0656Z"
          fill="url(#paint30_radial_1252_2194)"
        />
        <path
          d="M33.0653 14.3691H48.5549C49.9649 14.3691 51.1149 15.5191 51.1149 16.9291L51.1149 54.8482C51.1149 56.2582 49.9649 57.4082 48.5549 57.4082H33.0653C31.6553 57.4082 30.5053 56.2582 30.5053 54.8482L30.5053 16.9291C30.5053 15.5191 31.6553 14.3691 33.0653 14.3691Z"
          fill="url(#paint31_radial_1252_2194)"
        />
        <path
          d="M33.0693 14.4805H48.569C49.919 14.4805 51.0189 15.5804 51.0189 16.9304L51.0189 54.8496C51.0189 56.2095 49.919 57.3095 48.559 57.3095H33.0693C31.7194 57.3095 30.6194 56.2095 30.6194 54.8596L30.6194 16.9404C30.6194 15.5804 31.7194 14.4805 33.0793 14.4805H33.0693Z"
          fill="url(#paint32_radial_1252_2194)"
        />
        <path
          d="M33.0665 14.5801H48.5662C49.8661 14.5801 50.9161 15.6301 50.9161 16.93L50.9161 54.8492C50.9161 56.1492 49.8661 57.1991 48.5662 57.1991H33.0765C31.7765 57.1991 30.7266 56.1492 30.7266 54.8492L30.7266 16.93C30.7266 15.6301 31.7765 14.5801 33.0765 14.5801H33.0665Z"
          fill="url(#paint33_radial_1252_2194)"
        />
      </g>
      <path
        d="M33.0665 14.5801H48.5662C49.8661 14.5801 50.9161 15.6301 50.9161 16.93L50.9161 54.8492C50.9161 56.1492 49.8661 57.1991 48.5662 57.1991H33.0765C31.7765 57.1991 30.7266 56.1492 30.7266 54.8492L30.7266 16.93C30.7266 15.6301 31.7765 14.5801 33.0765 14.5801H33.0665Z"
        fill="url(#paint34_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M33.0665 14.5801H48.5662C49.8661 14.5801 50.9161 15.6301 50.9161 16.93L50.9161 54.8492C50.9161 56.1492 49.8661 57.1991 48.5662 57.1991H33.0765C31.7765 57.1991 30.7266 56.1492 30.7266 54.8492L30.7266 16.93C30.7266 15.6301 31.7765 14.5801 33.0765 14.5801H33.0665Z"
        fill="#D32F2F"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M13.998 119.997C11.4507 119.797 9.99811 119.542 9.99811 119.214L9.99811 3.24107C9.99811 3.14732 11.4823 3.06696 13.998 3L13.998 119.997Z"
        fill="url(#paint35_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "screen" }}
        d="M68.9993 119.997C71.5466 119.797 72.9992 119.542 72.9992 119.214L72.9992 3.24107C72.9992 3.14732 71.5151 3.06696 68.9993 3L68.9993 119.997Z"
        fill="url(#paint36_linear_1252_2194)"
      />
      <path
        d="M70.3997 1.15039H12.361L10.071 3.42034H72.8397L70.3997 1.15039Z"
        fill="url(#paint37_linear_1252_2194)"
      />
      <path
        d="M69.0789 0H13.6801L12.3602 1.14997H70.3989L69.0789 0Z"
        fill="url(#paint38_linear_1252_2194)"
      />
      <path
        d="M50.777 145.606H32.1275L31.6575 144.066H51.257L50.777 145.606Z"
        fill="url(#paint39_linear_1252_2194)"
      />
      <path
        d="M69.0362 2.86914H13.3274L12.4675 3.65912H69.9762L69.0362 2.86914Z"
        fill="url(#paint40_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8992 35.8906H37.7293V39.3405H43.8992V35.8906Z"
        fill="url(#paint41_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M48.4504 51.7705H33.1707V53.0505H48.4504V51.7705Z"
        fill="url(#paint42_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8975 32.2295H37.7277V33.0895H43.8975V32.2295Z"
        fill="url(#paint43_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8997 30.75H37.7299V31.61H43.8997V30.75Z"
        fill="black"
        fill-opacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8997 29.2803H37.7299V30.1403H43.8997V29.2803Z"
        fill="black"
        fill-opacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8973 27.7998H37.7275V28.6598H43.8973V27.7998Z"
        fill="black"
        fill-opacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8972 26.3291H37.7274V27.1891H43.8972V26.3291Z"
        fill="black"
        fill-opacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M43.8969 24.8604H37.7271V25.7203H43.8969V24.8604Z"
        fill="black"
        fill-opacity="0.16"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M49.9288 38.6094H49.0888V46.6892H49.9288V38.6094Z"
        fill="url(#paint44_linear_1252_2194)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        d="M32.5398 38.6094H31.6998V46.6892H32.5398V38.6094Z"
        fill="url(#paint45_linear_1252_2194)"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M67.9983 74.9995H67.5233L67.5233 7.25464H15.4645L15.4645 74.9995H14.9995L14.9995 7.00098H67.9983L67.9983 74.9995Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        d="M67.2187 145.588H15.5399V146.378H67.2187V145.588Z"
        fill="url(#paint46_linear_1252_2194)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1252_2194"
          x1="61.8303"
          y1="68.4386"
          x2="83.2074"
          y2="68.9203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#242424" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.6572 142.969) scale(3.19993 2.43194)"
        >
          <stop stop-color="#F7F7F7" />
          <stop offset="1" stop-color="#DBDBDB" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.2499 168.306) rotate(180) scale(3.19993 2.43194)"
        >
          <stop stop-color="#F7F7F7" />
          <stop offset="1" stop-color="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1252_2194"
          x1="40.9974"
          y1="120.594"
          x2="40.9974"
          y2="142.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1252_2194"
          x1="36.2595"
          y1="134.107"
          x2="21.676"
          y2="128.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3B3B3B" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1252_2194"
          x1="41.5007"
          y1="123.983"
          x2="41.5007"
          y2="140.204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1252_2194"
          x1="-0.000748826"
          y1="120.526"
          x2="8.22907"
          y2="120.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADADAD" />
          <stop offset="1" stop-color="#878787" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1252_2194"
          x1="0.0644022"
          y1="119.835"
          x2="7.88423"
          y2="119.835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1252_2194"
          x1="83.3607"
          y1="121.129"
          x2="75.1308"
          y2="121.129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADADAD" />
          <stop offset="1" stop-color="#878787" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1252_2194"
          x1="83.2923"
          y1="120.439"
          x2="75.4725"
          y2="120.439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.4974 61.9987) rotate(90) scale(58.9987 31.4993)"
        >
          <stop stop-color="#F44336" />
          <stop offset="1" stop-color="#D32F2F" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(68.8793 92.5284) rotate(-5.71) scale(9.77318 9.66579)"
        >
          <stop stop-color="#E9E9E9" />
          <stop offset="1" stop-color="#E7E7E7" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(97.9141 82.0614) rotate(-12.53) scale(11.1634 10.1002)"
        >
          <stop stop-color="#EBEBEB" />
          <stop offset="1" stop-color="#E6E6E6" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(127.805 62.9403) rotate(-20.55) scale(14.0475 10.6257)"
        >
          <stop stop-color="#ECECEC" />
          <stop offset="1" stop-color="#E4E4E4" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(162.403 24.6069) rotate(-29.74) scale(21.352 10.8223)"
        >
          <stop stop-color="#EDEDED" />
          <stop offset="1" stop-color="#E3E3E3" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(279.958 -103.538) rotate(-39.8) scale(61.1793 11.2019)"
        >
          <stop stop-color="#EFEFEF" />
          <stop offset="1" stop-color="#E2E2E2" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-70.9117 229.461) rotate(-50.2) scale(60.6469 11.1044)"
        >
          <stop stop-color="#F0F0F0" />
          <stop offset="1" stop-color="#E1E1E1" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.6149 103.662) rotate(-60.26) scale(20.7826 10.5337)"
        >
          <stop stop-color="#F2F2F2" />
          <stop offset="1" stop-color="#E0E0E0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.597 81.5176) rotate(-69.45) scale(13.4001 10.136)"
        >
          <stop stop-color="#F3F3F3" />
          <stop offset="1" stop-color="#DFDFDF" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.2435 79.0646) rotate(-77.47) scale(10.4158 9.42379)"
        >
          <stop stop-color="#F4F4F4" />
          <stop offset="1" stop-color="#DDDDDD" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.5417 85.0066) rotate(-84.29) scale(8.89961 8.80181)"
        >
          <stop stop-color="#F6F6F6" />
          <stop offset="1" stop-color="#DCDCDC" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8191 94.638) rotate(-90) scale(8.11982 8.11982)"
        >
          <stop stop-color="#F7F7F7" />
          <stop offset="1" stop-color="#DBDBDB" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8166 35.8797) scale(17.7796)"
        >
          <stop stop-color="#ECECEC" />
          <stop offset="1" stop-color="white" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6919 36.2955) rotate(-5.71) scale(18.6455)"
        >
          <stop stop-color="#EDEDED" />
          <stop offset="1" stop-color="#FCFCFC" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8636 35.6738) rotate(-12.53) scale(19.748 19.748)"
        >
          <stop stop-color="#EEEEEE" />
          <stop offset="1" stop-color="#F8F8F8" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.884 35.4896) rotate(-20.55) scale(20.7397 20.7397)"
        >
          <stop stop-color="#EFEFEF" />
          <stop offset="1" stop-color="#F5F5F5" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8068 36.261) rotate(-29.74) scale(21.3301 21.3301)"
        >
          <stop stop-color="#F0F0F0" />
          <stop offset="1" stop-color="#F2F2F2" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8226 35.8867) rotate(-39.8) scale(21.8036)"
        >
          <stop stop-color="#F1F1F1" />
          <stop offset="1" stop-color="#EFEFEF" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8225 35.8906) rotate(-50.2) scale(21.6971 21.6971)"
        >
          <stop stop-color="#F2F2F2" />
          <stop offset="1" stop-color="#EBEBEB" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.9031 36.2123) rotate(-60.26) scale(21.0089)"
        >
          <stop stop-color="#F3F3F3" />
          <stop offset="1" stop-color="#E8E8E8" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.6811 35.5561) rotate(-69.45) scale(20.2094 20.2094)"
        >
          <stop stop-color="#F4F4F4" />
          <stop offset="1" stop-color="#E5E5E5" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.7309 35.7274) rotate(-77.47) scale(19.0172)"
        >
          <stop stop-color="#F5F5F5" />
          <stop offset="1" stop-color="#E2E2E2" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.0129 36.171) rotate(-84.29) scale(17.7173 17.7173)"
        >
          <stop stop-color="#F6F6F6" />
          <stop offset="1" stop-color="#DEDEDE" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_1252_2194"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8163 35.8896) rotate(-90) scale(16.6696 16.6696)"
        >
          <stop stop-color="#F7F7F7" />
          <stop offset="1" stop-color="#DBDBDB" />
        </radialGradient>
        <linearGradient
          id="paint34_linear_1252_2194"
          x1="40.8163"
          y1="56.0492"
          x2="40.8163"
          y2="14.0101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7F7F7" />
          <stop offset="0.78" stop-color="#F2F2F2" />
          <stop offset="1" stop-color="#E6E6E6" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_1252_2194"
          x1="9.99811"
          y1="61.4987"
          x2="13.998"
          y2="61.4987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DBDBDB" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_1252_2194"
          x1="73.0098"
          y1="61.4987"
          x2="69.0099"
          y2="61.4987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6A6A6" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_1252_2194"
          x1="9.64105"
          y1="2.28037"
          x2="77.1896"
          y2="2.28037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ADADAD" />
          <stop offset="1" stop-color="#878787" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_1252_2194"
          x1="11.9602"
          y1="0.569987"
          x2="74.4188"
          y2="0.569987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_1252_2194"
          x1="31.6575"
          y1="144.836"
          x2="51.257"
          y2="144.836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_1252_2194"
          x1="12.4675"
          y1="3.25913"
          x2="69.9762"
          y2="3.25913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#363B39" />
          <stop offset="1" stop-color="#1B1C1C" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_1252_2194"
          x1="40.8092"
          y1="36.1806"
          x2="40.8092"
          y2="38.4406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCCCCC" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_1252_2194"
          x1="40.8105"
          y1="51.8705"
          x2="40.8105"
          y2="52.7105"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCCCCC" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_1252_2194"
          x1="40.8076"
          y1="32.2895"
          x2="40.8076"
          y2="32.8595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCCCCC" />
          <stop offset="0.3" stop-color="#CFCFCF" />
          <stop offset="0.56" stop-color="#DADADA" />
          <stop offset="0.81" stop-color="#ECECEC" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_1252_2194"
          x1="49.9088"
          y1="42.6493"
          x2="49.1088"
          y2="42.6493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCCCCC" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_1252_2194"
          x1="31.7198"
          y1="42.6493"
          x2="32.5098"
          y2="42.6493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CCCCCC" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_1252_2194"
          x1="63.1488"
          y1="159.618"
          x2="41.5091"
          y2="146.058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3B3B3B" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
