import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const PostPublicationAssignedIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="69"
      height="68"
      viewBox="0 0 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="34.3335" cy="34" r="34" fill="none" />
      <path
        d="M13.0266 20.9445V45.0574L13.6472 45.4161L13.8608 45.537L13.9656 45.5974L14.1792 45.7183L29.1556 54.3995L29.3692 54.5204L29.474 54.5768L29.6876 54.7018L30.7959 55.3466V31.2256L30.6428 31.1329L29.6876 30.5767L29.474 30.4558L29.3692 30.3994L29.1556 30.2785L14.1792 21.6135L13.9656 21.4885L13.8608 21.4241L13.6472 21.3031L13.0266 20.9445Z"
        fill="#50504F"
      />
      <path
        d="M29.4744 11.7794V54.5766L29.688 54.7015V11.7794H29.4744Z"
        fill="#393E3E"
      />
      <path
        d="M29.156 11.7794V54.3992L29.3696 54.5201V11.7794H29.156Z"
        fill="#737373"
      />
      <path
        d="M27.5359 12.993V53.4566L27.7495 53.5775V13.0091L27.5359 12.993Z"
        fill="#393E3E"
      />
      <path
        d="M27.2176 12.9729V53.2753L27.4312 53.4003V12.985L27.2176 12.9729Z"
        fill="#737373"
      />
      <path
        d="M25.5969 14.2099V52.3319L25.8105 52.4569V14.2381L25.5969 14.2099Z"
        fill="#393E3E"
      />
      <path
        d="M25.2786 14.1615V52.1546L25.4922 52.2755V14.1897L25.2786 14.1615Z"
        fill="#737373"
      />
      <path
        d="M23.6585 15.4234V51.2119L23.8721 51.3329V15.4677L23.6585 15.4234Z"
        fill="#393E3E"
      />
      <path
        d="M23.3401 15.351V51.0307L23.5537 51.1516V15.3993L23.3401 15.351Z"
        fill="#737373"
      />
      <path
        d="M21.72 16.6364V50.0874L21.9336 50.2083V16.6968L21.72 16.6364Z"
        fill="#393E3E"
      />
      <path
        d="M21.4016 16.5435V49.9058L21.6152 50.0308V16.6039L21.4016 16.5435Z"
        fill="#737373"
      />
      <path
        d="M19.7815 17.8491V48.9626L19.9951 49.0875V17.9257L19.7815 17.8491Z"
        fill="#393E3E"
      />
      <path
        d="M19.4632 17.7322V48.7852L19.6768 48.9061V17.8088L19.4632 17.7322Z"
        fill="#737373"
      />
      <path
        d="M17.843 19.0617V47.8416L18.0566 47.9625V19.1544L17.843 19.0617Z"
        fill="#393E3E"
      />
      <path
        d="M17.5247 18.9208V47.6604L17.7383 47.7854V19.0135L17.5247 18.9208Z"
        fill="#737373"
      />
      <path
        d="M15.9046 20.2756V46.718L16.1182 46.843V20.3844L15.9046 20.2756Z"
        fill="#393E3E"
      />
      <path
        d="M15.5857 20.1103V46.5406L15.7993 46.6616V20.2191L15.5857 20.1103Z"
        fill="#737373"
      />
      <path
        d="M13.9661 21.4882V45.5971L14.1797 45.718V21.6131L13.9661 21.4882Z"
        fill="#393E3E"
      />
      <path
        d="M13.6477 21.3028V45.4157L13.8613 45.5366V21.4237L13.6477 21.3028Z"
        fill="#737373"
      />
      <path
        d="M30.796 31.2258V55.3468L31.598 54.8793L31.8156 54.7503L31.9164 54.6939L32.134 54.5609L52.4263 42.6637V18.5507L51.8661 18.8772L51.6444 19.0061L51.5477 19.0666L51.326 19.1956L32.134 30.4399L31.9164 30.5689L31.8156 30.6294L31.598 30.7583L30.796 31.2258Z"
        fill="#737373"
      />
      <path
        d="M31.9166 11.1424V54.6932L32.1343 54.5602V11.1424H31.9166Z"
        fill="#939393"
      />
      <path
        d="M31.5983 11.1424V54.8786L31.8159 54.7496V11.1424H31.5983Z"
        fill="#5C5C5C"
      />
      <path
        d="M33.8867 11.9287V53.5329L34.1084 53.4039V11.9166L33.8867 11.9287Z"
        fill="#939393"
      />
      <path
        d="M33.5684 11.9487V53.7222L33.79 53.5932V11.9366L33.5684 11.9487Z"
        fill="#5C5C5C"
      />
      <path
        d="M35.862 12.7148V52.3764L36.0796 52.2475V12.6906L35.862 12.7148Z"
        fill="#939393"
      />
      <path
        d="M35.5436 12.7551V52.5658L35.7612 52.4328V12.7269L35.5436 12.7551Z"
        fill="#5C5C5C"
      />
      <path
        d="M37.8325 13.5041V51.2191L38.0542 51.0861V13.4638L37.8325 13.5041Z"
        fill="#939393"
      />
      <path
        d="M37.5142 13.5604V51.4043L37.7358 51.2754V13.5201L37.5142 13.5604Z"
        fill="#5C5C5C"
      />
      <path
        d="M39.8073 14.2903V50.0627L40.0249 49.9297V14.2379L39.8073 14.2903Z"
        fill="#939393"
      />
      <path
        d="M39.4889 14.3627V50.248L39.7065 50.119V14.3103L39.4889 14.3627Z"
        fill="#5C5C5C"
      />
      <path
        d="M41.7783 15.0765V48.9023L42 48.7734V15.012L41.7783 15.0765Z"
        fill="#939393"
      />
      <path
        d="M41.46 15.1694V49.0919L41.6816 48.959V15.1049L41.46 15.1694Z"
        fill="#5C5C5C"
      />
      <path
        d="M43.7529 15.8618V47.745L43.9746 47.612V15.7812L43.7529 15.8618Z"
        fill="#939393"
      />
      <path
        d="M43.4347 15.9747V47.9346L43.6523 47.8016V15.8941L43.4347 15.9747Z"
        fill="#5C5C5C"
      />
      <path
        d="M45.7236 16.648V46.5887L45.9453 46.4557V16.5553L45.7236 16.648Z"
        fill="#939393"
      />
      <path
        d="M45.4053 16.781V46.7741L45.627 46.6451V16.6883L45.4053 16.781Z"
        fill="#5C5C5C"
      />
      <path
        d="M47.6987 17.4342V45.4283L47.9204 45.2993V17.3294L47.6987 17.4342Z"
        fill="#939393"
      />
      <path
        d="M47.3804 17.5834V45.6177L47.6021 45.4847V17.4786L47.3804 17.5834Z"
        fill="#5C5C5C"
      />
      <path
        d="M49.6694 18.2194V44.2709L49.8911 44.1419V18.1025L49.6694 18.2194Z"
        fill="#939393"
      />
      <path
        d="M49.3511 18.3887V44.4603L49.5728 44.3273V18.2718L49.3511 18.3887Z"
        fill="#5C5C5C"
      />
      <path
        d="M51.6441 19.0064V43.1153L51.8657 42.9823V18.8774L51.6441 19.0064Z"
        fill="#939393"
      />
      <path
        d="M51.3257 19.1957V43.3006L51.5474 43.1716V19.0667L51.3257 19.1957Z"
        fill="#5C5C5C"
      />
      <path
        d="M30.6431 30.7622V31.5561L12.3337 20.9606V20.1707L30.6431 30.7622Z"
        fill="#BDCACF"
      />
      <path
        d="M30.6428 30.7623L53.1235 17.5955L34.8141 7L12.3335 20.1708L30.6428 30.7623Z"
        fill="#DFE1EB"
      />
      <path
        d="M14.0182 20.2514L34.9553 7.97525L51.439 17.5148L30.5059 29.7869L14.0182 20.2514Z"
        fill="#D3D3D9"
      />
      <path
        d="M34.9553 8.71279L50.8022 17.8856L51.439 17.5148L34.9553 7.97525L14.0182 20.2514L14.659 20.6181L34.9553 8.71279Z"
        fill="#9FA7AB"
      />
      <path
        d="M30.6428 30.7623V31.5562L53.1235 18.3854V17.5955L30.6428 30.7623Z"
        fill="#9FA7AB"
      />
      <path
        d="M34.9553 7.97525V8.71279L50.8022 17.8856L51.439 17.5148L34.9553 7.97525Z"
        fill="#9FA7AB"
      />
      <path
        d="M20.1924 31.8991V34.4221L16.9924 32.5802V30.0573L20.1924 31.8991Z"
        fill="#C7C7C7"
      />
      <path
        d="M17.4679 32.3061V30.8834L19.7168 32.1731V33.5958L17.4679 32.3061Z"
        fill="#484848"
      />
      <path
        d="M17.7984 32.1126L17.4679 32.306L19.7168 33.5957V33.2128L17.7984 32.1126Z"
        fill="#E6F5FF"
      />
      <path
        d="M17.4678 30.3351V32.8581L14.6587 31.2298V28.7109L17.4678 30.3351Z"
        fill="#C7C7C7"
      />
      <path
        d="M15.1342 30.9558V29.5331L16.9922 30.6092V32.0319L15.1342 30.9558Z"
        fill="#484848"
      />
      <path
        d="M15.4647 30.7662L15.1342 30.9556L16.9922 32.0317V31.6488L15.4647 30.7662Z"
        fill="#E6F5FF"
      />
      <path
        d="M24.771 34.5389V37.0618L21.571 35.22V32.7011L24.771 34.5389Z"
        fill="#C7C7C7"
      />
      <path
        d="M22.0465 34.9459V33.5233L24.2954 34.8129V36.2356L22.0465 34.9459Z"
        fill="#484848"
      />
      <path
        d="M22.377 34.7563L22.0465 34.9458L24.2954 36.2354V35.8566L22.377 34.7563Z"
        fill="#E6F5FF"
      />
      <path
        d="M22.0464 32.9749V35.4978L19.2413 33.8696V31.3507L22.0464 32.9749Z"
        fill="#C7C7C7"
      />
      <path
        d="M19.7169 33.5956V32.1729L21.5708 33.249V34.6717L19.7169 33.5956Z"
        fill="#484848"
      />
      <path
        d="M20.0474 33.406L19.7169 33.5955L21.5708 34.6715V34.2887L20.0474 33.406Z"
        fill="#E6F5FF"
      />
      <path
        d="M29.188 37.0896V39.6125L25.988 37.7707V35.2518L29.188 37.0896Z"
        fill="#C7C7C7"
      />
      <path
        d="M26.4635 37.4965V36.0739L28.7124 37.3635V38.7862L26.4635 37.4965Z"
        fill="#484848"
      />
      <path
        d="M26.794 37.3069L26.4635 37.4963L28.7124 38.786V38.4072L26.794 37.3069Z"
        fill="#E6F5FF"
      />
      <path
        d="M26.4639 35.5265V38.0494L23.6548 36.4212V33.9023L26.4639 35.5265Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.1303 36.1472V34.7245L25.9883 35.8006V37.2232L24.1303 36.1472Z"
        fill="#484848"
      />
      <path
        d="M24.4608 35.9576L24.1303 36.147L25.9883 37.2231V36.8402L24.4608 35.9576Z"
        fill="#E6F5FF"
      />
      <path
        d="M45.9497 16.6969L41.7502 15.9795V19.1432L45.9497 16.6969Z"
        fill="#9FA7AB"
      />
      <path
        d="M32.7426 10.7803L36.9461 11.4977L45.9497 16.6967L41.7502 15.9793L32.7426 10.7803Z"
        fill="#E7E3E3"
      />
      <path
        d="M33.8229 11.0665L33.4118 11.1672L41.0854 15.5965L41.5005 15.4997L33.8229 11.0665Z"
        fill="#E7E3E3"
      />
      <path
        d="M32.7429 13.9441L41.7505 19.1431V15.9793L32.7429 10.7803V13.9441Z"
        fill="#BDCACF"
      />
      <path
        d="M41.7505 19.1438L37.5469 18.4264V21.5942L41.7505 19.1438Z"
        fill="#9FA7AB"
      />
      <path
        d="M28.5434 13.227L32.7429 13.9444L41.7505 19.1434L37.5469 18.426L28.5434 13.227Z"
        fill="#E7E3E3"
      />
      <path
        d="M29.6193 13.5173L29.2122 13.614L36.8818 18.0433L37.2969 17.9465L29.6193 13.5173Z"
        fill="#E7E3E3"
      />
      <path
        d="M28.5433 16.3948L37.5469 21.5938V18.426L28.5433 13.227V16.3948Z"
        fill="#BDCACF"
      />
      <path
        d="M37.5464 21.5946L33.3428 20.8773V24.041L37.5464 21.5946Z"
        fill="#9FA7AB"
      />
      <path
        d="M24.3393 15.6781L28.5428 16.3955L37.5464 21.5945L33.3428 20.8771L24.3393 15.6781Z"
        fill="#E7E3E3"
      />
      <path
        d="M25.4197 15.9642L25.0086 16.065L32.6781 20.4902L33.0933 20.3975L25.4197 15.9642Z"
        fill="#E7E3E3"
      />
      <path
        d="M24.3392 18.8414L33.3428 24.0404V20.8767L24.3392 15.6777V18.8414Z"
        fill="#BDCACF"
      />
      <path
        d="M33.3433 24.0404L29.1438 23.3231V26.4868L33.3433 24.0404Z"
        fill="#9FA7AB"
      />
      <path
        d="M20.1362 18.1237L24.3397 18.841L33.3433 24.04L29.1438 23.3227L20.1362 18.1237Z"
        fill="#E7E3E3"
      />
      <path
        d="M21.2164 18.414L20.8053 18.5107L28.4789 22.9399L28.894 22.8432L21.2164 18.414Z"
        fill="#E7E3E3"
      />
      <path
        d="M20.136 21.2873L29.1436 26.4863V23.3226L20.136 18.1236V21.2873Z"
        fill="#BDCACF"
      />
      <path
        d="M20.1685 29.8191V32.338L16.9684 30.5003V27.9773L20.1685 29.8191Z"
        fill="#C7C7C7"
      />
      <path
        d="M17.4435 30.2259V28.8032L19.6924 30.0929V31.5156L17.4435 30.2259Z"
        fill="#484848"
      />
      <path
        d="M17.774 30.0323L17.4435 30.2258L19.6924 31.5155V31.1326L17.774 30.0323Z"
        fill="#E6F5FF"
      />
      <path
        d="M17.4438 28.256V30.7749L14.6348 29.1507V26.6277L17.4438 28.256Z"
        fill="#C7C7C7"
      />
      <path
        d="M15.1103 28.8765V27.4539L16.9683 28.5299V29.9526L15.1103 28.8765Z"
        fill="#484848"
      />
      <path
        d="M15.4408 28.683L15.1103 28.8765L16.9683 29.9525V29.5697L15.4408 28.683Z"
        fill="#E6F5FF"
      />
      <path
        d="M20.168 27.8689V30.3919L16.968 28.5501V26.0271L20.168 27.8689Z"
        fill="#C7C7C7"
      />
      <path
        d="M17.4435 28.2759V26.8532L19.6924 28.1429V29.5656L17.4435 28.2759Z"
        fill="#484848"
      />
      <path
        d="M17.774 28.0824L17.4435 28.2758L19.6924 29.5655V29.1827L17.774 28.0824Z"
        fill="#E6F5FF"
      />
      <path
        d="M17.4438 26.3049V28.8278L14.6348 27.1996V24.6807L17.4438 26.3049Z"
        fill="#C7C7C7"
      />
      <path
        d="M15.1103 26.9256V25.5029L16.9683 26.579V28.0017L15.1103 26.9256Z"
        fill="#484848"
      />
      <path
        d="M15.4408 26.736L15.1103 26.9254L16.9683 28.0015V27.6186L15.4408 26.736Z"
        fill="#E6F5FF"
      />
      <path
        d="M24.751 32.4589V34.9819L21.5469 33.1401V30.6171L24.751 32.4589Z"
        fill="#C7C7C7"
      />
      <path
        d="M22.0226 32.8658V31.4431L24.2715 32.7328V34.1554L22.0226 32.8658Z"
        fill="#484848"
      />
      <path
        d="M22.3531 32.6722L22.0226 32.8657L24.2715 34.1554V33.7725L22.3531 32.6722Z"
        fill="#E6F5FF"
      />
      <path
        d="M22.0225 30.8948V33.4137L19.2174 31.7895V29.2706L22.0225 30.8948Z"
        fill="#C7C7C7"
      />
      <path
        d="M19.693 31.5154V30.0928L21.5469 31.1688V32.5915L19.693 31.5154Z"
        fill="#484848"
      />
      <path
        d="M20.0234 31.3259L19.693 31.5153L21.5469 32.5914V32.2085L20.0234 31.3259Z"
        fill="#E6F5FF"
      />
      <path
        d="M24.751 30.5087V33.0317L21.5469 31.1899V28.671L24.751 30.5087Z"
        fill="#C7C7C7"
      />
      <path
        d="M22.0226 30.9158V29.4931L24.2715 30.7828V32.2055L22.0226 30.9158Z"
        fill="#484848"
      />
      <path
        d="M22.3531 30.7262L22.0226 30.9156L24.2715 32.2053V31.8265L22.3531 30.7262Z"
        fill="#E6F5FF"
      />
      <path
        d="M22.0225 28.9447V31.4676L19.2174 29.8394V27.3205L22.0225 28.9447Z"
        fill="#C7C7C7"
      />
      <path
        d="M19.693 29.5655V28.1428L21.5469 29.2189V30.6416L19.693 29.5655Z"
        fill="#484848"
      />
      <path
        d="M20.0234 29.3759L19.693 29.5653L21.5469 30.6414V30.2626L20.0234 29.3759Z"
        fill="#E6F5FF"
      />
      <path
        d="M29.1636 35.0026V37.5255L25.9636 35.6837V33.1608L29.1636 35.0026Z"
        fill="#C7C7C7"
      />
      <path
        d="M26.4391 35.4098V33.9871L28.688 35.2768V36.6994L26.4391 35.4098Z"
        fill="#484848"
      />
      <path
        d="M26.7696 35.2162L26.4391 35.4097L28.688 36.6994V36.3165L26.7696 35.2162Z"
        fill="#E6F5FF"
      />
      <path
        d="M26.4395 33.439V35.9579L23.6304 34.3337V31.8148L26.4395 33.439Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.11 34.0595V32.6368L25.9639 33.7129V35.1355L24.11 34.0595Z"
        fill="#484848"
      />
      <path
        d="M24.4364 33.8699L24.11 34.0593L25.9639 35.1354V34.7525L24.4364 33.8699Z"
        fill="#E6F5FF"
      />
      <path
        d="M29.1636 33.0519V35.5749L25.9636 33.733V31.2141L29.1636 33.0519Z"
        fill="#C7C7C7"
      />
      <path
        d="M26.4391 33.4588V32.0362L28.688 33.3258V34.7485L26.4391 33.4588Z"
        fill="#484848"
      />
      <path
        d="M26.7696 33.2692L26.4391 33.4586L28.688 34.7483V34.3695L26.7696 33.2692Z"
        fill="#E6F5FF"
      />
      <path
        d="M26.439 31.4888V34.0117L23.6299 32.3835V29.8646L26.439 31.4888Z"
        fill="#C7C7C7"
      />
      <path
        d="M24.1095 32.1095V30.6868L25.9634 31.7629V33.1855L24.1095 32.1095Z"
        fill="#484848"
      />
      <path
        d="M24.4359 31.9199L24.1095 32.1093L25.9634 33.1854V32.8025L24.4359 31.9199Z"
        fill="#E6F5FF"
      />
      <path
        d="M47.4731 28.129V41.1284L36.3692 47.5363V34.5369L47.4731 28.129Z"
        fill="#3D3D39"
      />
      <path
        d="M47.4731 39.9408V45.4544L36.3692 51.9965V46.3487V37.6775L47.4731 39.9408Z"
        fill="#909090"
      />
      <path
        d="M36.7759 34.7354L36.3692 34.4731V32.3087H36.7759V34.7354Z"
        fill="#474747"
      />
      <path
        d="M47.2369 26L47.7812 26.8395L36.7756 33.2343L36.369 32.3095L47.2369 26Z"
        fill="#909090"
      />
      <path
        d="M47.7812 26.8401V28.3355L36.7756 34.7368V33.2349L47.7812 26.8401Z"
        fill="#636363"
      />
      <mask
        id="mask0_751_57819"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="36"
        y="34"
        width="20"
        height="27"
      >
        <path
          d="M55.6753 38.8012V53.592L49.9865 60.9874L36.3337 51.4136V45.7658V35.3879L36.7886 35.7293L39.1779 34.2502L55.6753 38.8012Z"
          fill="#909090"
        />
      </mask>
      <g mask="url(#mask0_751_57819)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.8034 44.9008C36.5176 44.1591 35.4692 44.605 35.4692 45.8933C35.4692 47.1816 36.5176 48.8371 37.8034 49.5788C39.0885 50.3205 40.1368 49.8746 40.1368 48.5867C40.1368 47.298 39.0885 45.6425 37.8034 44.9008Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4841 45.8903C35.1983 45.1485 34.1499 45.5944 34.1499 46.8828C34.1499 48.1711 35.1983 49.8266 36.4841 50.5683C37.7691 51.31 38.8175 50.8641 38.8175 49.5762C38.8175 48.2875 37.7691 46.632 36.4841 45.8903Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.4845 46.6767C35.6308 46.1843 34.9346 46.4801 34.9346 47.3357C34.9346 48.1909 35.6308 49.2903 36.4845 49.7831C37.3378 50.2755 38.034 49.9793 38.034 49.1241C38.034 48.2685 37.3378 47.1691 36.4845 46.6767Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.549 46.7905C35.7954 46.3556 35.1807 46.617 35.1807 47.3722C35.1807 48.1275 35.7954 49.098 36.549 49.5326C37.3022 49.9675 37.917 49.7061 37.917 48.9508C37.917 48.1959 37.3022 47.225 36.549 46.7905Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5492 46.9878C35.9044 46.6158 35.3784 46.8395 35.3784 47.4857C35.3784 48.1319 35.9044 48.9625 36.5492 49.3345C37.1939 49.7065 37.7195 49.4828 37.7195 48.8366C37.7195 48.1904 37.1939 47.3601 36.5492 46.9878Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5491 47.2259C36.0345 46.9289 35.6147 47.1072 35.6147 47.6231C35.6147 48.1385 36.0345 48.8016 36.5491 49.0985C37.0635 49.395 37.4832 49.2167 37.4832 48.7012C37.4832 48.1854 37.0635 47.5228 36.5491 47.2259Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.549 47.3442C36.0993 47.0846 35.7329 47.2406 35.7329 47.6909C35.7329 48.1412 36.0993 48.7204 36.549 48.9796C36.9983 49.2388 37.3646 49.0828 37.3646 48.6325C37.3646 48.1822 36.9983 47.6034 36.549 47.3442Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.0338 50.8461C38.3431 50.8352 38.8903 50.4564 39.6755 49.7097L39.1662 48.1681L38.3468 49.7566C37.8289 50.4939 37.7245 50.857 38.0338 50.8461Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.6501 44.9008C50.3643 44.1591 49.3159 44.605 49.3159 45.8933C49.3159 47.1816 50.3643 48.8371 51.6501 49.5788C52.9351 50.3205 53.9835 49.8746 53.9835 48.5867C53.9835 47.298 52.9351 45.6425 51.6501 44.9008Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3312 45.8903C49.0454 45.1485 47.9971 45.5944 47.9971 46.8828C47.9971 48.1711 49.0454 49.8266 50.3312 50.5683C51.6163 51.31 52.6647 50.8641 52.6647 49.5762C52.6647 48.2875 51.6163 46.632 50.3312 45.8903Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3311 46.6767C49.4775 46.1843 48.7812 46.4801 48.7812 47.3357C48.7812 48.1909 49.4775 49.2903 50.3311 49.7831C51.1845 50.2755 51.8807 49.9793 51.8807 49.1241C51.8807 48.2685 51.1845 47.1691 50.3311 46.6767Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3962 46.7897C49.6426 46.3548 49.0278 46.6162 49.0278 47.3715C49.0278 48.1268 49.6426 49.0973 50.3962 49.5319C51.1494 49.9668 51.7642 49.7054 51.7642 48.9501C51.7642 48.1952 51.1494 47.2243 50.3962 46.7897Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3958 46.9878C49.7511 46.6158 49.2251 46.8395 49.2251 47.4857C49.2251 48.1319 49.7511 48.9625 50.3958 49.3345C51.0406 49.7065 51.5662 49.4828 51.5662 48.8366C51.5662 48.1904 51.0406 47.3601 50.3958 46.9878Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3958 47.2259C49.8811 46.9289 49.4614 47.1072 49.4614 47.6231C49.4614 48.1385 49.8811 48.8016 50.3958 49.0985C50.9101 49.395 51.3299 49.2167 51.3299 48.7012C51.3299 48.1854 50.9101 47.5228 50.3958 47.2259Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.3961 47.3449C49.9465 47.0854 49.5801 47.2413 49.5801 47.6916C49.5801 48.1419 49.9465 48.7211 50.3961 48.9803C50.8454 49.2395 51.2118 49.0836 51.2118 48.6332C51.2118 48.1829 50.8454 47.6041 50.3961 47.3449Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.881 50.8461C52.1903 50.8352 52.7375 50.4564 53.5227 49.7097L53.0133 48.1681L52.194 49.7566C51.676 50.4939 51.5717 50.857 51.881 50.8461Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.1105 44.6508V44.6866L49.9947 45.5806L48.3022 45.8925C49.5077 44.9584 50.1105 44.5308 50.1105 44.6095C50.1105 44.6882 50.1105 44.702 50.1105 44.6508Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.9653 45.9978L52.2368 46.9951V48.5466L53.9653 47.5527V45.9978Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5845 44.8156L52.1544 46.9438L52.2366 46.9921L53.9651 45.9981L53.88 45.9476L53.8628 45.9377L50.3126 43.8184L48.5845 44.8156Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.736 46.8328V47.1765C53.736 47.2417 53.701 47.3018 53.6447 47.3343L52.672 47.9102C52.6223 47.9388 52.5605 47.9025 52.5605 47.845V47.804L53.4332 47.2864C53.4891 47.2542 53.5245 47.1938 53.5245 47.129V46.8262L53.6246 46.7687C53.6743 46.7402 53.736 46.7764 53.736 46.8328Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.5245 46.8265V47.1293C53.5245 47.1941 53.4891 47.2545 53.4332 47.2867L52.5605 47.8044V47.5024C52.5605 47.4365 52.5956 47.3764 52.6519 47.3438L53.5245 46.8265Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.6888 53.3211L43.0776 49.9943V45.9108L48.6888 49.2376V53.3211Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.8207 46.8516L48.689 49.2371V53.3206L52.8207 50.9501V46.8516Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.0781 45.9092L48.6882 49.2374L52.8206 46.8655L47.2084 43.524L43.0781 45.9092Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.3763 51.9121L45.9139 51.6459L45.5986 49.9018L46.3763 50.3606V51.9121Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.668 45.1047L43.1514 45.961V49.8267L41.668 48.9758V45.1047Z"
          fill="#502D1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.6602 49.712L41.8751 48.9948V46.5697L40.6602 47.2869V49.712Z"
          fill="#8D4528"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.1768 46.4311L40.6602 47.2874V49.7125L39.1768 48.8616V46.4311Z"
          fill="#6D3822"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.8751 46.5696L40.7884 47.2114L40.6602 47.2876L39.1768 46.436L39.3097 46.3569L39.3364 46.3415L40.3924 45.7137L41.8751 46.5696Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.4481 47.6727L36.4836 45.3326L31.6023 39.6705L31.3335 38.1976L42.9999 45.0902L40.4481 47.6727Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.1034 45.2068L40.3577 46.9728L36.8445 44.8985L32.5809 39.9547L32.5049 39.5366L42.1034 45.2068Z"
          fill="#874226"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.1761 40.8932L43.0001 45.0902L40.4482 47.6727L47.6242 43.4907L50.1761 40.8932Z"
          fill="#CA722E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3345 38.1968L42.9998 45.0905L50.1762 40.9074L38.509 34.0002L31.3345 38.1968Z"
          fill="#ECC530"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0764 40.9035L45.6018 42.9295L43.0003 44.4451L41.0621 43.2992L33.8189 39.0184L32.4326 38.1994L38.5069 34.6468L49.0764 40.9035Z"
          fill="#E2832D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.7754 36.1193L33.8189 39.0184L32.4326 38.1994L38.5069 34.6468L38.7754 36.1193Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.6018 42.9294L43.0002 44.445L41.062 43.2991L43.6581 41.7816L45.6018 42.9294Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.6573 41.7806L45.6017 42.9287L49.0767 40.9035L38.5068 34.6461L38.7757 36.1185L43.6573 41.7806Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.0167 45.102L42.4312 44.7527V43.7628L43.0167 44.112V45.102Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.2487 47.8481L44.6567 45.143L46.2815 43.6588L48.3034 44.8523L49.2487 47.8481Z"
          fill="#212220"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.7716 43.428L48.3037 44.8529L49.2491 47.8487L51.7169 46.4326L50.7716 43.428Z"
          fill="#434341"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2822 43.6585L48.3033 44.8527L50.7716 43.4359L48.749 42.2336L46.2822 43.6585Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0239 50.0579L52.485 48.0568L52.5675 48.1026L49.0239 52.1692V50.0579Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0239 50.0579L49.1065 50.1036V52.2149L49.0239 52.1692V50.0579Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1064 50.1037L52.5675 48.1025V50.1791L49.1064 52.215V50.1037Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 48.2987V50.1138L51.9695 50.3994L51.896 50.4426L51.0095 50.9643L50.9361 51.0078L50.0473 51.5295L49.9757 51.5731L49.906 51.613L49.2192 52.0175V50.433L49.8326 50.0798L49.9757 49.9985V49.9227L49.8326 50.004L49.2192 50.3576V50.1684L49.8326 49.8151L50.0473 49.6902L50.7943 49.2575L50.9361 49.1759L51.0095 49.1345L51.7547 48.7032L51.896 48.6219L51.9695 48.5787L52.4542 48.2987Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 49.0869V49.1623L51.9695 49.4401L51.896 49.4837L51.7547 49.565L51.0095 49.9922L50.9361 50.0358L50.7943 50.1171L50.0473 50.5461L49.9757 50.5879L49.8326 50.6691L49.2192 51.0228V50.947L49.8326 50.5934L49.9757 50.5121L50.0473 50.4707L50.7943 50.0416L50.9361 49.96L51.0095 49.9168L51.7547 49.4896L51.896 49.4083L51.9695 49.3647L52.4542 49.0869Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.8114V50.8854L49.8326 50.9666L49.2192 51.3199V51.2463L49.8326 50.8927L49.9757 50.8114Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 49.384V49.4594L51.9693 49.7373L51.8959 49.7808L51.7545 49.8621L51.0093 50.2893L50.9359 50.3329L50.7942 50.4142L50.0472 50.8433L49.9756 50.885V50.811L50.0472 50.7697L50.7942 50.3384L50.9359 50.2571L51.0093 50.2158L51.7545 49.7863L51.8959 49.705L51.9693 49.6637L52.454 49.384Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 51.1085V51.1839L49.8326 51.2656L49.2192 51.6189V51.5434L49.8326 51.1898L49.9757 51.1085Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 51.4049V51.4803L49.8326 51.5616L49.2192 51.9153V51.8395L49.8326 51.4862L49.9757 51.4049Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 49.9797V50.0533L51.9693 50.3334L51.8959 50.3747L51.7545 50.456L51.0093 50.8854L50.9359 50.9268L50.7942 51.0081L50.0472 51.4394L49.9756 51.4807V51.4053L50.0472 51.3636L50.7942 50.9345L50.9359 50.8532L51.0093 50.8097L51.7545 50.3824L51.8959 50.3011L51.9693 50.2576L52.454 49.9797Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.3764 49.0573L52.4542 49.0874L51.9695 49.3652L51.896 49.4088L51.7547 49.4901L51.0095 49.9173L50.9361 49.9605L50.7943 50.0422L50.0473 50.4712L49.9757 50.5126L49.8326 50.5939L49.2192 50.9475V50.8739L49.8326 50.5203L49.9757 50.439L50.0473 50.3973L50.7943 49.9664L50.9361 49.8851L51.0095 49.8434L51.7547 49.4143L51.896 49.333L51.9695 49.2913L52.3764 49.0573Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.3764 49.356L52.4542 49.3841L51.9695 49.6638L51.896 49.7052L51.7547 49.7865L51.0095 50.2159L50.9361 50.2573L50.7943 50.3386L50.0473 50.7698L49.9757 50.8112L49.8326 50.8925L49.2192 51.2461V51.1704L49.8326 50.8171L49.9757 50.7358L50.0473 50.6941L50.7943 50.265L50.9361 50.1837L51.0095 50.1401L51.7547 49.7129L51.896 49.6316L51.9695 49.5881L52.3764 49.356Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.9229V49.9987L49.8326 50.08L49.2192 50.4333V50.3579L49.8326 50.0042L49.9757 49.9229Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 48.4955V48.5709L51.9693 48.8506L51.8959 48.8924L51.7545 48.9736L51.0093 49.4027L50.9359 49.4444L50.7942 49.5257L50.0472 49.9566L49.9756 49.9984V49.9226L50.0472 49.8812L50.7942 49.4499L50.9359 49.3687L51.0093 49.3273L51.7545 48.8979L51.8959 48.8166L51.9693 48.7752L52.454 48.4955Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 48.7941V48.8681L51.9695 49.1478L51.896 49.1895L51.7547 49.2708L51.0095 49.6999L50.9361 49.7412L50.7943 49.8229L50.0473 50.2538L49.9757 50.2951L49.8326 50.3768L49.2192 50.7301V50.6547L49.8326 50.301L49.9757 50.2197L50.0473 50.178L50.7943 49.7489L50.9361 49.6676L51.0095 49.6241L51.7547 49.1968L51.896 49.1156L51.9695 49.072L52.4542 48.7941Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.3764 48.4675L52.4542 48.4957L51.9695 48.7754L51.896 48.8168L51.7547 48.898L51.0095 49.3275L50.9361 49.3688L50.7943 49.4501L50.0473 49.8814L49.9757 49.9228L49.8326 50.004L49.2192 50.3577V50.2819L49.8326 49.9286L49.9757 49.8473L50.0473 49.8056L50.7943 49.3765L50.9361 49.2953L51.0095 49.2517L51.7547 48.8244L51.896 48.7432L51.9695 48.6996L52.3764 48.4675Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.3764 48.7639L52.4542 48.7939L51.9695 49.0718L51.896 49.1154L51.7547 49.1966L51.0095 49.6239L50.9361 49.6675L50.7943 49.7487L50.0473 50.1778L49.9757 50.2195L49.8326 50.3008L49.2192 50.6545V50.5805L49.8326 50.2269L49.9757 50.1459L50.0473 50.1042L50.7943 49.6729L50.9361 49.5917L51.0095 49.5503L51.7547 49.1212L51.896 49.04L51.9695 48.9982L52.3764 48.7639Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9694 48.5788V50.3994L51.896 50.4427V48.622L51.9694 48.5788Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.6216V48.8164L51.7549 48.8241V48.7029L51.8962 48.6216Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.8922V49.1151L51.7549 49.1228V48.9735L51.8962 48.8922Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.1893V49.4086L51.7549 49.4159V49.2706L51.8962 49.1893Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.4843V49.7054L51.7549 49.7131V49.5655L51.8962 49.4843Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 49.6826V49.7565L51.9693 50.0362L51.8959 50.078L51.7545 50.1593L51.0093 50.5883L50.9359 50.6301L50.7942 50.7113L50.0472 51.1422L49.9756 51.1836V51.1082L50.0472 51.0665L50.7942 50.6374L50.9359 50.5561L51.0093 50.5125L51.7545 50.0853L51.8959 50.004L51.9693 49.9605L52.454 49.6826Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.7814V50.0043L51.7549 50.012V49.8626L51.8962 49.7814Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 50.0778V50.3007L51.7549 50.3084V50.159L51.8962 50.0778Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 50.3742V50.4423L51.7549 50.5254V50.4555L51.8962 50.3742Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.0095 49.1348V50.9645L50.936 51.0081V49.1761L51.0095 49.1348Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.1761V49.369L50.7944 49.3767V49.2577L50.9362 49.1761Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.4444V49.6674L50.7944 49.6751V49.5257L50.9362 49.4444Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.7408V49.9601L50.7944 49.9678V49.8225L50.9362 49.7408Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.0357V50.2569L50.7944 50.2645V50.117L50.9362 50.0357Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.3329V50.5558L50.7944 50.5635V50.4142L50.9362 50.3329Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.63V50.853L50.7944 50.8603V50.7113L50.9362 50.63Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.9264V51.0077L50.7944 51.0908V51.0077L50.9362 50.9264Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0472 49.6899V51.5292L49.9756 51.5728V49.7317L50.0472 49.6899Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.732V49.9227L49.8325 49.9286V49.8151L49.9757 49.732Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.9981V50.2193L49.8325 50.2266V50.0794L49.9757 49.9981Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.2945V50.512L49.8325 50.5197V50.3762L49.9757 50.2945Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.588V50.8109L49.8325 50.8186V50.6693L49.9757 50.588Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.8851V51.1081L49.8325 51.1154V50.9664L49.9757 50.8851Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 51.1837V51.4052L49.8325 51.4125V51.2654L49.9757 51.1837Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8325 51.5614L49.9757 51.4801V51.5727L49.9059 51.6126H49.8325V51.5614Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0239 49.5345L52.485 47.5334L52.5675 47.5791L49.0239 51.522V49.5345Z"
          fill="#20211F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0239 49.5344L49.1065 49.5802V51.5677L49.0239 51.522V49.5344Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1064 49.5802L52.5675 47.5791V49.5319L49.1064 51.5677V49.5802Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 47.7752V49.5899L51.9695 49.8755L51.896 49.9187L51.0095 50.4408L50.9361 50.484L50.7943 50.5674L50.0473 51.006L49.9757 51.0492L49.906 51.0891L49.2192 51.4937V49.9095L49.8326 49.5559L49.9757 49.4746V49.3988L49.8326 49.4801L49.2192 49.8338V49.6449L49.8326 49.2912L49.9757 49.2081L50.0473 49.1664L50.7943 48.7336L50.9361 48.6524L51.0095 48.6106L51.7547 48.1797L51.896 48.0984L51.9695 48.0549L52.4542 47.7752Z"
          fill="#0A0B09"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 48.5641V48.6399L51.9695 48.9174L51.896 48.9609L51.7547 49.0422L51.0095 49.4695L50.9361 49.513L50.7943 49.5943L50.0473 50.0234L49.9757 50.0651L49.8326 50.1464L49.2192 50.5V50.4242L49.8326 50.071L49.9757 49.9893L50.0473 49.948L50.7943 49.5189L50.9361 49.4376L51.0095 49.394L51.7547 48.9668L51.896 48.8855L51.9695 48.842L52.4542 48.5641Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.2879V50.3619L49.8326 50.4431L49.2192 50.7968V50.7228L49.8326 50.3692L49.9757 50.2879Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 48.8605V48.9359L51.9693 49.2138L51.8959 49.2573L51.7545 49.3386L51.0093 49.7658L50.9359 49.8094L50.7942 49.8907L50.0472 50.3198L49.9756 50.3615V50.2875L50.0472 50.2462L50.7942 49.8149L50.9359 49.7336L51.0093 49.6923L51.7545 49.2628L51.8959 49.1819L51.9693 49.1402L52.454 48.8605Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.5843V50.6601L49.8326 50.7413L49.2192 51.0946V51.0192L49.8326 50.6656L49.9757 50.5843Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.8814V50.9569L49.8326 51.0381L49.2192 51.3918V51.316L49.8326 50.9627L49.9757 50.8814Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 49.4562V49.5302L51.9693 49.8099L51.8959 49.8516L51.7545 49.9329L51.0093 50.362L50.9359 50.4033L50.7942 50.4846L50.0472 50.9159L49.9756 50.9573V50.8818L50.0472 50.8401L50.7942 50.411L50.9359 50.3298L51.0093 50.2862L51.7545 49.8589L51.8959 49.7777L51.9693 49.7341L52.454 49.4562Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.3987V49.4745L49.8326 49.5558L49.2192 49.9094V49.8336L49.8326 49.48L49.9757 49.3987Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 47.972V48.0475L51.9693 48.3272L51.8959 48.3689L51.7545 48.4502L51.0093 48.8792L50.9359 48.921L50.7942 49.0023L50.0472 49.4332L49.9756 49.4749V49.3991L50.0472 49.3577L50.7942 48.9265L50.9359 48.8452L51.0093 48.8038L51.7545 48.3748L51.8959 48.2935L51.9693 48.2517L52.454 47.972Z"
          fill="#2D2E2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4542 48.2707V48.3446L51.9695 48.6243L51.896 48.666L51.7547 48.7473L51.0095 49.1764L50.9361 49.2181L50.7943 49.2994L50.0473 49.7303L49.9757 49.772L49.8326 49.8533L49.2192 50.2066V50.1312L49.8326 49.7775L49.9757 49.6963L50.0473 49.6545L50.7943 49.2255L50.9361 49.1442L51.0095 49.1006L51.7547 48.6734L51.896 48.5921L51.9695 48.5489L52.4542 48.2707Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9694 48.0554V49.876L51.896 49.9192V48.0989L51.9694 48.0554Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.0988V48.2936L51.7549 48.3009V48.1801L51.8962 48.0988Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.3687V48.5917L51.7549 48.5994V48.45L51.8962 48.3687Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.6658V48.8851L51.7549 48.8924V48.7471L51.8962 48.6658Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 48.9608V49.1822L51.7549 49.1896V49.042L51.8962 48.9608Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.454 49.1591V49.233L51.9693 49.5127L51.8959 49.5545L51.7545 49.6358L51.0093 50.0648L50.9359 50.1066L50.7942 50.1878L50.0472 50.6187L49.9756 50.6605V50.5847L50.0472 50.543L50.7942 50.1139L50.9359 50.0326L51.0093 49.989L51.7545 49.5618L51.8959 49.4805L51.9693 49.4373L52.454 49.1591Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.2571V49.4801L51.7549 49.4878V49.3384L51.8962 49.2571Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.5543V49.7772L51.7549 49.7849V49.6356L51.8962 49.5543Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.8962 49.8514V49.9191L51.7549 50.0026V49.9327L51.8962 49.8514Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.0095 48.6113V50.4414L50.936 50.4846V48.653L51.0095 48.6113Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 48.6526V48.8451L50.7944 48.8528V48.7338L50.9362 48.6526Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 48.921V49.1439L50.7944 49.1516V49.0022L50.9362 48.921Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.2181V49.4374L50.7944 49.4447V49.2994L50.9362 49.2181Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.5123V49.7334L50.7944 49.7411V49.5935L50.9362 49.5123Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 49.8094V50.0323L50.7944 50.04V49.8907L50.9362 49.8094Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.1065V50.3295L50.7944 50.3372V50.1878L50.9362 50.1065Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9362 50.4029V50.4842L50.7944 50.5677V50.4842L50.9362 50.4029Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0472 49.1665V51.0061L49.9756 51.0493V49.2082L50.0472 49.1665Z"
          fill="#333431"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.2085V49.3992L49.8325 49.4051V49.2916L49.9757 49.2085Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.4747V49.6958L49.8325 49.7035V49.5559L49.9757 49.4747Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 49.7718V49.9889L49.8325 49.9966V49.8531L49.9757 49.7718Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.0645V50.2875L49.8325 50.2952V50.1458L49.9757 50.0645Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.3616V50.5846L49.8325 50.5923V50.4429L49.9757 50.3616Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9757 50.6602V50.8814L49.8325 50.8887V50.7415L49.9757 50.6602Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8325 51.0379L49.9757 50.9566V51.0493L49.9059 51.0892H49.8325V51.0379Z"
          fill="#0B0C0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.356 53.8187L48.104 53.6701V53.1671L48.356 53.3157V53.8187Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.4878 50.3668L48.356 53.3157V53.8188L53.4878 50.8709V50.3668Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.104 53.1668L48.356 53.3155L53.4879 50.3676L53.4602 50.3511L53.4547 50.3482L53.2359 50.2179L48.104 53.1668Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2888 45.4679L42.4312 43.763V43.313L45.2888 45.0176V45.4679Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.5033 46.4808L33.8711 41.954V41.5037L41.5033 46.0305V46.4808Z"
          fill="#A95C2E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4479 40.8195L45.2891 45.0176V45.4679L52.4479 41.2698V40.8195Z"
          fill="#EEA32D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4312 43.3126L45.2654 45.0039L45.2888 45.0175L52.4476 40.8194L49.59 39.1134L42.4312 43.3126Z"
          fill="#F4D251"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.9957 41.5951L41.3573 45.9612L41.4841 45.8327L34.1459 41.4801L33.9556 41.5482L33.9957 41.5951Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.3859 49.186C44.1001 48.4443 43.0518 48.8902 43.0518 50.1785C43.0518 51.4668 44.1001 53.1223 45.3859 53.864C46.671 54.6058 47.7194 54.1599 47.7194 52.8719C47.7194 51.5832 46.671 49.9277 45.3859 49.186Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.0676 50.1751C42.7818 49.4334 41.7334 49.8793 41.7334 51.1676C41.7334 52.4559 42.7818 54.1115 44.0676 54.8532C45.3526 55.5949 46.401 55.149 46.401 53.861C46.401 52.5724 45.3526 50.9169 44.0676 50.1751Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.0675 50.9607C43.2138 50.4683 42.5176 50.7641 42.5176 51.6197C42.5176 52.4749 43.2138 53.5743 44.0675 54.0671C44.9208 54.5595 45.617 54.2633 45.617 53.4081C45.617 52.5525 44.9208 51.4531 44.0675 50.9607Z"
          fill="#353633"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.132 51.0745C43.3784 50.6396 42.7637 50.9009 42.7637 51.6562C42.7637 52.4115 43.3784 53.382 44.132 53.8166C44.8852 54.2515 45.5 53.9901 45.5 53.2348C45.5 52.4799 44.8852 51.509 44.132 51.0745Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.1322 51.2726C43.4874 50.9006 42.9614 51.1243 42.9614 51.7705C42.9614 52.4166 43.4874 53.2473 44.1322 53.6193C44.7769 53.9912 45.3025 53.7675 45.3025 53.1214C45.3025 52.4752 44.7769 51.6449 44.1322 51.2726Z"
          fill="#8E4428"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.1321 51.5106C43.6175 51.2137 43.1978 51.392 43.1978 51.9078C43.1978 52.4233 43.6175 53.0863 44.1321 53.3832C44.6465 53.6797 45.0662 53.5014 45.0662 52.986C45.0662 52.4701 44.6465 51.8075 44.1321 51.5106Z"
          fill="#A34D2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.132 51.6297C43.6823 51.3701 43.3159 51.5261 43.3159 51.9764C43.3159 52.4267 43.6823 53.0058 44.132 53.265C44.5813 53.5242 44.9476 53.3683 44.9476 52.918C44.9476 52.4677 44.5813 51.8889 44.132 51.6297Z"
          fill="#E59138"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.6168 55.1313C45.9261 55.1204 46.4733 54.7416 47.2585 53.995L46.7492 52.4533L45.9299 54.0418C45.4119 54.7791 45.3075 55.1422 45.6168 55.1313Z"
          fill="#121312"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8463 48.9352V48.971L43.7305 49.865L42.0381 50.177C43.2436 49.2429 43.8463 48.8152 43.8463 48.8939C43.8463 48.9727 43.8463 48.9864 43.8463 48.9352Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.7241 48.456L46.2944 50.5838L46.3762 50.6325L48.1047 49.6382L48.0196 49.5877L48.0028 49.5778L42.7241 48.1844V48.456Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1958 48.768L42.7238 48.4554L44.4519 47.4581L41.1958 48.4968V48.768Z"
          fill="#D16F2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.7241 48.1841L46.2944 50.3122L46.3762 50.3606L48.1047 49.3666L48.0196 49.3161L48.0028 49.3062L44.4523 47.1868L42.7241 48.1841Z"
          fill="#E9B237"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1958 48.4971L42.7238 48.1841L44.4519 47.1868L42.9236 47.4998L41.1958 48.4971Z"
          fill="#A44C2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.1046 49.3633L46.3765 50.3606V51.9121L48.1046 50.9182V49.3633Z"
          fill="#EB922D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8098 50.1892V50.5334C47.8098 50.5982 47.7747 50.6586 47.7184 50.6908L46.7457 51.2667C46.696 51.2952 46.6343 51.259 46.6343 51.2019V51.1609L47.5069 50.6432C47.5628 50.6106 47.5983 50.5506 47.5983 50.4854V50.1826L47.6983 50.1255C47.748 50.0966 47.8098 50.1332 47.8098 50.1892Z"
          fill="#121311"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.5983 50.1825V50.4852C47.5983 50.5504 47.5628 50.6104 47.5069 50.643L46.6343 51.1607V50.8587C46.6343 50.7928 46.6693 50.7327 46.7256 50.7001L47.5983 50.1825Z"
          fill="#FFF3CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.3763 51.9118L45.9139 51.6457L45.5986 49.9016L46.3763 50.3603V51.9118Z"
          fill="#D16F2D"
        />
      </g>
    </SvgIcon>
  );
};
