import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
  SourceInfoScreen,
  TrucksDashboardScreen,
  TruckScreen,
  OperatorsScreen,
  TruckAssignmentScreen,
  TruckAssignmentMetricsScreen,
  TruckAssignmentDailyPlanScreen,
  UnplannedParkedTrucksScreen,
  AdministrationScreen,
  PerformanceRatingSupervisorScreen,
  BusesMapScreen,
  PerformanceRatingOperatorScreen,
  TruckAssigmentPerformanceScreen,
} from "screens";
import {
  UserProvider,
  ConfirmationDialogProvider,
  FeedbackProvider,
  InfoSourceProvider,
  OperatorAssignmentProvider,
  AlertsProvider,
  TrucksDashboardProvider,
  OperatorsProvider,
  ExcavatorProvider,
  TruckAssignmentKpisProvider,
  OperatorAssignmentKpisProvider,
  PreAssembleOperatorAssignmentKpisProvider,
  PerformanceRatingKpisProvider,
  PerformanceRatingsProvider,
  TruckAssignmentMetricsProvider,
  DailyPlanProvider,
  AppParamsProvider,
  OperatorsFilterProvider,
  OperatorsPreArmadoFilterProvider,
} from "contexts";
import { CssBaseline } from "@material-ui/core";
import { AppRoute } from "utils";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es } from "date-fns/esm/locale";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "services";
import { Helmet } from "react-helmet";
import "react-virtualized/styles.css"; // only needs to be imported once
import {
  EnvironmentAlert,
  FirstNavigate,
  ProtectedScreen,
  VisibilityTracker,
} from "components";
import { Can, Module } from "interfaces";
import { UsePollingAxiosParams } from "hooks";
import { TruckAssigmentPerformanceKpisProvider } from "contexts/TruckAssigmentPerformanceKpisContext";
import { TruckPerformancePhasesProvider } from "contexts/TruckPerformancePhasesContext";
import { TruckAssigmentCycleTimeProvider } from "contexts/TruckAssigmentCycleTimeContext";
import { TruckPerformanceProvider } from "contexts/TruckPerformanceContext";
import { TruckAssignmentPerformanceStatusProvider } from "contexts/TruckAssignmentPerformanceStatusContext";
import { RelieveScreen } from "screens/OA/Relieve/RelieveScreen";
import { OperatorsRelieveProvider } from "contexts/OperatorsRelieveContext";
import { RelieveFiltersProvider } from "contexts/RelieveFiltersContext";
import { RelieveRecommendationProvider } from "contexts/RelieveRecommendationContext";
import { RelieveRecommendationExportProvider } from "contexts/RelieveRecommendationExportContext";
import { TruckVelocityScreen } from "screens/TA/TruckVelocity/TruckVelocityScreen";
import { TruckAssignmentVelocityProvider } from "contexts/TruckAssignmentVelocityContext";
import { TruckAssignmentFiltersVelProvider } from "contexts/TruckAssignmentFiltersVelContext";
import { RelieveGroupsProvider } from "contexts/RelieveGroupsContext";
import { TruckAssigmentStatusVelocityProvider } from "contexts/TruckAssigmentStatusVelocityContext";
import { OperatorsRelieveStatusProvider } from "contexts/OperatorsRelieveStatusContext";
import { ShiftChangeOperatorsScreen } from "screens/SC/ShiftChangeOperatorsScreen";
import { ShiftChangeProvider } from "contexts/ShifChangeContext";
import { ShiftChangeOperatorsProvider } from "contexts/ShiftChangeOperatorsContext";
import { ShiftChangeScreen } from "screens/SC/ShiftChangeScreen";
import { TrucksDashboardPrearmedProvider } from "contexts/TruckDashboardPrearmedContext";
import { TruckPreAssembleScreen } from "screens/OA/Truck/PreAssemble/TruckPreAssembleScreen";

export const DATA_REFETCH_MINUTES = 1;
export const TRACKING_DELAY_SECONDS = 10;
export const STANDARD_POLLING: Pick<UsePollingAxiosParams, "polling"> = {
  polling: {
    minutes: DATA_REFETCH_MINUTES,
    silent: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const App: React.FC = () => {
  const classes = useStyles();

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <CssBaseline />
          <FeedbackProvider>
            <UserProvider>
              <ConfirmationDialogProvider>
                <Helmet>
                  <title>Truck Utilisation Basics</title>
                  <link
                    rel="icon"
                    href="public/favicon.ico"
                    type="image/x-icon"
                  />
                </Helmet>
                <div className={classes.root}>
                  <Router>
                    <InfoSourceProvider>
                      <VisibilityTracker>
                        <Routes>
                          <Route path="/" element={<FirstNavigate />} />
                          <Route
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.OPERATOR_ASSIGNMENT}
                              >
                                <TrucksDashboardPrearmedProvider>
                                  <TrucksDashboardProvider>
                                    <OperatorsProvider>
                                      <OperatorAssignmentKpisProvider>
                                        <PreAssembleOperatorAssignmentKpisProvider>
                                          <Outlet />
                                        </PreAssembleOperatorAssignmentKpisProvider>
                                      </OperatorAssignmentKpisProvider>
                                    </OperatorsProvider>
                                  </TrucksDashboardProvider>
                                </TrucksDashboardPrearmedProvider>
                              </ProtectedScreen>
                            }
                          >
                            <Route
                              path={AppRoute.TRUCKS_DASHBOARD}
                              element={
                                <AlertsProvider>
                                  <TrucksDashboardScreen />
                                </AlertsProvider>
                              }
                            />
                            <Route
                              path={AppRoute.TRUCK_DETAILS}
                              element={
                                <OperatorsFilterProvider>
                                  <OperatorAssignmentProvider>
                                    <TruckScreen />
                                  </OperatorAssignmentProvider>
                                </OperatorsFilterProvider>
                              }
                            />
                            <Route
                              path={AppRoute.PRE_ASSEMBLE_TRUCK_DETAILS}
                              element={
                                <OperatorsPreArmadoFilterProvider>
                                  <OperatorAssignmentProvider>
                                    <TruckPreAssembleScreen
                                      preAssemble={true}
                                    />
                                  </OperatorAssignmentProvider>
                                </OperatorsPreArmadoFilterProvider>
                              }
                            />
                            <Route
                              path={AppRoute.OPERATORS_SCREEN}
                              element={<OperatorsScreen />}
                            />
                            <Route
                              path={AppRoute.UNPLANNED_LOCATIONS_SCREEN}
                              element={<UnplannedParkedTrucksScreen />}
                            />
                            <Route
                              path={AppRoute.RELIEVE}
                              element={
                                <OperatorsRelieveStatusProvider>
                                  <RelieveRecommendationProvider>
                                    <RelieveFiltersProvider>
                                      <OperatorsRelieveProvider>
                                        <RelieveGroupsProvider>
                                          <RelieveRecommendationExportProvider>
                                            <RelieveScreen />
                                          </RelieveRecommendationExportProvider>
                                        </RelieveGroupsProvider>
                                      </OperatorsRelieveProvider>
                                    </RelieveFiltersProvider>
                                  </RelieveRecommendationProvider>
                                </OperatorsRelieveStatusProvider>
                              }
                            />
                            <Route
                              path={
                                AppRoute.SC_SHIFT_CHANGE_NOT_OPERATOR_SCREEN
                              }
                              element={<ShiftChangeScreen />}
                            />
                          </Route>
                          <Route
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.TRUCK_ASSIGNMENT}
                              >
                                <ExcavatorProvider>
                                  <TruckAssignmentKpisProvider>
                                    <Outlet />
                                  </TruckAssignmentKpisProvider>
                                </ExcavatorProvider>
                              </ProtectedScreen>
                            }
                          >
                            <Route
                              path={AppRoute.TA_BALANCE_SCREEN}
                              element={<TruckAssignmentScreen />}
                            />
                            <Route
                              path={AppRoute.TA_METRICS_SCREEN}
                              element={
                                <TruckAssignmentMetricsProvider>
                                  <TruckAssignmentMetricsScreen />
                                </TruckAssignmentMetricsProvider>
                              }
                            />
                            <Route
                              path={AppRoute.TA_PERFORMANCE_SCREEN}
                              element={
                                <TruckAssigmentCycleTimeProvider>
                                  <TruckAssigmentPerformanceKpisProvider>
                                    <TruckPerformancePhasesProvider>
                                      <TruckPerformanceProvider>
                                        <TruckAssignmentPerformanceStatusProvider>
                                          <TruckAssigmentPerformanceScreen />
                                        </TruckAssignmentPerformanceStatusProvider>
                                      </TruckPerformanceProvider>
                                    </TruckPerformancePhasesProvider>
                                  </TruckAssigmentPerformanceKpisProvider>
                                </TruckAssigmentCycleTimeProvider>
                              }
                            />
                            <Route
                              path={AppRoute.TA_VELOCITY_SCREEN}
                              element={
                                <TruckAssigmentStatusVelocityProvider>
                                  <TruckAssignmentFiltersVelProvider>
                                    <TruckAssigmentPerformanceKpisProvider>
                                      <TruckAssignmentVelocityProvider>
                                        <TruckVelocityScreen />
                                      </TruckAssignmentVelocityProvider>
                                    </TruckAssigmentPerformanceKpisProvider>
                                  </TruckAssignmentFiltersVelProvider>
                                </TruckAssigmentStatusVelocityProvider>
                              }
                            />
                            <Route
                              path={AppRoute.TA_DAILY_PLAN_SCREEN}
                              element={
                                <DailyPlanProvider>
                                  <TruckAssignmentDailyPlanScreen />
                                </DailyPlanProvider>
                              }
                            />
                          </Route>
                          <Route
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.PERFORMANCE_RATINGS}
                              >
                                <PerformanceRatingKpisProvider>
                                  <PerformanceRatingsProvider>
                                    <Outlet />
                                  </PerformanceRatingsProvider>
                                </PerformanceRatingKpisProvider>
                              </ProtectedScreen>
                            }
                          >
                            <Route
                              path={AppRoute.PERFORMANCE_RATINGS}
                              element={<PerformanceRatingSupervisorScreen />}
                            />
                          </Route>
                          <Route
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.SHIFT_CHANGE}
                              >
                                <Outlet />
                              </ProtectedScreen>
                            }
                          >
                            <Route
                              path={AppRoute.SC_SHIFT_CHANGE_OPERATOR_SCREEN}
                              element={<ShiftChangeOperatorsScreen />}
                            />
                          </Route>
                          <Route
                            path={AppRoute.BUS_MANAGEMENT_MAP_SCREEN}
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.BUS_MANAGEMENT}
                              >
                                <OperatorAssignmentKpisProvider>
                                  <BusesMapScreen />
                                </OperatorAssignmentKpisProvider>
                              </ProtectedScreen>
                            }
                          />
                          <Route
                            path={AppRoute.SOURCE_INFO_STATUS}
                            element={<SourceInfoScreen />}
                          />
                          <Route
                            path={AppRoute.ADMIN}
                            element={
                              <ProtectedScreen
                                to={Can.READ}
                                at={Module.ADMINISTRATION_PANEL}
                              >
                                <AppParamsProvider>
                                  <AdministrationScreen />
                                </AppParamsProvider>
                              </ProtectedScreen>
                            }
                          />
                          <Route
                            path={"*"}
                            element={<Navigate to={AppRoute.HOME} />}
                          />
                        </Routes>
                      </VisibilityTracker>
                    </InfoSourceProvider>
                    <EnvironmentAlert />
                  </Router>
                </div>
              </ConfirmationDialogProvider>
            </UserProvider>
          </FeedbackProvider>
        </MuiPickersUtilsProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    paddingTop: 64,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
}));
