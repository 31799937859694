export enum ErrorMsg {
  GET_OPERATORS = "Ocurrió un error al cargar los operadores",
  UPDATE_OPERATORS = "Ocurrió un error al actualizar los operadores",
  DELETE_OPERATOR = "Ocurrió un error al borrar el operador",
  UPDATE_SHIFT_CREW_OPERATORS = "Ocurrió un error al agregar el operador",

  GET_CAEX_CA = "Ocurrió un error al cargar la lista de CAEXs",
  UPDATE_CAEX_CA = "Ocurrió un error al guardar los cambios",

  ASSIGN_OPERATOR = "Ocurrió un error al asignar el operador",
  DELETE_ASSIGN_OPERATOR = "Ocurrió un error al liberar el operador",

  GET_OPERATOR_ASSIGMENT_ALERTS = "Ocurrió un error al cargar las alertas de asignación",
  MARK_ASSIGMENT_ALERT = "Ocurrió un error al aceptar la alerta",

  GET_EXCAVATORS_INFO = "Ocurrió un error al cargar la información de las palas",
  UPDATE_EXCAVATOR = "Ocurrió un error al guardar los cambios en las palas",

  GET_SOURCE_INFO = "Ocurrió un error al cargar el estado de las fuentes de información",
  GET_KPIS = "Ocurrió un error al cargar los KPI's",
  GET_STATUS_PERFORMANCE_VIEW = "Ocurrió un error al cargar el estado de Rendimiento",
  GET_STATUS_RELAY_VIEW = "Ocurrió un error al cargar el estado de Relevo",
  GET_STATUS_VELOCITY_VIEW = "Ocurrió un error al cargar el estado de Velocidad",
  GET_PHASES = "Ocurrió un error al cargar información de las fases",
  GET_FILTERS = "Ocurrió un error al cargar información de los filtros",

  GET_DASHBOARD_CAEXS = "Ocurrió un error al cargar el dashboard de CAEXs",
  GET_CAEX = "Ocurrió un error al cargar la información del CAEX",
  GET_CAEXS = "Ocurrió un error al cargar los CAEX",
  UPDATE_CAEX = "Ocurrió un error al guardar la información del CAEX",
  UPDATE_CAEXS = "Ocurrió un error al actualizar los CAEXs",

  GET_PMS = "Ocurrió un error al cargar las mantenciones planificadas",
  UPDATE_PMS = "Ocurrió un error al actualizar las mantenciones planificadas",

  GET_USERS = "Ocurrió un error al cargar los usuarios",
  GET_USER = "Ocurrió un error al cargar el usuario",
  CREATE_USER = "Ocurrió un error al crear el usuario",
  UPDATE_USER = "Ocurrió un error al actualizar el usuario",
  DELETE_USER = "Ocurrió un error al eliminar el usuario",
  GET_USERS_ROLES = "Ocurrió un error al obtener los roles",

  GENERIC_GET = "Ocurrió un error al cargar la información",
  GENERIC_UPDATE = "Ocurrió un error al guardar los cambios",

  GET_LOCATIONS = "Ocurrió un error al cargar las ubicaciones",
  GET_BUSES = "Ocurrió un error al cargar los buses",

  GET_SUPERVISORS = "Ocurrió un error al cargar los supervisores",
  GET_PR_TOP_10_OPERATORS = "Ocurrió un error al cargar el ranking de operadores",
  GET_PR_OPERATORS_EVENTS = "Ocurrió un error al cargar los eventos de los operadores",
  GET_PR_OPERATOR_EVENTS = "Ocurrió un error al cargar los eventos del operador",

  GET_EVALUATIONS = "Ocurrió un error al cargar las evaluaciones",
  UPDATE_EVALUATIONS = "Ocurrió un error al guardar la evaluación",

  GET_SHIFT_INFO = "Ocurrió un error al cargar la información del turno",

  TRACKING = "Hubo un error al mandar informacion de tracking",

  DAILY_PLAN_CSV_PARSING = "Algunas filas fueron omitidas ya que no cumplen el formato requerido",

  GET_MOVEMENTS_PER_CLIENT = "Ocurrió un error al cargar los movimientos por cliente",

  GET_SIC = "Ocurrió un error al cargar los registros SIC",
  CREATE_SIC = "Ocurrió un error al crear el registro SIC",
  UPDATE_SIC = "Ocurrió un error al actualizar el registro SIC",

  GET_APP_PARAMS = "Ocurrió un error al cargar los parámetros de la aplicación",
  UPDATE_APP_PARAMS = "Ocurrió un error al guardar los parámetros",

  GET_GENERAL_INFO = "Ocurrió un error al cargar información",

  GET_STATUS_RELAY = "Ocurrió un error al obtener datos del formulario",
  GET_FILTERS_RELAY = "Ocurrió un error al obtener datos de selección",
  POST_FINISHED_RELAY = "Ocurrió un error en la programación de relevo",
  POST_DELETED_RELAY = "Ocurrió un error al eliminar comedor",
  DELETED_RECOMMENDATION = "No se pudo realizar la modificación.",
  GET_RECOMMENDATION_RELAY = "Ocurrió un error al obtener relevos",
  CREATE_RELAY = "Ocurrió un error al crear relevo",
  GET_ROUTES_DATA = "Ocurrió un error al obtener datos de las rutas",
  GET_STATUS_SHIFT_CHANGE = "Ocurrió un error al cargar el estado de Cambio de Turno",
  GET_DATA_OPERATORS_SHIFT_CHANGE = "Ocurrió un error al cargar la data de Cambio de Turno",
  GET_DATA_NO_OPERATORS_SHIFT_CHANGE = "Ocurrió un error al cargar la data de Cambio de Turno",
  GET_POINTS_OF_INTEREST_AND_BUSES = "Ocurrió un error al cargar los puntos de interés y los buses",
}

export enum ErrorRelay {
  OPER_NOT_FOUND = "Operador no disponible para generar el relevo",
  CAEX1_NOT_FOUND = "CAEX del primer horario no disponible para generar el relevo",
  CAEX2_NOT_FOUND = "CAEX del segundo horario no disponible para generar el relevo",
  EXCAV_NOT_FOUND = "Pala no disponible para generar el relevo",
  TRUCK_NOT_ASSIGNED = "CAEX no disponible para generar el relevo",
  RELAY_NOT_FOUND = "Error al generar relevo",
}
