import React, { useCallback } from "react";
import {
  Divider,
  Theme,
  Typography,
  alpha,
  makeStyles,
} from "@material-ui/core";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { BusInfo } from "interfaces/bus.interface";
import { parseTimeDelta } from "utils/date";
import { AutocompleteChangeReason } from "@material-ui/lab";

interface Props {
  bus: BusInfo;
  isSelected: boolean;
  onSelect: (
    _event: React.ChangeEvent<{}>,
    value: BusInfo | null,
    _reason: AutocompleteChangeReason
  ) => void;
  style: React.CSSProperties;
}

export const BusCard: React.FC<Props> = ({
  bus,
  isSelected,
  onSelect,
  style,
}) => {
  const classes = useStyles({
    moving: bus.moving,
    isSelected,
    outdated: bus.outdatedPulse,
  });
  const onClick = useCallback(
    () =>
      onSelect(
        {} as React.ChangeEvent<{}>,
        bus,
        "selectOption" as AutocompleteChangeReason
      ),
    [onSelect, bus]
  );

  return (
    <div className={classes.busCardContainer} onClick={onClick} style={style}>
      {isSelected && <div className={classes.selectedBusCard} />}
      <div className={classes.busCard}>
        <div className={classes.busCardLeft}>
          <DirectionsBusIcon className={classes.busIcon} />
          <div className={classes.busCardContent}>
            <Typography variant="body1">
              <strong>
                Bus {bus.codigoInternoBus} · {bus.codigoRegistro}
              </strong>
            </Typography>
            <Typography variant="body1">
              Velocidad: {bus.valorVelocidadAvl} km/hr
            </Typography>
            <Typography variant="body1">
              {`Actualizado hace: ${parseTimeDelta(
                Math.floor(
                  (new Date().getTime() -
                    new Date(bus.datetimeValue).getTime()) /
                    (1000 * 60)
                )
              )}`}
            </Typography>
            {bus.closestLocation &&
              (bus.closestLocation?.name || bus.closestLocation?.alias) && (
                <Typography
                  variant="body1"
                  className={classes.pointOfInterestNameText}
                >
                  Punto más cercano:{" "}
                  {bus.closestLocation.alias?.length
                    ? bus.closestLocation.alias
                    : bus.closestLocation.name}
                </Typography>
              )}
          </div>
        </div>
        <div className={classes.busStatusDiv}>
          <DirectionsBusIcon className={classes.busIconChip} />
          <Typography variant="body1">
            <strong>{bus.moving ? "VIAJANDO" : "DETENIDO"}</strong>
          </Typography>
        </div>
      </div>
      <Divider />
    </div>
  );
};

interface StyleProps {
  moving: boolean;
  isSelected: boolean;
  outdated: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  busCard: {
    display: "flex",
    flexDirection: "row",
    width: "500px",
    padding: "12px 24px 12px 0px",
    justifyContent: "space-between",
  },
  busCardLeft: {
    display: "flex",
    flexDirection: "row",
  },
  busCardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  busIcon: {
    width: "24px",
    height: "24px",
    margin: "0px 10px",
  },
  busIconChip: {
    width: "24px",
    height: "24px",
  },
  busStatusDiv: {
    height: "28px",
    width: "109px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ moving, outdated }) =>
      moving && !outdated
        ? alpha(theme.palette.primary.main, 0.1)
        : moving && outdated
        ? alpha(theme.palette.error.light, 0.1)
        : alpha(theme.palette.grey[500], 0.3),
    color: ({ moving, outdated }) =>
      moving && !outdated
        ? theme.palette.primary.light
        : moving && outdated
        ? theme.palette.error.dark
        : theme.palette.grey[500],
    borderRadius: "4px",
  },
  pointOfInterestNameText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  selectedBusCard: {
    width: "6px",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    left: "0",
    height: "116px",
  },
  busCardContainer: {
    position: "relative",
  },
}));
