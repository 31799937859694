import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Slider,
  IconButton,
  Mark,
  Typography,
  MenuItem,
  Menu,
  Button,
  Fade,
} from "@material-ui/core";
import { PlayArrow, Pause, FlashOnRounded } from "@material-ui/icons";
import {
  eachMinuteOfInterval,
  eachHourOfInterval,
  format,
  isBefore,
} from "date-fns";

type Props = {
  initialDate: Date;
  endDate: Date;
  fetchData: (date: Date) => void;
};

export const TimeSlider: React.FC<Props> = ({
  initialDate,
  endDate,
  fetchData,
}) => {
  const classes = useStyles();
  const intervalRef = useRef<number | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Ensure the start is always <= end
  const validStart = isBefore(initialDate, endDate) ? initialDate : endDate;
  const validEnd = isBefore(initialDate, endDate) ? endDate : initialDate;

  // Current time in state, instead of an integer index
  const [currentDate, setCurrentDate] = useState<Date>(
    new Date(new Date().setSeconds(0, 0))
  );

  // Play/Pause
  const [isPlaying, setIsPlaying] = useState(true);

  // Speed in milliseconds
  const [speed, setSpeed] = useState(60000);

  const minutesArray = useMemo(
    () =>
      eachMinuteOfInterval({
        start: validStart,
        end: validEnd,
      }),
    [validStart, validEnd]
  );

  const maxIndex = minutesArray.length - 1;

  // Generate every HOUR in the same interval for labeled marks:
  const hoursArray = eachHourOfInterval({ start: validStart, end: validEnd });

  // Create marks: find which “minute index” each hour corresponds to
  // so that the slider’s marks line up precisely with hours.
  const hourMarks: Mark[] = useMemo(
    () =>
      hoursArray.map((hourDate) => {
        const minuteIndex = minutesArray.findIndex(
          (m) => m.getTime() === hourDate.getTime()
        );

        return {
          value: minuteIndex,
          label: format(hourDate, "HH:mm"),
        };
      }),
    [hoursArray, minutesArray]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  // Convert date <-> index
  const dateToIndex = useCallback(
    (d: Date) => minutesArray.findIndex((m) => m.getTime() === d.getTime()),
    [minutesArray]
  );

  // Convert index <-> date
  const indexToDate = useCallback(
    (i: number) => minutesArray[i],
    [minutesArray]
  );

  // Only let the user move the thumb to hour marks => step={null} with marks
  // but we ourselves can set the value to any minute index for the animation.
  const handleSliderChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setIsPlaying(false);
      if (!Array.isArray(newValue)) {
        const newDate = indexToDate(newValue);
        if (newDate > new Date(new Date().setSeconds(0, 0))) {
          return;
        }
        setCurrentDate(newDate);
      }
    },
    [indexToDate, setCurrentDate]
  );

  const handleSpeedUp = useCallback((speedMls: number) => {
    setSpeed(speedMls);
    setAnchorEl(null);
  }, []);

  const handleNormalSpeed = useCallback(() => {
    setSpeed(60000);
    setAnchorEl(null);
  }, []);

  // Auto‐advance minute by minute on Play:
  useEffect(() => {
    if (isPlaying) {
      // Move forward every minute
      intervalRef.current = window.setInterval(() => {
        const currentIndex = dateToIndex(currentDate);

        const nextIndex = currentIndex + 1;
        setCurrentDate(indexToDate(nextIndex));
        fetchData(indexToDate(nextIndex));
      }, speed);
    } else if (!isPlaying && intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    // Cleanup
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [
    isPlaying,
    maxIndex,
    currentDate,
    dateToIndex,
    indexToDate,
    fetchData,
    speed,
  ]);

  const handlePlayPause = useCallback(
    (isPlaying: boolean) => {
      setIsPlaying(!isPlaying);
      if (!isPlaying) {
        fetchData(currentDate);
      }
    },
    [setIsPlaying, fetchData, currentDate]
  );

  const handleGoLive = useCallback(() => {
    setIsPlaying(true);
    setCurrentDate(new Date(new Date().setSeconds(0, 0)));
    fetchData(new Date(new Date().setSeconds(0, 0)));
    setSpeed(60000);
  }, [fetchData, setCurrentDate, setIsPlaying]);

  // For the slider: convert currentDate -> numeric index
  const currentIndex = useMemo(
    () => dateToIndex(currentDate),
    [currentDate, dateToIndex]
  );

  const isLive = useMemo(
    () => currentDate >= new Date(new Date().setSeconds(0, 0)),
    [currentDate]
  );

  useEffect(() => {
    if (isLive) {
      setSpeed(60000);
    }
  }, [isLive]);

  return (
    <div className={classes.container}>
      <div className={classes.sliderContainer}>
        <IconButton
          className={classes.playPauseButton}
          onClick={() => handlePlayPause(isPlaying)}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>

        <Slider
          classes={{
            markLabel: classes.markLabel,
            valueLabel: classes.valueLabel,
            mark: classes.mark,
            markActive: classes.markActive,
            rail: classes.rail,
          }}
          className={classes.slider}
          value={currentIndex}
          onChange={handleSliderChange}
          min={0}
          max={maxIndex}
          step={null}
          marks={hourMarks}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => format(minutesArray[value], "HH:mm")}
        />
      </div>

      <div className={classes.controlsContainer}>
        <div className={classes.speedButtonsContainer}>
          <Typography variant="caption">Speed:</Typography>
          <Button
            className={classes.speedButton}
            onClick={handleClick}
            disabled={isLive}
            size="small"
          >
            {speed === 60000 ? "Normal" : speed === 30000 ? "x2" : "x3"}
          </Button>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleNormalSpeed}>Normal</MenuItem>
            <MenuItem onClick={() => handleSpeedUp(30000)}>x2</MenuItem>
            <MenuItem onClick={() => handleSpeedUp(15000)}>x3</MenuItem>
          </Menu>
        </div>

        <Button
          className={classes.goLiveButton}
          size="small"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleGoLive}
          endIcon={<FlashOnRounded />}
          disabled={isLive}
        >
          GO LIVE
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    //TODO: Remove this when using the Slider
    display: "none",
    marginTop: 16,
    flexDirection: "column",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.background.default
        : theme.palette.background.paper,
    padding: "10px 24px",
    borderRadius: 4,
  },
  sliderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  slider: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  label: {
    marginLeft: theme.spacing(2),
  },
  playPauseButton: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
  controlsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  speedButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  speedButton: {
    color: theme.palette.primary.main,
    textTransform: "none",
    minWidth: "fit-content",
  },
  goLiveButton: {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
  markLabel: {
    fontWeight: 600,
  },
  valueLabel: {
    "& span > span": {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
  },
  mark: {
    backgroundColor: theme.palette.text.disabled,
    opacity: 1,
    height: 8,
    width: 8,
    top: 10,
    borderRadius: "50%",
  },
  markActive: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    height: 8,
    width: 8,
    top: 10,
    borderRadius: "50%",
  },
  rail: {
    opacity: 1,
    backgroundColor: theme.palette.text.disabled,
  },
}));
