import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

export const ParkingLotIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="15"
      height="26"
      viewBox="0 0 15 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_485_176366)">
        <mask
          id="mask0_485_176366"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="18"
          width="14"
          height="8"
        >
          <path
            d="M1.13747 18.6182H14.6365V25.6894H1.13747V18.6182Z"
            fill="#DFDFDF"
          />
        </mask>

        <g mask="url(#mask0_485_176366)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.88687 18.6182C4.15997 18.6182 1.13747 20.2013 1.13747 22.1539C1.13747 24.1062 4.15997 25.6894 7.88687 25.6894C11.614 25.6894 14.6365 24.1062 14.6365 22.1539C14.6365 20.2013 11.614 18.6182 7.88687 18.6182Z"
            fill="#DFDFDF"
          />
        </g>

        <mask
          id="mask1_485_176366"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-1"
          width="15"
          height="26"
        >
          <path
            d="M0 -0.0507812H14.1194V24.0542H0V-0.0507812Z"
            fill="#8391A4"
          />
        </mask>

        <g mask="url(#mask1_485_176366)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.765338 7.0144L13.1145 0.0447806C13.3113 -0.0661276 13.4913 -0.0771933 13.6272 -0.00551789L13.627 -0.00702685L14.1193 0.270873L13.8797 0.806301V15.3992C13.8797 15.9059 13.5353 16.5148 13.1145 16.7524L0.990632 23.5948L0.755919 24.0543L0.268927 23.7802C0.251107 23.7719 0.233797 23.7623 0.217504 23.7512L0.215213 23.75H0.215468C0.0820733 23.6592 -0.000152588 23.4801 -0.000152588 23.2327V8.36768C-0.000152588 7.86092 0.34428 7.25206 0.765338 7.0144Z"
            fill="#8391A4"
          />
        </g>

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.24739 7.28546L13.5966 0.315839C14.0174 0.0784301 14.3618 0.298486 14.3618 0.805495V15.6702C14.3618 16.177 14.0174 16.7858 13.5966 17.0235L1.24739 23.9931C0.826336 24.2305 0.481903 24.0105 0.481903 23.5037V8.63899C0.481903 8.13223 0.826336 7.52312 1.24739 7.28546Z"
          fill="#FEFEFE"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.7061 7.64727L13.1378 1.19548C13.5275 0.97567 13.8463 1.17938 13.8463 1.64866V15.4091C13.8463 15.8784 13.5275 16.442 13.1378 16.662L1.7061 23.1136C1.31636 23.3336 0.997635 23.1297 0.997635 22.6606V8.90021C0.997635 8.43117 1.31636 7.86732 1.7061 7.64727Z"
          fill="#323441"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.534 11.5638L5.63429 14.1806V17.5048L12.2516 14.0878V15.4881L4.00708 20.0193V7.5656L12.2516 3.1084V5.23703L5.63429 8.91511V12.608L10.534 9.94347V11.5638Z"
          fill="#FEFEFE"
        />
      </g>

      <defs>
        <clipPath id="clip0_485_176366">
          <rect width="14.6365" height="26" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
