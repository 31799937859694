import { OperatorStatusType } from "./operator.interface";

export interface Bus {
  id: number;
  radioId: string;
}

export interface OperatorInfoDetail {
  id: number;
  name: string;
  status: OperatorStatusType;
}

export interface OperatorsInfo {
  total: number;
  operators: OperatorInfoDetail[];
}

export interface PointOfInterest {
  alias: string;
  id: number;
  lat: number;
  lon: number;
  name: string;
  isBusPoi: boolean;
  type: PointOfInterestType;
  closestBuses: BusInfo[];
  operatorsInfo: OperatorsInfo;
}
export interface GetPointsOfInterestAndBusesResponse {
  locations: PointOfInterest[];
  buses: BusInfo[];
}

export interface Position {
  lat: number;
  lon: number;
}

export interface BusInfo {
  altitude: number;
  codigoInternoBus: string;
  codigoRegistro: string;
  createdAt: string;
  datetimeValue: string;
  idRecurso: string;
  lat: number;
  lon: number;
  orientacion: number;
  timestampEstado: number;
  valorOdometroAvl: number;
  valorVelocidadAvl: number;
  geozonas0Nombre: string;
  geozonas1Nombre: string;
  lastPositions: Position[];
  moving: boolean;
  outdatedPulse: boolean;
  closestLocation: {
    id: number;
    name: string;
    alias: string;
  };
}

export interface OperatorLocation {
  id: number;
  name: string;
  status: OperatorStatusType;
  locationId: number | null;
  location: Pick<
    PointOfInterest,
    "name" | "alias" | "lat" | "lon" | "isBusPoi"
  > | null;
}
export enum BusFilter {
  ALL = "ALL",
  MINE = "MINE",
  VCA = "VCA",
}
export interface FilteredBusResume {
  id: BusFilter;
  name: string;
  count: number;
}

export enum PointOfInterestType {
  DINING_ROOM = "DINING_ROOM",
  PARKING_LOT = "PARKING_LOT",
}

export interface GetPointsOfInterestAndBusesResponse {
  locations: PointOfInterest[];
  buses: BusInfo[];
  operators: OperatorLocation[];
}
